import React from 'react';
import { Dialog } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { Vessel } from '~/types';
import { api } from '~/utils';

import { VesselGeometryUpdateDialog } from './VesselGeometryUpdateDialog';

interface IProps {
  vessel: Vessel;
  onSuccess: () => Promise<void>;
  onClose: () => void;
}

export const VesselGeometryUpdate = ({ vessel, onSuccess, onClose }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('vesselGeometry', file);
      await api.post(`/vessels/${vessel.imoNumber}/vessel-geometry`, formData);
      enqueueSnackbar('Vessel geometry has been been uploaded.', { variant: 'success' });
      await onSuccess();
      onClose();
    } catch (e) {
      enqueueSnackbar('Error uploading vessel geometry.', { variant: 'error' });
    }
  };

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="md">
      <VesselGeometryUpdateDialog onCancel={onClose} onSubmit={handleSubmit} />
    </Dialog>
  );
};
