import React from 'react';
import { Grid } from '@material-ui/core';

import { DetailField } from '@hpc/components';

import { Vessel } from '~/types';
import { vesselDetails } from './config';

interface IProps {
  vessel: Vessel;
}

export const VesselDetailsView = ({ vessel }: IProps) => {
  return (
    <Grid container>
      {vesselDetails.map(({ label, property }) => (
        <Grid item key={label} xs={12}>
          <DetailField label={label} value={vessel?.[property] || ''} />
        </Grid>
      ))}
      <Grid item xs={12}>
        <DetailField label="Geometry origin" value={vessel.geometryOriginator} />
      </Grid>
    </Grid>
  );
};
