import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import { AccessControl, ApiResponse, AuthContext, UserRoles, useToggle } from '@hpc/components';

import { Group } from '~/types';
import { api } from '~/utils';

import { CreateGroup } from './CreateGroup';
import { GroupTable } from './GroupTable';

export const Groups = () => {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { user } = useContext(AuthContext);
  const [isCreatingGroup, toggleCreatingGroup] = useToggle();
  const [loading, setLoading] = useToggle(true);
  const [groups, setGroups] = useState<Group[]>([]);

  const organisationId = params?.organisationId || user.organisationId;

  const fetchGroups = async () => {
    try {
      const response = await api.get<ApiResponse<Group[]>>(
        `/organisations/${organisationId}/groups`
      );
      setGroups(response?.data?.payload || []);
    } catch (e) {
      enqueueSnackbar('Error fetching groups.', { variant: 'error' });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (organisationId) {
      fetchGroups();
    }
  }, [organisationId, isCreatingGroup]);

  return (
    <>
      <Grid container direction="column">
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography variant="h1">Groups</Typography>
          </Grid>
          {!loading && (
            <AccessControl userRole={UserRoles.ADMIN}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={loading}
                  onClick={toggleCreatingGroup}
                >
                  New Group
                </Button>
              </Grid>
            </AccessControl>
          )}
        </Grid>
        <Grid item>
          <GroupTable loading={loading} groups={groups} />
        </Grid>
      </Grid>

      {isCreatingGroup && organisationId && (
        <CreateGroup organisationId={organisationId} onClose={() => toggleCreatingGroup(false)} />
      )}
    </>
  );
};
