import React, { useState } from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import { Button } from '@hpc/components';

import { FileUpload } from '~/components';
import { VesselCallInput } from '~/types';

interface IProps {
  onSubmit: (file: File) => Promise<void>;
  onCancel: () => void;
}

export const VesselGeometryUpdateDialog = ({ onCancel, onSubmit }: IProps) => {
  const [geometry, setGeometry] = useState<File | null>(null);
  const { formState, handleSubmit } = useForm<VesselCallInput>();

  const handleGeometryUpload = (file: File) => {
    if (!file) {
      setGeometry(null);
      return;
    }
    const reader = new FileReader();

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');

    reader.onload = () => {
      setGeometry(file);
    };

    reader.readAsText(file);
  };

  const updateGeometry = async () => {
    await onSubmit(geometry);
  };

  return (
    <form onSubmit={handleSubmit(updateGeometry)}>
      <DialogTitle>Update vessel geometry</DialogTitle>
      <DialogContent>
        <FileUpload accept={['.json', '.staf', '.staf.txt']} onChange={handleGeometryUpload} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          disabled={formState.isSubmitting}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!geometry}
          loading={formState.isSubmitting}
        >
          Upload
        </Button>
      </DialogActions>
    </form>
  );
};
