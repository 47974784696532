import React from 'react';
import { Tooltip } from '@material-ui/core';
import { MUIDataTableColumn } from 'mui-datatables';

import { StatusPositive } from '@hpc/components';

import { Container, WorkingDirection } from '~/types';
import { getYesNoValue, wdUndefinedToRob } from '~/utils';

export const filterMap = {
  'plannedPosition.bay': 'bay',
  'plannedPosition.row': 'row',
  'plannedPosition.tier': 'tier',
  'plannedPosition.deckHold': 'dh',
  workingDirection: 'dir',
  containerNumber: 'cnum',
  isoCode: 'iso',
  weight: 'weight',
  weightUnit: 'wunit',
  pol: 'pol',
  pod: 'pod',
  owner: 'owner',
  cargoStatus: 'cargo',
  note: 'note',
  oogInfo: 'oog',
  isROB: 'rob',
  isReefer: 'reefer',
  isHighCube: 'hc',
  isIMDG: 'imdg',
  discrepancy: 'discrepancy',
};

export const getContainerColumns = (
  containers: Container[],
  filters: Partial<Record<keyof typeof filterMap, string>>,
  classes: Record<string, string>,
  isMediumUp: boolean
): MUIDataTableColumn[] => {
  const renderDiscrepancy = (v) => containers[v].discrepancy && <StatusPositive />;
  const renderWD = (v) => wdUndefinedToRob(containers[v].workingDirection);
  const renderOogInfo = (v) =>
    containers[v].oogInfo && (
      <Tooltip
        title={
          <div>
            {Object.keys(containers[v].oogInfo)?.map((key) => (
              <div key={key}>
                {key}: {containers[v].oogInfo[key]}
              </div>
            ))}
          </div>
        }
        placement="top"
      >
        <div>
          <StatusPositive />
        </div>
      </Tooltip>
    );
  const renderROB = (v) => containers[v].isROB && <StatusPositive />;
  const renderReefer = (v) => containers[v].isReefer && <StatusPositive />;
  const renderHighCube = (v) => containers[v].isHighCube && <StatusPositive />;
  const renderImdg = (v) => containers[v].isIMDG && <StatusPositive />;

  const setConfirmedCellProps = () => ({
    className: classes.confirmed,
  });

  const setCellProps = () =>
    isMediumUp && {
      padding: 'checkbox',
      align: 'center',
    };

  const arraySortCompare = (order) => {
    return ({ data: arr1 }, { data: arr2 }) => {
      const aVal = Array.isArray(arr1) && arr1.length > 0 ? arr1.join('') : '';
      const bVal = Array.isArray(arr2) && arr2.length > 0 ? arr2.join('') : '';
      return aVal.localeCompare(bVal) * (order === 'asc' ? 1 : -1);
    };
  };

  const columns: MUIDataTableColumn[] = [
    {
      name: 'containerNumber',
      label: 'Container Number',
      options: {
        customFilterListOptions: {
          render: (v) => `Container: ${v}`,
        },
        filterType: 'textField',
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
          },
        }),
      },
    },
    {
      name: 'plannedPosition.bay',
      label: 'Planned Bay',
      options: {
        customFilterListOptions: {
          render: (v) => `Planned Bay: ${v}`,
        },
      },
    },
    {
      name: 'plannedPosition.row',
      label: 'Planned Row',
      options: {
        customFilterListOptions: {
          render: (v) => `Planned Row: ${v}`,
        },
      },
    },
    {
      name: 'plannedPosition.tier',
      label: 'Planned Tier',
      options: {
        customFilterListOptions: {
          render: (v) => `Planned Tier: ${v}`,
        },
      },
    },
    {
      name: 'plannedPosition.deckHold',
      label: 'Planned Deck/Hold',
      options: {
        customFilterListOptions: {
          render: (v) => `Planned D/H: ${v}`,
        },
        filterType: 'dropdown',
      },
    },
    {
      name: 'confirmedPositions.bay',
      label: 'Confirmed Bay',
      options: {
        customBodyRender: (v) => v?.join('\n'),
        customFilterListOptions: {
          render: (v) => `Confirmed Bay: ${v}`,
        },
        setCellProps: setConfirmedCellProps,
        sortCompare: arraySortCompare,
      },
    },
    {
      name: 'confirmedPositions.row',
      label: 'Confirmed Row',
      options: {
        customBodyRender: (v) => v?.join('\n'),
        customFilterListOptions: {
          render: (v) => `Confirmed Row: ${v}`,
        },
        setCellProps: setConfirmedCellProps,
        sortCompare: arraySortCompare,
      },
    },
    {
      name: 'confirmedPositions.tier',
      label: 'Confirmed Tier',
      options: {
        customBodyRender: (v) => v?.join('\n'),
        customFilterListOptions: {
          render: (v) => `Confirmed Tier: ${v}`,
        },
        setCellProps: setConfirmedCellProps,
        sortCompare: arraySortCompare,
      },
    },
    {
      name: 'confirmedPositions.deckHold',
      label: 'Confirmed Deck/Hold',
      options: {
        customBodyRender: (v) => v?.join('\n'),
        customFilterListOptions: {
          render: (v) => `Confirmed D/H: ${getYesNoValue(v)}`,
        },
        filterType: 'dropdown',
        setCellProps: setConfirmedCellProps,
        sortCompare: arraySortCompare,
      },
    },
    {
      name: 'discrepancy',
      label: 'Discrepancy',
      options: {
        customBodyRenderLite: renderDiscrepancy,
        customFilterListOptions: {
          render: (v) => `Discrepancy: ${getYesNoValue(v)}`,
        },
        filterOptions: {
          renderValue: (v) => getYesNoValue(v),
        },
        filterType: 'dropdown',
        setCellProps,
      },
    },
    {
      name: 'workingDirection',
      label: 'Load/Discharge',
      options: {
        customBodyRenderLite: renderWD,
        customFilterListOptions: {
          render: (v) => `WD: ${wdUndefinedToRob(v)}`,
        },
        filterOptions: {
          renderValue: (v) => wdUndefinedToRob(v as WorkingDirection),
        },
        filterType: 'dropdown',
      },
    },
    {
      name: 'isoCode',
      label: 'ISO',
      options: {
        customFilterListOptions: {
          render: (v) => `ISO: ${v}`,
        },
        filterType: 'textField',
      },
    },
    {
      name: 'weight',
      label: 'Weight',
      options: {
        customFilterListOptions: {
          render: (v) => `Weight: ${v}`,
        },
        filterType: 'textField',
      },
    },
    {
      name: 'weightUnit',
      label: 'Weight Unit',
      options: {
        customFilterListOptions: {
          render: (v) => `Weight unit: ${v}`,
        },
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
          },
        }),
      },
    },
    {
      name: 'pod',
      label: 'POD',
      options: {
        customFilterListOptions: {
          render: (v) => `POD: ${v || 'N/A'}`,
        },
        filterOptions: {
          renderValue: (v) => v || 'N/A',
        },
      },
    },
    {
      name: 'pol',
      label: 'POL',
      options: {
        customFilterListOptions: {
          render: (v) => `POL: ${v || 'N/A'}`,
        },
        filterOptions: {
          renderValue: (v) => v || 'N/A',
        },
      },
    },
    {
      name: 'owner',
      label: 'Owner',
      options: {},
    },
    {
      name: 'cargoStatus',
      label: 'Full/Empty',
      options: {
        filterType: 'dropdown',
        setCellProps,
      },
    },
    {
      name: 'oogInfo',
      label: 'OOG',
      options: {
        customBodyRender: (v) => getYesNoValue(v),
        customBodyRenderLite: renderOogInfo,
        customFilterListOptions: {
          render: (v) => `OOG: ${getYesNoValue(v)}`,
        },
        filterOptions: {
          logic(value, filters) {
            if (filters.includes('Yes') || filters.includes(true)) {
              return !value;
            } else if (filters.includes('No') || filters.includes(false)) {
              return Boolean(value);
            }
            return false;
          },
        },
        filterType: 'dropdown',
        setCellProps,
        sortCompare: (order) => (obj1) => (obj1.data ? 1 : -1) * (order === 'asc' ? 1 : -1),
      },
    },
    {
      name: 'isROB',
      label: 'ROB',
      options: {
        customBodyRenderLite: renderROB,
        customFilterListOptions: {
          render: (v) => `ROB: ${getYesNoValue(v)}`,
        },
        filterOptions: {
          renderValue: (v) => getYesNoValue(v),
        },
        filterType: 'dropdown',
        setCellProps,
      },
    },
    {
      name: 'isReefer',
      label: 'Reefer',
      options: {
        customBodyRenderLite: renderReefer,
        customFilterListOptions: {
          render: (v) => `Reefer: ${getYesNoValue(v)}`,
        },
        filterOptions: {
          renderValue: (v) => getYesNoValue(v),
        },
        filterType: 'dropdown',
        setCellProps,
      },
    },
    {
      name: 'isHighCube',
      label: 'High Cube',
      options: {
        customBodyRenderLite: renderHighCube,
        customFilterListOptions: {
          render: (v) => `High Cube: ${getYesNoValue(v)}`,
        },
        filterOptions: {
          renderValue: (v) => getYesNoValue(v),
        },
        filterType: 'dropdown',
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
          },
        }),
        setCellProps,
      },
    },
    {
      name: 'isIMDG',
      label: 'IMDG',
      options: {
        customBodyRenderLite: renderImdg,
        customFilterListOptions: {
          render: (v) => `IMDG: ${getYesNoValue(v)}`,
        },
        filterOptions: {
          renderValue: (v) => getYesNoValue(v),
        },
        filterType: 'dropdown',
        setCellProps,
      },
    },
    {
      name: 'note',
      label: 'Notes',
      options: {
        customFilterListOptions: {
          render: (v) => `Note: ${v}`,
        },
        filterOptions: {
          fullWidth: true,
        },
        filterType: 'textField',
      },
    },
  ];

  return columns.map((column) => ({
    ...column,
    options: {
      sortThirdClickReset: true,
      ...column.options,
      ...(Object.prototype.hasOwnProperty.call(filters, filterMap[column.name]) && {
        filterList: filters[filterMap[column.name]]
          ?.split(',')
          .filter((val) => val !== '')
          .map((val) => (val === '1' ? true : val === '0' ? false : val)),
      }),
    },
  }));
};
