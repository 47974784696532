import { AccessControlType } from '~/types';

export const accessControl: AccessControlType = {
  isUser: true,
  isAdmin: false,
  isSuperAdmin: false,
  hasAppAdmin: () => false,
  hasAppWrite: () => false,
  hasAppRead: () => false,
};
