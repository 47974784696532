import React, { useState } from 'react';
import {
  Button,
  Container,
  Grid,
  makeStyles,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { DataTest } from '~/constants';
import { GettingStartedStep } from '~/types/GettingStarted';

const useStyles = makeStyles(({ palette, spacing, transitions }) => ({
  root: {
    '& img': {
      maxWidth: '100%',
      border: `1px solid ${palette.grey.A200}`,
    },
    '& hr': {
      marginTop: spacing(2),
      marginBottom: spacing(2),
    },
  },
  stepper: {
    backgroundColor: 'transparent',
  },
  stepIcon: {
    zIndex: 100,
    transform: 'scale(1.7)',
    overflow: 'visible',
    '& > circle': {
      fill: 'transparent',
      stroke: 'currentColor',
    },
    '& > text': {
      fill: 'currentColor',
      stroke: 'none',
    },
  },
  stepIconActive: {
    '& > circle': {
      fill: 'currentColor',
      stroke: 'currentColor',
    },
    '& > text': {
      fill: palette.common.white,
      stroke: 'none',
    },
  },
  title: {
    textAlign: 'center',
    animation: `$fadeIn ${transitions.duration.enteringScreen}ms`,
    '& > h2': {
      fontSize: '1.5rem',
    },
  },
  content: {
    animation: `$fadeIn ${transitions.duration.enteringScreen}ms`,
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}));

interface IProps {
  steps: GettingStartedStep[];
}

export const GettingStarted = ({ steps }: IProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();
  const stepIconClasses = {
    root: classes.stepIcon,
    active: classes.stepIconActive,
  };

  const handleNext = () => {
    setActiveStep((currentStep) => currentStep + 1);
  };

  const handleBack = () => {
    setActiveStep((currentStep) => currentStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  return (
    <Container className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            nonLinear
            classes={{ root: classes.stepper }}
          >
            {steps.map((step, index) => (
              <Step key={index} data-testid={`${DataTest.STEP}-${index}`}>
                <StepButton
                  data-testid={`${DataTest.STEP_BUTTON}-${index}`}
                  optional={<Typography variant="caption">{step.optional}</Typography>}
                  onClick={handleStep(index)}
                >
                  <StepLabel StepIconProps={{ classes: stepIconClasses }}>
                    {step.label || ''}
                  </StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.title}>
            {steps[activeStep].label && (
              <Typography key={activeStep} variant="h2">
                {steps[activeStep].label}
              </Typography>
            )}
            {steps[activeStep].optional && (
              <Typography variant="subtitle2">{steps[activeStep].optional}</Typography>
            )}
          </div>
        </Grid>
        <Grid item xs={12} data-testid={DataTest.STEP_CONTENT}>
          <div key={activeStep} className={classes.content}>
            {steps[activeStep].content}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Button
                data-testid={DataTest.BUTTON_PREV}
                variant="outlined"
                color="primary"
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                Previous
              </Button>
            </Grid>
            <Grid item>
              <Button
                data-testid={DataTest.BUTTON_NEXT}
                variant="outlined"
                color="primary"
                disabled={activeStep === steps.length - 1}
                onClick={handleNext}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
