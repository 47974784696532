import React from 'react';
import { Grid } from '@material-ui/core';
import { DetailField, Organisation } from '@hpc/components';

interface IProps {
  organisation: Organisation;
}

export const HatchClerkView = ({ organisation }: IProps) => {
  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item xs={12}>
        <DetailField label="Location code" value={organisation.locationCode} />
      </Grid>
    </Grid>
  );
};
