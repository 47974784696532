import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Controller, FieldError, useForm } from 'react-hook-form';

import { Button, setErrors } from '@hpc/components';

import { UserInput } from '~/types';
import { userValidationSchema } from '~/utils';
import { fields } from './config';

interface IProps {
  onSubmit: (values: UserInput) => Promise<void>;
  onCancel: () => void;
}

export const CreateUserDialog = ({ onCancel, onSubmit }: IProps) => {
  const [formError, setFormError] = useState('');
  const { control, formState, handleSubmit, setError } = useForm<UserInput>({
    resolver: yupResolver(userValidationSchema),
    defaultValues: {
      firstName: '',
      name: '',
      emailAddress: '',
      jobTitle: '',
      phone: '',
      department: '',
    },
  });

  const createUser = async (data: UserInput) => {
    try {
      await onSubmit(data);
    } catch (e) {
      if (e.error && e.error.code === 'VALIDATION_ERROR') {
        if (e.error.extensions) {
          setErrors(e.error.extensions, setError);
        } else {
          setFormError(e.error.message);
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(createUser)}>
      <DialogTitle>Create User</DialogTitle>
      <DialogContent>
        <Grid container>
          {formError && (
            <Grid item xs={12}>
              <Alert severity="error">{formError}</Alert>
            </Grid>
          )}

          {fields.map(({ key, label, optional, placeholder }) => (
            <Grid key={key} item xs={12}>
              <Controller
                name={key}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={label}
                    error={!!formState.errors?.[key]}
                    helperText={(formState.errors?.[key] as FieldError)?.message}
                    fullWidth
                    placeholder={placeholder}
                    InputProps={{
                      endAdornment: optional && (
                        <InputAdornment position="end">optional</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          disabled={formState.isSubmitting}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button variant="contained" color="primary" type="submit" loading={formState.isSubmitting}>
          Create
        </Button>
      </DialogActions>
    </form>
  );
};
