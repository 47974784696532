import React from 'react';
import { useSnackbar } from 'notistack';

import { Button, useToggle } from '@hpc/components';
import { api } from '~/utils';

interface IProps {
  organisationId: string;
  userId: string;
}

export const SendInvitationButton = ({ organisationId, userId }: IProps) => {
  const [isLoading, toggleLoading] = useToggle();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = async () => {
    toggleLoading(true);
    try {
      await api.post(`/organisations/${organisationId}/users/${userId}/invitation:send`);
      enqueueSnackbar('The invitation has been sent.', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Error resending the invitation.', { variant: 'error' });
    }
    toggleLoading(false);
  };

  return (
    <Button type="button" variant="outlined" loading={isLoading} onClick={handleClick}>
      Resend invitation
    </Button>
  );
};
