import axios from 'axios';
import { errorInterceptor, setAuthorizationHeader } from '@hpc/components';
import { API_URL } from '~/constants';

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// temporary solution until server will handle error correctly
api.interceptors.response.use(undefined, errorInterceptor);

setAuthorizationHeader(api);
