import React from 'react';
import { makeStyles } from '@material-ui/core';

import { ProgressCircle } from './ProgressCircle';
import { ProgressNoData } from './ProgressNoData';
import { ProgressValues } from './ProgressValues';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
}));

interface IProps {
  current: number;
  total: number;
}

export const ProgressInfo = ({ current, total }: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ProgressCircle current={current} total={total} />
      {total > 0 ? <ProgressValues current={current} total={total} /> : <ProgressNoData />}
    </div>
  );
};
