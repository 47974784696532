import React from 'react';
import { Card as MuiCard, CardProps } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    padding: spacing(2),
  },
}));

export const Card = ({ classes, ...restProps }: CardProps) => {
  const customClasses = useStyles();

  return <MuiCard classes={{ ...customClasses, ...classes }} {...restProps} />;
};
