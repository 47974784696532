import React from 'react';
import { StatusNegative } from './StatusNegative';
import { StatusPositive } from './StatusPositive';

interface IProps {
  status?: boolean;
}

export const StatusBoolean = ({ status }: IProps) =>
  status ? <StatusPositive /> : <StatusNegative />;
