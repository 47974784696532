import React from 'react';
import { Tooltip } from '@material-ui/core';
import { StatusLoading, StatusNegative, StatusPositive } from '@hpc/components';
import { DataTest } from '~/constants';
import { BaplieStatus, VesselProfileStatus } from '~/types';

const getStatusIcon = (status) => {
  switch (status) {
    case BaplieStatus.PRESENT:
    case VesselProfileStatus.PRESENT:
      return <StatusPositive />;
    case BaplieStatus.PARSING:
      return <StatusLoading />;
    case BaplieStatus.FAILED:
      return <StatusNegative />;
    case BaplieStatus.ABSENT:
    case VesselProfileStatus.ABSENT:
      return null;
    default:
      return <span>{status}</span>;
  }
};

interface IProps {
  status?: BaplieStatus | VesselProfileStatus;
  message?: string;
}

export const StatusIcon = ({ status, message }: IProps) => {
  return (
    <Tooltip arrow title={message ? message : status}>
      <span data-test={DataTest.STATUS_ICON}>{getStatusIcon(status)}</span>
    </Tooltip>
  );
};
