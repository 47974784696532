import React from 'react';
import { Link as MuiLink } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { TableColumnDef, trimUrl, withHttp } from '@hpc/components';
import { Organisation } from '~/types';

export const organisationsColumns = (organisations: Organisation[]): TableColumnDef[] => {
  const renderName = (value) => (
    <Link to={`${organisations[value].id}`}>{organisations[value].name}</Link>
  );
  const renderUrl = (value) => (
    <MuiLink href={withHttp(organisations[value].websiteUrl)} target="_blank">
      {trimUrl(organisations[value].websiteUrl)}
    </MuiLink>
  );

  return [
    {
      name: 'name',
      label: 'Name',
      options: {
        render: renderName,
      },
    },
    {
      name: 'websiteUrl',
      label: 'Website',
      options: {
        render: renderUrl,
      },
    },
    { name: 'tomSubdomain', label: 'Subdomain' },
    { name: 'country', label: 'Country' },
    { name: 'city', label: 'City' },
    { name: 'status', label: 'Status' },
  ];
};
