import React from 'react';
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { GsCreateVessel, GsVessels } from '~/assets';

const useStyles = makeStyles(({ breakpoints }) => ({
  createVesselImage: {
    'img&': {
      [breakpoints.down('sm')]: {
        display: 'block',
        margin: '0 auto',
        maxWidth: 400,
      },
    },
  },
}));

export const Vessels = () => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <img src={GsVessels} alt="Vessels menu link" />
          </Grid>

          <Grid item xs={12} sm={6} md={8} lg={9}>
            <Typography variant="body1">
              The Hatch Clerk App allows for storing vessel information for ships calling your port.
              To add a new vessel to your vessel list, select the Vessels option in the menu on your
              left-hand side.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={8}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1">
                  All ships already existing will be displayed. Insert a new vessel by selecting the
                  New Vessel button on top of the list on your right-hand side.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Upon selection, a creation screen will open for you to insert the master data of
                  the vessel. Furthermore, it allows you to upload a vessel geometry via drag and
                  drop. The geometry is a prerequisite for the stowage information to be displayed
                  later on in the mobile app.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  You can change vessel details even after creation by clicking on the vessel&apos;s
                  IMO number and selecting edit (top-right corner).
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <img
              src={GsCreateVessel}
              className={classes.createVesselImage}
              alt="Create vessel dialog"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          The displayed number of rows is limited. Therefore, if you cannot find your vessel in the
          list, please check if there are more vessel pages at the bottom of the list.
        </Typography>
      </Grid>
    </Grid>
  );
};
