import { User } from '~/types';

export const user: User = {
  sub: '1',
  firstName: 'Jon',
  name: 'Snow',
  emailAddress: 'jon.snow@example.com',
  jobTitle: 'King in the North',
  department: "Night's Watch",
  organisationId: '1',
  groups: [],
} as User;
