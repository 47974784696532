import React, { useContext, useEffect, useRef, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import {
  AccessControl,
  ApiResponse,
  Application as ApplicationType,
  AppStatusAdmin,
  AppStatusDisable,
  AppStatusEnable,
  AuthContext,
  Button,
  IApplicationProps,
  IApplicationRef,
  Loading,
  NotificationError,
  Paper,
  UserRoles,
  useToggle,
} from '@hpc/components';

import { AppStatus } from '~/components';
import { api } from '~/utils';

import { appConfig } from './config';

interface IStyleProps {
  image: string;
}

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    position: 'relative',
    padding: spacing(2, 4),
    paddingRight: 250,
  },
  background: ({ image }: IStyleProps) => ({
    position: 'absolute',
    zIndex: 1,
    height: 498,
    width: '100%',
    bottom: 0,
    right: 0,
    backgroundImage: `linear-gradient(140deg, #ffffffff 20%, #ffffff00 70%), url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '50% 0',
  }),
  appName: {
    position: 'relative',
    height: 500,
    width: 250,
  },
  appTitle: {
    position: 'relative',
    zIndex: 2,
    fontSize: 35,
    fontWeight: 100,
  },
  controls: {
    marginLeft: 'auto',
  },
  content: {
    width: '100%',
    '& > .MuiPaper-root': {
      height: '100%',
    },
  },
}));

interface IProps {
  onUpdate: () => Promise<void>;
}

export const Application = ({ onUpdate }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { accessControl, user } = useContext(AuthContext);
  const params = useParams();
  const [isEditing, toggleEditing] = useToggle();
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const [isLoading, toggleLoading] = useToggle(true);
  const [app, setApp] = useState<ApplicationType>(null);
  const classes = useStyles({ image: app?.image || '' });

  const childRef = useRef<IApplicationRef>(null);

  const organisationId = params?.organisationId || user.organisationId;

  const fetchApp = async () => {
    try {
      const response = await api.get<ApiResponse<ApplicationType[]>>(
        `/organisations/${organisationId}/apps`
      );
      const application = response.data.payload?.find((app) => app.name === params.appId);
      setApp({
        ...appConfig[application.name],
        ...application,
      });
    } catch (e) {
      enqueueSnackbar('Error fetching app details.', { variant: 'error' });
    }
    toggleLoading(false);
  };

  useEffect(() => {
    if (organisationId) {
      fetchApp();
    }
  }, [organisationId]);

  const handleEnable = async () => {
    toggleSubmitting(true);
    try {
      await api.post(`/organisations/${organisationId}/apps/${app.name}/enable`);
      await fetchApp();
      enqueueSnackbar('App has been enabled.', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Error enabling app.', { variant: 'error' });
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    await fetchApp();
    toggleSubmitting(false);
  };

  const handleDisable = async () => {
    toggleSubmitting(true);
    try {
      await api.post(`/organisations/${organisationId}/apps/${app.name}/disable`);
      await fetchApp();
      enqueueSnackbar('App has been disabled.', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Error disabling app.', { variant: 'error' });
    }
    toggleSubmitting(false);
  };

  const handleSave = async () => {
    toggleSubmitting(true);
    await childRef?.current?.submit();
    await onUpdate();
    toggleSubmitting(false);
    toggleEditing(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!app) {
    return <NotificationError title="Not found" />;
  }

  if (!accessControl.isSuperAdmin && AppStatusAdmin.includes(app.status)) {
    return (
      <NotificationError
        title="Unavailable"
        message="The application is not available for your organisation. Please contact support for more information."
      />
    );
  }

  const AppComponent: React.ForwardRefExoticComponent<
    IApplicationProps & React.RefAttributes<IApplicationRef>
  > = app.component;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="h1">Application configuration</Typography>
          </Grid>
          <Grid item>
            <AppStatus status={app.status} />
          </Grid>
          <Grid item className={classes.controls}>
            {isEditing ? (
              <Grid container>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={isSubmitting}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="button"
                    variant="outlined"
                    disabled={isSubmitting}
                    onClick={toggleEditing}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Button type="button" color="primary" variant="outlined" onClick={toggleEditing}>
                Edit
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container wrap="nowrap">
          <Grid item>
            <Paper variant="outlined" square className={classes.appName}>
              <Grid className={classes.content} container>
                <Grid item>
                  <Typography variant="body1" className={classes.appTitle}>
                    {app.title}
                  </Typography>
                </Grid>
              </Grid>
              <div className={classes.background} />
            </Paper>
          </Grid>
          <Grid item className={classes.content}>
            <Paper variant="outlined" square>
              <AppComponent ref={childRef} app={app} isEditing={isEditing} onSubmit={fetchApp} />
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <AccessControl userRole={UserRoles.SUPER_ADMIN}>
        <Grid item xs={12}>
          <Grid item>
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              onClick={() =>
                AppStatusDisable.includes(app.status) ? handleDisable() : handleEnable()
              }
              disabled={
                !AppStatusEnable.concat(AppStatusDisable).includes(app.status) ||
                isEditing ||
                isSubmitting
              }
              loading={isSubmitting && !isEditing}
            >
              {AppStatusDisable.includes(app.status) ? 'Disable' : 'Enable'} Application
            </Button>
          </Grid>
        </Grid>
      </AccessControl>
    </Grid>
  );
};
