import React from 'react';
import { MUIDataTableColumn } from 'mui-datatables';
import { Link } from 'react-router-dom';

import { Bay, VesselCall } from '~/types';
import { formatDateTime } from '~/utils';

const filterMap = {
  bay: 'bay',
  deckHold: 'dh',
  workingDirection: 'dir',
  weight: 'weight',
  weightUnit: 'wunit',
  note: 'note',
};

export const getBaysColumns = (
  bays: Bay[],
  vesselCall: VesselCall,
  filters: Partial<Record<keyof typeof filterMap, string>>
): MUIDataTableColumn[] => {
  const renderBay = (value) => (
    <Link
      to={`/hatch-clerk/${vesselCall.isArchived ? 'archived-' : ''}vessel-calls/${
        vesselCall.callNumber
      }/containers`}
      state={{
        filters: {
          bay: bays[value].bay,
        },
      }}
    >
      {bays[value].bay}
    </Link>
  );

  const renderDeckHold = (value) => (
    <Link
      to={`/hatch-clerk/${vesselCall.isArchived ? 'archived-' : ''}vessel-calls/${
        vesselCall.callNumber
      }/containers`}
      state={{
        filters: {
          bay: bays[value].bay,
          dh: bays[value].deckHold,
        },
      }}
    >
      {bays[value].deckHold}
    </Link>
  );

  const renderWorkingDirection = (value) => (
    <Link
      to={`/hatch-clerk/${vesselCall.isArchived ? 'archived-' : ''}vessel-calls/${
        vesselCall.callNumber
      }/containers`}
      state={{
        filters: {
          bay: bays[value].bay,
          dh: bays[value].deckHold,
          dir: bays[value].workingDirection,
        },
      }}
    >
      {bays[value].workingDirection}
    </Link>
  );

  const columns: MUIDataTableColumn[] = [
    {
      name: 'bay',
      label: 'Bay',
      options: {
        customBodyRenderLite: renderBay,
      },
    },
    {
      name: 'deckHold',
      label: 'Deck/Hold',
      options: {
        customBodyRenderLite: renderDeckHold,
      },
    },
    {
      name: 'workingDirection',
      label: 'Load/Discharge',
      options: {
        customBodyRenderLite: renderWorkingDirection,
      },
    },
    { name: 'status', label: 'Status' },
    {
      name: 'closingTime',
      label: 'Closing time',
      options: {
        customBodyRenderLite: (value) => formatDateTime(bays[value].closingTime),
      },
    },
    { name: 'closedBy', label: 'Closed by' },
    { name: 'containerCount', label: '# of containers' },
    { name: 'note', label: 'Note' },
  ];

  return columns.map((column) => ({
    ...column,
    options: {
      sortThirdClickReset: true,
      ...column.options,
      ...(filters[filterMap[column.name]] && {
        filterList: filters[filterMap[column.name]]
          ?.split(',')
          ?.map((val) => (val === '1' ? true : val === '0' ? false : val)),
      }),
    },
  }));
};
