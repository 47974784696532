import React from 'react';

import { MUIDataTableOptions } from 'mui-datatables';

import { DataGrid } from '@hpc/components';
import { Container, WorkingDirection } from '~/types';
import { getContainerColumns, WorkingBay } from './getContainerColumns';

interface IProps {
  containers: Container[];
}

export const ContainersTable = ({ containers }: IProps) => {
  const grouped = containers
    .filter((container) => container.workingDirection !== WorkingDirection.UNDEFINED)
    .reduce((acc, container) => {
      const key = `${container.plannedPosition.bay}${container.plannedPosition.deckHold[0]}-${container.workingDirection}`;
      return {
        ...acc,
        [key]: acc[key] ? [...acc[key], container] : [container],
      };
    }, {});
  const workingBays: WorkingBay[] = Object.keys(grouped)
    .map((key) => ({
      workingBay: key,
      bay: grouped[key][0].plannedPosition.bay,
      deckHold: grouped[key][0].plannedPosition.deckHold,
      workingDirection: grouped[key][0].workingDirection,
      confirmed: grouped[key].filter(
        (container: Container) => container.confirmedPositions.bay.length > 0
      ).length,
      planned: grouped[key].length,
    }))
    .map((workingBay) => ({
      ...workingBay,
      percentage: Math.round((workingBay.confirmed / workingBay.planned) * 100),
    }));

  const columns = getContainerColumns(workingBays);

  const options: MUIDataTableOptions = {
    pagination: false,
    tableBodyMaxHeight: '350px',
    setTableProps: () => ({
      size: 'small',
    }),
  };

  return <DataGrid data={workingBays} columns={columns} options={options} />;
};
