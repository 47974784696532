import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { useSnackbar } from 'notistack';

import { useToggle } from '@hpc/components';

import { api } from '~/utils';
import { VesselDeleteDialog } from './VesselDeleteDialog';

const ColorButton = withStyles(() => ({
  root: {
    color: red[700],
    borderColor: red[700],
    '&:hover': {
      color: red[800],
      borderColor: red[800],
    },
  },
}))(Button);

interface IProps {
  imoNumber: string;
  onDelete: () => void;
}

export const VesselDeleteButton = ({ imoNumber, onDelete }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isDeletingVessel, toggleDeleteVessel] = useToggle();

  const deleteVessel = async () => {
    try {
      await api.delete(`/vessels/${imoNumber}`);
      enqueueSnackbar('Vessel has been deleted.', { variant: 'success' });
      onDelete();
    } catch (e) {
      enqueueSnackbar('Error deleting vessel.', { variant: 'error' });
    }
  };

  return (
    <>
      <ColorButton type="button" variant="outlined" onClick={toggleDeleteVessel}>
        Delete vessel
      </ColorButton>
      {isDeletingVessel && (
        <VesselDeleteDialog onCancel={toggleDeleteVessel} onConfirm={deleteVessel} />
      )}
    </>
  );
};
