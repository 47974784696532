import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Check } from '@material-ui/icons';

const useStyles = makeStyles(({ palette }) => ({
  icon: {
    color: palette.success.main,
  },
}));

export const StatusPositive = () => {
  const classes = useStyles();
  return <Check className={classes.icon} />;
};
