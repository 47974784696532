import React from 'react';
import { Card, CardContent, DetailField } from '@hpc/components';
import { Group } from '~/types';

interface IProps {
  group: Group;
}

export const GroupView = ({ group }: IProps) => {
  return (
    <Card>
      <CardContent>
        <DetailField label="Description" value={group?.description || ''} />
      </CardContent>
    </Card>
  );
};
