import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { AppRoutes as HatchClerkRoutes } from '@hpc/hatch-clerk';

import { AppRoutes } from './AppRoutes';

export const App = () => {
  return (
    <Routes>
      <HatchClerkRoutes path="/hatch-clerk/*" />
      <Route path="*" element={<AppRoutes />} />
    </Routes>
  );
};
