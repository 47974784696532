import React from 'react';
import { Button, Card, CardActions, CardContent, Grid, Typography } from '@material-ui/core';

import { useStyles } from '../styles';

export const ConfirmPasswordCompleted = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card} elevation={6}>
      <CardContent>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h1">Password changed</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">
              Your password has been changed. You can visit the Sign In page now and sign in with
              the new password.
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button href="/login" color="primary" fullWidth>
          Go to Sign In page
        </Button>
      </CardActions>
    </Card>
  );
};
