import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import { Application, Paper } from '@hpc/components';
import { AppStatus } from '~/components';

interface IStyleProps {
  image: string;
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  paper: {
    position: 'relative',
    padding: spacing(2, 4),
    paddingRight: 250,
    cursor: 'pointer',
  },
  content: {
    position: 'relative',
    zIndex: 2,
  },
  background: ({ image }: IStyleProps) => ({
    position: 'absolute',
    zIndex: 1,
    height: '100%',
    width: 350,
    top: 0,
    right: 0,
    backgroundImage: `linear-gradient(55deg, #ffffffff 20%, #ffffff00 65%), url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '50px 0',
  }),
  appStatus: {
    whiteSpace: 'nowrap',
    textAlign: 'right',
  },
  appInfo: {
    flexGrow: 1,
  },
  appName: {
    color: palette.grey.A400,
    '& .MuiTypography-body1': {
      fontWeight: 100,
      fontSize: 30,
    },
  },
  appDescription: {
    color: palette.grey.A200,
  },
}));

interface IProps {
  app: Application;
}

export const AppCard = ({ app: { name, title, description, image, status } }: IProps) => {
  const navigate = useNavigate();
  const classes = useStyles({ image });

  const handleClick = () => {
    navigate(name);
  };

  return (
    <Paper variant="outlined" square className={classes.paper}>
      <Grid
        className={classes.content}
        container
        spacing={4}
        alignItems="center"
        wrap="nowrap"
        onClick={handleClick}
      >
        <Grid item className={classes.appStatus}>
          <AppStatus status={status} />
        </Grid>
        <Grid item className={classes.appInfo}>
          <Grid container direction="column" spacing={1}>
            <Grid item className={classes.appName}>
              <Typography variant="body1">{title}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" className={classes.appDescription}>
                {description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.background} />
    </Paper>
  );
};
