import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ spacing }) => ({
  root: {
    height: '100vh',
    width: '100vw',
  },
  card: {
    width: 400,
    padding: spacing(2),
  },
  cardActions: {
    padding: spacing(2),
  },
  forgotPasswordLink: {
    fontWeight: 600,
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));
