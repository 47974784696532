import React from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import {
  AddCircleOutline as Add,
  Autorenew,
  Block,
  CheckCircleOutlineOutlined as Check,
  CancelOutlined as Cross,
  ErrorOutlineOutlined as Warning,
} from '@material-ui/icons';

import { AppStatus as AppStatusType, AppStatusLabel } from '@hpc/components';

const useStyles = makeStyles(({ palette }) => ({
  [AppStatusType.AVAILABLE]: {
    color: palette.grey.A700,
  },
  [AppStatusType.UNAVAILABLE]: {
    color: palette.grey.A200,
  },
  [AppStatusType.UPDATING]: {
    color: palette.warning.light,
    animation: '$rotate 3s linear infinite',
  },
  '@keyframes rotate': {
    to: {
      transform: 'rotate(360deg)',
    },
  },
  [AppStatusType.ENABLED]: {
    color: palette.success.main,
  },
  [AppStatusType.DISABLED]: {
    color: palette.error.dark,
  },
  [AppStatusType.WARNING]: {
    color: palette.warning.light,
  },
  [AppStatusType.ERROR]: {
    color: palette.error.dark,
  },
}));

const icons = {
  [AppStatusType.AVAILABLE]: Add,
  [AppStatusType.UNAVAILABLE]: Block,
  [AppStatusType.UPDATING]: Autorenew,
  [AppStatusType.ENABLED]: Check,
  [AppStatusType.DISABLED]: Cross,
  [AppStatusType.WARNING]: Warning,
  [AppStatusType.ERROR]: Warning,
};

interface IProps {
  status: AppStatusType;
}

export const AppStatus = ({ status }: IProps) => {
  const classes = useStyles();
  const Icon = icons[status];
  return (
    <Tooltip title={AppStatusLabel[status] || ''} placement="top">
      <Icon fontSize="large" className={classes[status]} />
    </Tooltip>
  );
};
