import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import {
  LayoutContext,
  Loading,
  NotificationError,
  SidebarAction,
  useToggle,
} from '@hpc/components';

import { Bay, BayApi, Container, ContainerApi, VesselCallComplete, VesselCallApi } from '~/types';
import { api, baysParser, containersParser, vesselCallParser } from '~/utils';

import { Bays } from '../bays';
import { Containers } from '../containers';

import { getSidebarElement } from './sidebar';
import { VesselCall } from './VesselCall';

export const VesselCallRoutes = () => {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { setSidebarElements } = useContext(LayoutContext);
  const [loading, setLoading] = useToggle(true);
  const [vesselCall, setVesselCall] = useState<VesselCallComplete>(null);
  const [bays, setBays] = useState<Bay[]>(null);
  const [containers, setContainers] = useState<Container[]>(null);

  const fetchVesselCall = async () => {
    try {
      const [vcResponse, baysResponse, containersResponse] = await Promise.all([
        api.get<VesselCallApi>(`/vessel-calls/${params.vesselCallId}`),
        api.get<BayApi[]>(`/vessel-calls/${params.vesselCallId}/bays`),
        api.get<ContainerApi[]>(`/vessel-calls/${params.vesselCallId}/bay-occupancies`),
      ]);
      setVesselCall(vesselCallParser(vcResponse.data));
      setBays(baysParser(baysResponse.data, containersResponse.data));
      setContainers(containersParser(containersResponse.data));
    } catch (e) {
      enqueueSnackbar('Error fetching vessel call details.', { variant: 'error' });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (params?.vesselCallId) {
      fetchVesselCall();
    }
  }, [params?.vesselCallId]);

  useEffect(() => {
    if (vesselCall) {
      setSidebarElements({
        type: SidebarAction.UPDATE,
        value: getSidebarElement(vesselCall),
      });
    }

    return () => {
      if (vesselCall) {
        setSidebarElements({
          type: SidebarAction.REMOVE,
          value: `vesselCall-${vesselCall.callNumber}`,
        });
      }
    };
  }, [vesselCall]);

  if (loading) {
    return <Loading />;
  }

  if (!loading && !vesselCall) {
    return <NotificationError isCard title="Vessel call not found" />;
  }

  return (
    <Routes>
      <Route
        path=""
        element={
          <VesselCall
            vesselCallComplete={vesselCall}
            bays={bays}
            containers={containers}
            onUpdate={fetchVesselCall}
          />
        }
      />
      <Route
        path="bays"
        element={<Bays vesselCall={vesselCall} bays={bays} containers={containers} />}
      />
      <Route
        path="containers"
        element={<Containers vesselCall={vesselCall} bays={bays} containers={containers} />}
      />
      <Route path="*" element={<Navigate to="/hatch-clerk" replace />} />
    </Routes>
  );
};
