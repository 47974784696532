import { object, string } from 'yup';
import zxcvbn from 'zxcvbn';

import {
  ERROR_FIELD_PASSWORD_LOWERCASE,
  ERROR_FIELD_PASSWORD_NUMBER,
  ERROR_FIELD_PASSWORD_SHORT,
  ERROR_FIELD_PASSWORD_UPPERCASE,
  ERROR_FIELD_PASSWORD_WEAK,
  ERROR_FIELD_REQUIRED,
} from '~/constants';

export const signInValidationSchema = object({
  subdomain: string().required(ERROR_FIELD_REQUIRED),
  username: string().required(ERROR_FIELD_REQUIRED),
  password: string().required(ERROR_FIELD_REQUIRED),
});

export const forgotPasswordValidationSchema = object({
  subdomain: string().required(ERROR_FIELD_REQUIRED),
  username: string().required(ERROR_FIELD_REQUIRED),
});

export const setNewPasswordValidationSchema = object({
  password: string()
    .required(ERROR_FIELD_REQUIRED)
    .matches(/[a-z]/, ERROR_FIELD_PASSWORD_LOWERCASE)
    .matches(/[A-Z]/, ERROR_FIELD_PASSWORD_UPPERCASE)
    .matches(/\d/, ERROR_FIELD_PASSWORD_NUMBER)
    .min(8, ERROR_FIELD_PASSWORD_SHORT)
    .test('strength', ERROR_FIELD_PASSWORD_WEAK, (value: string) => zxcvbn(value).score > 2),
});

export const confirmPasswordValidationSchema = setNewPasswordValidationSchema.concat(
  object({
    confirmationCode: string().required(ERROR_FIELD_REQUIRED),
  })
);
