import React, { useContext } from 'react';
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import {
  AccessLevels,
  Apps,
  AuthContext,
  Card,
  Editable,
  hasAppAccess,
  useToggle,
} from '@hpc/components';

import { DataTest } from '~/constants';
import { VesselCall, VesselCallEditInput } from '~/types';
import { api, vesselCallEditComposer, vesselCallEditValidationSchema } from '~/utils';

import { BaplieUpdate } from '../Baplie';
import { FileStatus } from '../FileStatus';

import { VesselCallDetailsEdit } from './VesselCallDetailsEdit';
import { VesselCallDetailsView } from './VesselCallDetailsView';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    height: '100%',
    padding: 0,
  },
  wrapper: {
    height: '100%',
  },
  title: {
    fontSize: '1.1rem',
  },
  details: {
    padding: spacing(2),
  },
  uploads: {
    marginTop: 'auto',
  },
  divider: {
    width: '100%',
  },
}));

interface IProps {
  vesselCall: VesselCall;
  onUpdate: () => Promise<void>;
}

export const VesselCallDetails = ({ vesselCall, onUpdate }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { accessControl } = useContext(AuthContext);
  const classes = useStyles();
  const [isUpdatingBaplieArrival, toggleUpdatingBaplieArrival] = useToggle();
  const [isUpdatingBaplieDeparture, toggleUpdatingBaplieDeparture] = useToggle();

  const updateVesselCall = async (data: VesselCallEditInput) => {
    const vesselCallApi = vesselCallEditComposer(data);
    try {
      await api.patch(`/vessel-calls/${vesselCall.callNumber}`, vesselCallApi);
      await onUpdate();
      enqueueSnackbar('Vessel call has been updated.', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Error updating vessel call.', { variant: 'error' });
    }
  };

  return (
    <Card className={classes.root} data-test={DataTest.HC_VESSEL_CALL_DETAILS}>
      <Grid container spacing={0} alignContent="space-between" className={classes.wrapper}>
        <Grid item xs={12} className={classes.details}>
          <Editable
            title={
              <Typography variant="h5" className={classes.title}>
                Vessel call details
              </Typography>
            }
            entity={vesselCall}
            renderEdit={(control, errors) => (
              <VesselCallDetailsEdit vesselCall={vesselCall} control={control} errors={errors} />
            )}
            renderNonEdit={<VesselCallDetailsView vesselCall={vesselCall} />}
            canEdit={hasAppAccess(accessControl, Apps.HATCH_CLERK, AccessLevels.WRITE)}
            buttonSize="small"
            onSubmit={updateVesselCall}
            validator={vesselCallEditValidationSchema}
          />
        </Grid>

        <Grid item xs={12} className={classes.uploads}>
          <Divider className={classes.divider} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-evenly"
            spacing={0}
            wrap="nowrap"
          >
            <Grid item>
              <FileStatus
                title="Arrival BAPLIE"
                status={vesselCall.baplieStatusArrival}
                message={vesselCall.baplieErrorArrival}
                canEdit={hasAppAccess(accessControl, Apps.HATCH_CLERK, AccessLevels.WRITE)}
                onEdit={toggleUpdatingBaplieArrival}
              />
            </Grid>

            <Divider orientation="vertical" flexItem />

            <Grid item>
              <FileStatus
                title="Departure BAPLIE"
                status={vesselCall.baplieStatusDeparture}
                message={vesselCall.baplieErrorDeparture}
                canEdit={hasAppAccess(accessControl, Apps.HATCH_CLERK, AccessLevels.WRITE)}
                onEdit={toggleUpdatingBaplieDeparture}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {isUpdatingBaplieArrival && (
        <BaplieUpdate
          callNumber={vesselCall.callNumber}
          baplieType="arrival"
          onSuccess={onUpdate}
          onClose={() => toggleUpdatingBaplieArrival(false)}
        />
      )}
      {isUpdatingBaplieDeparture && (
        <BaplieUpdate
          callNumber={vesselCall.callNumber}
          baplieType="departure"
          onSuccess={onUpdate}
          onClose={() => toggleUpdatingBaplieDeparture(false)}
        />
      )}
    </Card>
  );
};
