import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ButtonProps, Grid, Typography } from '@material-ui/core';
import {
  Control,
  DeepPartial,
  FieldErrors,
  SubmitHandler,
  UnpackNestedValue,
  useForm,
} from 'react-hook-form';
import { AnyObjectSchema } from 'yup';

import { DataTest } from '~/constants';
import { useToggle } from '~/hooks';
import { setErrors } from '~/utils';

import { Button } from '../Button';

interface IProps<T> {
  title?: React.ReactNode;
  entity?: UnpackNestedValue<DeepPartial<T>>;
  renderEdit: (control: Control<T>, errors: FieldErrors<T>) => React.ReactNode;
  renderNonEdit: React.ReactNode;
  canEdit: boolean;
  buttonSize?: ButtonProps['size'];
  onSubmit: SubmitHandler<T>;
  validator?: AnyObjectSchema;
}

export const Editable = <T extends Record<string, unknown>>({
  title,
  entity,
  renderEdit,
  renderNonEdit,
  canEdit,
  buttonSize = 'medium',
  onSubmit,
  validator,
}: IProps<T>) => {
  const [isEditing, toggleEdit] = useToggle();

  const { control, formState, handleSubmit, reset, setError } = useForm<T>({
    ...(validator && { resolver: yupResolver(validator) }),
    mode: 'onBlur',
  });

  useEffect(() => {
    if (entity) {
      reset(entity);
    }
  }, [entity]);

  const submitAction: SubmitHandler<T> = async (data) => {
    try {
      await onSubmit(data);
      toggleEdit();
    } catch (e) {
      if (e.error && e.error.code === 'VALIDATION_ERROR') {
        if (e.error.extensions) {
          setErrors(e.error.extensions, setError);
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(submitAction)}>
      <Grid container direction="column">
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid item data-testid={DataTest.EDITABLE_TITLE}>
              {!title || typeof title === 'string' ? (
                <Typography variant="h1">{title || '\u00a0'}</Typography>
              ) : (
                title
              )}
            </Grid>
            {canEdit && (
              <Grid item data-testid={DataTest.EDITABLE_ACTIONS}>
                {isEditing ? (
                  <Grid container>
                    <Grid item>
                      <Button
                        type="submit"
                        size={buttonSize}
                        variant="contained"
                        color="primary"
                        loading={formState.isSubmitting}
                      >
                        Save
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="button"
                        size={buttonSize}
                        variant="outlined"
                        disabled={formState.isSubmitting}
                        onClick={toggleEdit}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Button
                    type="button"
                    size={buttonSize}
                    color="primary"
                    variant="outlined"
                    disabled={!entity}
                    onClick={toggleEdit}
                  >
                    Edit
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item data-testid={DataTest.EDITABLE_CONTENT}>
          {isEditing ? renderEdit(control, formState.errors) : renderNonEdit}
        </Grid>
      </Grid>
    </form>
  );
};
