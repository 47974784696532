import React from 'react';
import { Notification, NotificationType } from './Notification';

interface IProps {
  isCard?: boolean;
  title?: string;
  message?: string;
}

export const NotificationError = ({ isCard = false, title = 'Not found', message }: IProps) => {
  return (
    <Notification isCard={isCard} type={NotificationType.ERROR} title={title} message={message} />
  );
};
