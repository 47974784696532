import React from 'react';
import { Notification, NotificationType } from './Notification';

interface IProps {
  isCard?: boolean;
  title?: string;
  message?: string;
}

export const NotificationNoData = ({ isCard = false, title = 'No data', message }: IProps) => {
  return (
    <Notification isCard={isCard} type={NotificationType.WARNING} title={title} message={message} />
  );
};
