import { AppStatus as AppStatusType, ProductLabels, Products } from '@hpc/components';
import { HatchClerkIcon } from '~/assets';
import { App } from './types';

export const appConfig: Record<Products, App> = {
  [Products.HATCH_CLERK]: {
    name: Products.HATCH_CLERK,
    title: ProductLabels[Products.HATCH_CLERK],
    url: '/hatch-clerk',
    icon: HatchClerkIcon,
    status: AppStatusType.UNAVAILABLE,
  },
};
