import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';

import { cranes, cranesLg, cranesMd, cranesXl } from '~/assets';
import { DataTest } from '~/constants';

import { Apps, VesselCalls } from './components';

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    margin: spacing(-2),
  },
  header: {
    position: 'relative',
    width: '100%',
    height: 250,
    padding: spacing(8),
    [breakpoints.up('xl')]: {
      height: 300,
    },
  },
  splash: {
    zIndex: -100,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 250,
    backgroundImage: `url(${cranes})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    filter: 'brightness(50%) blur(1px)',
    [breakpoints.between('md', 'lg')]: {
      backgroundImage: `url(${cranesMd})`,
    },
    [breakpoints.between('lg', 'xl')]: {
      backgroundImage: `url(${cranesLg})`,
    },
    [breakpoints.up('xl')]: {
      backgroundImage: `url(${cranesXl})`,
      height: 300,
    },
  },
  display: {
    color: palette.primary.contrastText,
    fontSize: spacing(6),
  },
  lead: {
    color: palette.primary.contrastText,
  },
  main: {
    margin: spacing(2),
  },
}));

export const Home = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <header className={classes.header} data-test={DataTest.DASHBOARD_BANNER}>
        <Typography variant="h1" className={classes.display}>
          TOS Add-Ons
        </Typography>
        <Typography variant="h2" className={classes.lead}>
          Think big. Start small.
        </Typography>
        <div className={classes.splash} />
      </header>
      <div className={classes.main}>
        <Grid container>
          <Grid item xs={12} sm={6} md={7} lg={8} xl={9}>
            <Apps />
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={4} xl={3}>
            <VesselCalls />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
