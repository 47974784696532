import React, { useMemo } from 'react';
import { MUIDataTableOptions } from 'mui-datatables';

import { DataGrid, Loading, NotificationNoData } from '@hpc/components';
import { VesselCallComplete } from '~/types';
import { getVesselCallsColumns } from './getVesselCallsColumns';

interface IProps {
  loading: boolean;
  vesselCalls: VesselCallComplete[];
}

export const VesselCallsTable = ({ loading, vesselCalls }: IProps) => {
  const columns = useMemo(() => getVesselCallsColumns(vesselCalls), [vesselCalls]);
  const options: MUIDataTableOptions = {
    search: true,
    filter: true,
    filterType: 'multiselect',
  };

  if (loading) {
    return <Loading />;
  }

  if (!vesselCalls.length) {
    return <NotificationNoData isCard message="The vessel call list is empty" />;
  }

  return <DataGrid data={vesselCalls} columns={columns} options={options} />;
};
