import { object, string } from 'yup';
import { ERROR_FIELD_REQUIRED, ERROR_FIELD_SUBDOMAIN } from '@hpc/components';

const subdomainRegexp = /^[a-z0-9]+([_-]?[a-z0-9])*$/;

export const organisationValidationSchema = object({
  name: string().required(ERROR_FIELD_REQUIRED),
  tomSubdomain: string()
    .required(ERROR_FIELD_REQUIRED)
    .matches(subdomainRegexp, ERROR_FIELD_SUBDOMAIN),
  websiteUrl: string().required(ERROR_FIELD_REQUIRED),
  country: string().required(ERROR_FIELD_REQUIRED),
  city: string().required(ERROR_FIELD_REQUIRED),
  postalCode: string().required(ERROR_FIELD_REQUIRED),
  street: string().required(ERROR_FIELD_REQUIRED),
});
