import { AccessControlType, AccessLevels, Apps, User, UserRoles } from '~/types';

const USER_GROUP = 'user';
const ADMIN_GROUP = 'admin';
const ADMIN_ORGANISATION = 'admin';

export const getAccessControl = (user?: User): AccessControlType => {
  const isSuperAdmin = user?.organisationSubdomain === ADMIN_ORGANISATION;

  const isAdmin = isSuperAdmin || !!user?.groups.includes(ADMIN_GROUP);
  const isUser = isAdmin || !!user?.groups.includes(USER_GROUP);

  const hasAppAdmin = (app: string) =>
    isAdmin || !!user?.groups.includes(`${app}.${AccessLevels.ADMIN}`);
  const hasAppWrite = (app: string) =>
    hasAppAdmin(app) || !!user?.groups.includes(`${app}.${AccessLevels.WRITE}`);
  const hasAppRead = (app: string) =>
    hasAppWrite(app) || !!user?.groups.includes(`${app}.${AccessLevels.READ}`);

  return {
    isUser,
    isAdmin,
    isSuperAdmin,
    hasAppAdmin,
    hasAppWrite,
    hasAppRead,
  };
};

export const hasRole = (accessControl: AccessControlType, userRole: UserRoles) => {
  const roleAccess = {
    [UserRoles.USER]: accessControl.isUser,
    [UserRoles.ADMIN]: accessControl.isAdmin,
    [UserRoles.SUPER_ADMIN]: accessControl.isSuperAdmin,
  };

  return roleAccess[userRole];
};

export const hasAppAccess = (
  accessControl: AccessControlType,
  app: Apps,
  accessLevel: AccessLevels
) => {
  const appAccess = {
    [AccessLevels.READ]: accessControl.hasAppRead(app),
    [AccessLevels.WRITE]: accessControl.hasAppWrite(app),
    [AccessLevels.ADMIN]: accessControl.hasAppAdmin(app),
  };

  return appAccess[accessLevel];
};
