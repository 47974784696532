import { Apps, AppStatus } from '~/types';

export type AppItem = {
  label: string;
  app: Apps;
  url: string;
  external?: boolean;
  status?: AppStatus;
};

export const apps: AppItem[] = [
  {
    label: 'Hatch Clerk',
    app: Apps.HATCH_CLERK,
    url: '/hatch-clerk',
  },
  {
    label: 'Hatch Clerk Mobile',
    app: Apps.HATCH_CLERK_MOBILE,
    external: true,
    url: '/hatch-clerk/mobile/HatchClerkClient.Starter.application',
  },
];
