import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { GsCreateUser } from '~/assets';

export const Users = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6} lg={8}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1">
              User Management is accessible to administrators only. To enable members of your
              organisation to use the platform, access the Users option in the menu on your
              left-hand side. All current users in the system and their current status are displayed
              in a list.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              You can add new users via the corresponding button at the top-right corner. Upon
              selection, a creation screen will open for you to insert the user&apos;s master data.
              The creation triggers an email with login credentials to the respective user. The
              login credentials expire after a fixed amount of time but can always be resent by the
              administrator.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              If information needs to be adjusted or a user no longer requires access to the
              platform, open user details by clicking on the user&apos;s name in the list to either
              edit user details (top-right corner) or deactivate the user by using the corresponding
              button.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <img src={GsCreateUser} alt="Create user dialog" />
      </Grid>
    </Grid>
  );
};
