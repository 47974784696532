import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';

import { Card } from '../Card';
import { barHeight, barHeightGrow } from './constants';
import { Spinner } from './Spinner';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(8),
  },
  spinner: {
    '&.MuiGrid-item': {
      height: barHeight + barHeightGrow,
      marginBottom: spacing(2),
    },
  },
}));

interface IProps {
  isCard?: boolean;
  message?: string;
}

export const Loading = ({ isCard = false, message }: IProps) => {
  const classes = useStyles();

  const content = (
    <Grid container alignItems="center" direction="column" spacing={2}>
      <Grid item className={classes.spinner}>
        <Spinner />
      </Grid>
      {message && (
        <Grid item>
          <Typography variant="body1">{message}</Typography>
        </Grid>
      )}
    </Grid>
  );
  return isCard ? (
    <Card className={classes.root}>{content}</Card>
  ) : (
    <div className={classes.root}>{content}</div>
  );
};
