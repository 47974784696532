import { User } from '~/types';

type UserField = {
  property: keyof User;
  label: string;
  optional?: boolean;
};

export const userDetails: UserField[] = [
  { property: 'sub', label: 'ID' },
  { property: 'emailAddress', label: 'Email' },
  { property: 'jobTitle', label: 'Job title' },
  { property: 'phone', label: 'Phone' },
  { property: 'department', label: 'Department' },
];

export const userEdit: UserField[] = [
  { property: 'firstName', label: 'First name' },
  { property: 'name', label: 'Last Name' },
  { property: 'emailAddress', label: 'Email' },
  { property: 'jobTitle', label: 'Job title', optional: true },
  { property: 'phone', label: 'Phone', optional: true },
  { property: 'department', label: 'Department', optional: true },
];
