import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Collapse,
  Divider,
  Fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Popover,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useLocation, useNavigate } from 'react-router-dom';

import { sidebarWidthOpen, LayoutContext } from '~/components';
import { useToggle } from '~/hooks';
import { AuthContext } from '~/utils';

import { SidebarElementStore } from './types';

interface IStyleProps {
  isFlyout: boolean;
  isSection: boolean;
  isSelected: boolean;
  isSidebarOpen: boolean;
  level: number;
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  listItem: ({ isFlyout, isSelected, level }: IStyleProps) => ({
    paddingLeft: spacing(2) + (isFlyout ? 0 : level * spacing(2)),
    borderLeft: `4px solid ${isSelected && !isFlyout ? palette.primary.main : 'transparent'}`,
  }),
  label: ({ isSelected }: IStyleProps) => ({
    '& .MuiListItemText-primary': {
      fontWeight: isSelected ? 600 : 400,
    },
  }),
  sectionLabel: {
    position: 'relative',
  },
  sectionDivider: {
    position: 'absolute',
    width: '100%',
    top: 23,
  },
  subheader: {
    display: 'inline-block',
    background: palette.background.paper,
    textTransform: 'uppercase',
    fontSize: '0.85rem',
    letterSpacing: 2,
    wordSpacing: 5,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    verticalAlign: 'top',
    textOverflow: 'ellipsis',
    maxWidth: sidebarWidthOpen * 0.8,
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    pointerEvents: 'auto',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

interface IProps {
  item: SidebarElementStore;
  level?: number;
  isFlyout?: boolean;
}

export const SidebarElement = ({ item, level, isFlyout }: IProps) => {
  const {
    access,
    action,
    children,
    dividerAfter,
    dividerBefore,
    exact,
    hidden,
    icon: Icon,
    id,
    isSection,
    label,
    visibleCollapsed,
    open,
    parent,
    url,
  } = item;
  const location = useLocation();
  const navigate = useNavigate();
  const isSelected = exact ? location.pathname === url : location.pathname.startsWith(url);
  const [elements, setElements] = useState<SidebarElementStore[]>([]);
  const { isSidebarOpen, sidebarElements } = useContext(LayoutContext);
  const { accessControl, user } = useContext(AuthContext);
  const [isOpen, toggleIsOpen] = useToggle(isSection || open);
  const popoverAnchor = useRef(null);
  const [isPopoverOpen, togglePopoverOpen] = useToggle();
  const classes = useStyles({ isFlyout, isSection, isSelected, isSidebarOpen, level });

  useEffect(() => {
    setElements(
      children
        .map((id) => sidebarElements.byId[id])
        .filter((el) => Boolean(el))
        .sort((a, b) => (a.priority < b.priority ? -1 : 1))
    );
  }, [children, sidebarElements]);

  useEffect(() => {
    toggleIsOpen(isSection || !!open);
  }, [isSection, open]);

  const handleClick = () => {
    if (action) {
      action(item, toggleIsOpen, navigate);
    } else if (url) {
      if (elements.length > 0) {
        toggleIsOpen(true);
      }
      navigate(url);
    }
  };

  const handleToggleOpen = (event: React.MouseEvent) => {
    toggleIsOpen();
    event.stopPropagation();
  };

  const handlePopoverOpen = () => {
    togglePopoverOpen(true);
  };

  const handlePopoverClose = () => {
    togglePopoverOpen(false);
  };

  if (hidden || (access && !access(accessControl, user))) {
    return null;
  }

  return (
    <>
      {dividerBefore && <Divider />}
      {isSection ? (
        <Collapse in={isSidebarOpen || isFlyout} className={classes.sectionLabel}>
          <Divider className={classes.sectionDivider} />
          <ListSubheader id={`sidebar-section-${id}`} component="div" className={classes.subheader}>
            {label}
          </ListSubheader>
        </Collapse>
      ) : (
        <ListItem
          id={`sidebar-item-${id}`}
          onClick={handleClick}
          button={!!action || !!url ? true : undefined}
          selected={isSelected}
          className={classes.listItem}
          ref={popoverAnchor}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {!!Icon && (
            <>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
            </>
          )}
          <ListItemText primary={label} className={classes.label} />
          {elements.length > 0 && (
            <Fade in={isSidebarOpen}>
              {isOpen ? (
                <ExpandLess onClick={handleToggleOpen} />
              ) : (
                <ExpandMore onClick={handleToggleOpen} />
              )}
            </Fade>
          )}
        </ListItem>
      )}
      {!isSidebarOpen && (level === 0 || elements.length > 0) && (
        <Popover
          id={`popover-${id}`}
          className={classes.popover}
          classes={{
            paper: classes.popoverContent,
          }}
          open={isPopoverOpen}
          anchorEl={popoverAnchor.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          PaperProps={{
            elevation: 1,
            onMouseEnter: handlePopoverOpen,
            onMouseLeave: handlePopoverClose,
          }}
        >
          {level === 0 && (
            <ListItem
              onClick={handleClick}
              button={!!action || !!url ? true : undefined}
              selected={isSelected}
            >
              <ListItemText primary={label} className={classes.label} />
            </ListItem>
          )}
          <List disablePadding>
            {elements.map((element) => (
              <SidebarElement
                key={element.id}
                item={element}
                level={!parent && isSection ? 0 : level + 1}
                isFlyout={true}
              />
            ))}
          </List>
        </Popover>
      )}
      {elements.length > 0 && (
        <Collapse in={(isSidebarOpen || visibleCollapsed || isFlyout) && isOpen}>
          <List disablePadding id={`submenu-${id}`}>
            {elements.map((element) => (
              <SidebarElement
                key={element.id}
                item={element}
                level={!parent && isSection ? 0 : level + 1}
                isFlyout={isFlyout}
              />
            ))}
          </List>
        </Collapse>
      )}
      {dividerAfter && <Divider />}
    </>
  );
};
