import React from 'react';
import {
  Padding,
  Size,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

export interface TableColumnOptions {
  render?: (index: number) => string | React.ReactNode;
  setCellProps?: (
    cellValue: unknown,
    rowIndex: number,
    columnIndex: number
  ) => Record<string, unknown>;
}

export interface TableColumnDef {
  label?: string;
  name: string;
  options?: TableColumnOptions;
}

export interface TableOptions {
  stickyHeader?: boolean;
  padding?: Padding;
  size?: Size;
}

interface IProps {
  columns: TableColumnDef[];
  data: Record<string, unknown>[];
  options?: TableOptions;
}

export const Table = ({ columns, data, options }: IProps) => {
  return (
    <MuiTable stickyHeader={options?.stickyHeader} padding={options?.padding} size={options?.size}>
      <TableHead>
        <TableRow>
          {columns.map(({ label, name }) => (
            <TableCell key={name}>{label}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((element, rowIndex) => (
          <TableRow key={rowIndex}>
            {columns.map(({ name, options }, columnIndex) => (
              <TableCell
                key={name}
                {...(options?.setCellProps instanceof Function &&
                  options.setCellProps(element[name], rowIndex, columnIndex))}
              >
                {options?.render instanceof Function ? options.render(rowIndex) : element[name]}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </MuiTable>
  );
};
