import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { StatusIcon } from '~/components';
import { BaplieStatus, VesselProfileStatus } from '~/types';

interface IStyleProps {
  canEdit: boolean;
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: ({ canEdit }: IStyleProps) => ({
    cursor: canEdit && 'pointer',
    padding: spacing(1),
  }),
  title: {
    display: 'inline-block',
    textTransform: 'uppercase',
    fontSize: '1rem',
    letterSpacing: 2,
    wordSpacing: 5,
    color: palette.text.secondary,
    width: '100%',
    textAlign: 'center',
  },
}));

interface IProps {
  title: string;
  status: BaplieStatus | VesselProfileStatus;
  message?: string;
  canEdit?: boolean;
  onEdit?: () => void;
}

export const FileStatus = ({ title, status, message, canEdit, onEdit }: IProps) => {
  const classes = useStyles({ canEdit });

  const onClick = () => {
    if (canEdit) {
      onEdit();
    }
  };

  return (
    <Grid
      className={classes.root}
      container
      alignItems="center"
      justifyContent="center"
      onClick={onClick}
    >
      <Grid item>
        <Typography variant="subtitle2" className={classes.title}>
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <StatusIcon status={status} message={message} />
      </Grid>
    </Grid>
  );
};
