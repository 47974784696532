import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Autorenew } from '@material-ui/icons';

const useStyles = makeStyles(({ palette }) => ({
  icon: {
    color: palette.warning.light,
    animation: '$rotate 3s linear infinite',
  },
  '@keyframes rotate': {
    to: {
      transform: 'rotate(360deg)',
    },
  },
}));

export const StatusLoading = () => {
  const classes = useStyles();
  return <Autorenew className={classes.icon} />;
};
