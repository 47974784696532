const parse = (file: string | ArrayBuffer) => {
  if (!file || typeof file !== 'string') {
    return;
  }

  const lines = file.split("'");
  // const interchangeHeader = lines.find((line) => line.startsWith('UNB'));
  // const messageHeader = lines.find((line) => line.startsWith('UNH'));
  // const BGM = lines.find((line) => line.startsWith('BGM'));
  const TDT = lines.find((line) => line.startsWith('TDT'));
  const TDTArray = TDT?.split('+');
  const vesselIdentification = TDTArray?.[TDTArray.length - 1]?.split(':');

  // const placeOfDeparture = lines.find((line) => line.startsWith('LOC+5'));
  const nextPortOfCall = lines.find((line) => line.startsWith('LOC+61'));

  // console.log({
  //   interchangeHeader,
  //   messageHeader,
  //   BGM,
  //   TDT,
  //   vesselIdentification,
  //   placeOfDeparture,
  //   nextPortOfCall,
  // });

  return {
    vesselName: vesselIdentification[vesselIdentification.length - 1],
    pod: nextPortOfCall,
    voyageNumber: TDTArray[2],
  };
};

export const baplie = { parse };
