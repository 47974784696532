import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, CardActions, CardContent, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Controller, useForm } from 'react-hook-form';

import {
  Button,
  PasswordInput,
  setErrors,
  SetNewPasswordInput,
  setNewPasswordValidationSchema,
} from '@hpc/components';

import { useStyles } from '../styles';
import { PasswordRequirements } from './PasswordRequirements';
import { PasswordStrength } from './PasswordStrength';

interface IProps {
  onSubmit: (data: SetNewPasswordInput) => Promise<void>;
}

export const SetNewPasswordForm = ({ onSubmit }: IProps) => {
  const classes = useStyles();
  const [formError, setFormError] = useState('');
  const [password, setPassword] = useState('');
  const { control, formState, handleSubmit, setError } = useForm<SetNewPasswordInput>({
    resolver: yupResolver(setNewPasswordValidationSchema),
    defaultValues: { password: '' },
  });

  const changePassword = async (data: SetNewPasswordInput) => {
    try {
      await onSubmit(data);
    } catch (e) {
      if (e.error && e.error.code === 'VALIDATION_ERROR') {
        if (e.error.extensions) {
          setErrors(e.error.extensions, setError);
        } else {
          setFormError(e.error.message);
        }
      }
    }
  };

  const handlePasswordUpdate = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPassword(event.target.value);
  };

  return (
    <form onSubmit={handleSubmit(changePassword)}>
      <Card className={classes.card} elevation={6}>
        <CardContent>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h1">Change password</Typography>
            </Grid>
            {formError && (
              <Grid item>
                <Alert severity="error">{formError}</Alert>
              </Grid>
            )}
            <Grid item>
              <Typography variant="body2">
                This is your first login, please change your password. In order to protect your
                account, make sure your password has at least:
              </Typography>
            </Grid>
            <Grid item>
              <PasswordRequirements password={password} />
              <PasswordStrength password={password} />
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="password"
                render={({ field: { onChange, ...props } }) => (
                  <PasswordInput
                    {...props}
                    label="New password"
                    error={!!formState.errors?.password}
                    helperText={formState.errors?.password?.message}
                    fullWidth
                    onChange={(event) => {
                      onChange(event);
                      handlePasswordUpdate(event);
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            loading={formState.isSubmitting}
            fullWidth
          >
            Change password
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};
