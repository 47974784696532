import React, { useContext } from 'react';
import { AppBar, Box, Grid, IconButton, makeStyles, Toolbar } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { Link, useNavigate } from 'react-router-dom';

import { Logo } from '~/assets';
import { LayoutContext } from '~/components';

import { navbarHeight } from '../constants';
import { AppsPicker, UserMenu } from './components';

const useStyles = makeStyles(({ spacing, zIndex }) => ({
  appBar: {
    zIndex: zIndex.drawer + 1,
    height: navbarHeight,
  },
  gutters: {
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
  },
  logo: {
    padding: spacing(1),
    '& > img': {
      height: spacing(4),
      verticalAlign: 'middle',
    },
  },
}));

export const Navbar = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { rootUrl } = useContext(LayoutContext);

  const onHelpClicked = () => navigate(`${rootUrl}/getting-started`);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar classes={{ gutters: classes.gutters }}>
        <Grid container alignItems="center" alignContent="center" justifyContent="space-between">
          <Grid item>
            <Box display="flex" justifyContent="center">
              <AppsPicker />
              <Link to="/" className={classes.logo}>
                <img src={Logo} alt="HPC logo" />
              </Link>
            </Box>
          </Grid>
          <Grid item>
            <IconButton color="inherit" onClick={onHelpClicked}>
              <HelpOutline />
            </IconButton>
            <UserMenu />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
