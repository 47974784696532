import { blue } from '@material-ui/core/colors';
import { createTheme, ThemeOptions } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: blue['500'],
      // main: '#004898',
      // dark: '#002269',
    },
    secondary: {
      main: '#c20430',
    },
  },
  typography: {
    fontFamily: ['"Helvetica"', '"Arial"', 'sans-serif'].join(','),
    fontSize: 14,
    h1: {
      fontSize: 32,
      paddingBottom: 8,
    },
    h2: {
      fontSize: 20,
      fontWeight: 'bold',
      paddingBottom: 8,
    },
    h3: {
      fontSize: 16,
      fontWeight: 'bold',
      paddingBottom: 4,
    },
    h4: {
      fontSize: 14,
      fontWeight: 'bold',
    },
  },
  props: {
    MuiGrid: {
      spacing: 2,
    },
  },
  overrides: {
    MUIDataTable: {
      responsiveStacked: {
        maxHeight: 'none',
        overflowX: 'auto',
      },
    },
  },
} as ThemeOptions);
