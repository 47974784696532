import React from 'react';
import { Dialog } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { ApiResponse } from '@hpc/components';

import { Vessel, VesselInput } from '~/types';
import { api } from '~/utils';
import { CreateVesselDialog } from './CreateVesselDialog';

interface IProps {
  onClose: () => void;
}

export const CreateVessel = ({ onClose }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleUploadVesselGeometry = async (imoNumber: string, geometry: File) => {
    try {
      if (geometry) {
        const formData = new FormData();
        formData.append('vesselGeometry', geometry);
        await api.post(`/vessels/${imoNumber}/vessel-geometry`, formData);
        enqueueSnackbar(`Vessel geometry has been been uploaded`, {
          variant: 'success',
        });
      }
    } catch (e) {
      enqueueSnackbar(
        `Error uploading vessel geometry. Please try to upload vessel geometry from the vessel details page.`,
        { variant: 'error' }
      );
    }
  };

  const handleSubmit = async (data: VesselInput) => {
    try {
      await api.post<ApiResponse<Vessel>>('/vessels', data);
      enqueueSnackbar('Vessel has been created', { variant: 'success' });

      if (data.geometry) {
        await handleUploadVesselGeometry(data.imoNumber, data.geometry);
      }
      onClose();
    } catch (e) {
      enqueueSnackbar('Error creating vessel', { variant: 'error' });
    }
  };

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="sm">
      <CreateVesselDialog onCancel={onClose} onSubmit={handleSubmit} />
    </Dialog>
  );
};
