import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { UserData } from './components';

export const UserProfile = () => {
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="h1">User Profile</Typography>
      </Grid>
      <Grid item xs={12} md={8} lg={6}>
        <UserData />
      </Grid>
    </Grid>
  );
};
