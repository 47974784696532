import React from 'react';
import { Notification, NotificationType } from './Notification';

interface IProps {
  isCard?: boolean;
  title?: string;
  message?: string;
}

export const NotificationForbidden = ({
  isCard = false,
  title = 'Unauthorised',
  message = "We are sorry, but you don't have access to this page.",
}: IProps) => {
  return (
    <Notification isCard={isCard} type={NotificationType.ERROR} title={title} message={message} />
  );
};
