import { format } from 'date-fns';
import { DATE_FORMAT, DATETIME_FORMAT, DATETIME_FORMAT_BACKEND, TIME_FORMAT } from '~/constants';

export const formatDateTime = (dateTime?: Date) =>
  dateTime ? format(dateTime, DATETIME_FORMAT) : '';

export const formatDate = (dateTime?: Date) => (dateTime ? format(dateTime, DATE_FORMAT) : '');

export const formatTime = (dateTime?: Date) => (dateTime ? format(dateTime, TIME_FORMAT) : '');

export const formatDateTimeBackend = (dateTime?: Date) =>
  dateTime ? format(dateTime, DATETIME_FORMAT_BACKEND) : '';
