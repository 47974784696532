import React from 'react';
import { Grid } from '@material-ui/core';

import { DetailField } from '@hpc/components';

import { VesselCall } from '~/types';
import { formatDateTime } from '~/utils';

import { vesselCallDetails } from './config';

interface IProps {
  vesselCall: VesselCall;
}

export const VesselCallDetailsView = ({ vesselCall }: IProps) => {
  return (
    <Grid container>
      {vesselCallDetails.map(({ label, property }) => (
        <Grid item key={label} xs={12}>
          <DetailField label={label} value={vesselCall?.[property] || ''} />
        </Grid>
      ))}

      <Grid item xs={12}>
        <DetailField
          label="Estimated time of arrival"
          value={vesselCall.eta ? formatDateTime(vesselCall.eta) : '-'}
        />
      </Grid>
      <Grid item xs={12}>
        <DetailField
          label="Estimated time of departure"
          value={vesselCall.etd ? formatDateTime(vesselCall.etd) : '-'}
        />
      </Grid>
      <Grid item xs={12}>
        <DetailField
          label="Actual time of departure"
          value={vesselCall.atd ? formatDateTime(vesselCall.atd) : '-'}
        />
      </Grid>

      <Grid item xs={12}>
        <DetailField label="Status" value={vesselCall.handlingStatus} />
      </Grid>
    </Grid>
  );
};
