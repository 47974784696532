import React, { useContext, useEffect, useState } from 'react';
import { Drawer, List, makeStyles } from '@material-ui/core';
import classNames from 'classnames';

import { DataTest } from '~/constants';
import { sidebarWidthClosed, sidebarWidthOpen, navbarHeight } from '../constants';
import { LayoutContext } from '../LayoutProvider';

import { SidebarButton } from './SidebarButton';
import { SidebarElement } from './SidebarElement';
import { SidebarElementStore } from './types';

const useStyles = makeStyles(({ transitions }) => ({
  drawer: {
    width: sidebarWidthOpen,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: sidebarWidthOpen,
    paddingTop: navbarHeight,
    transition: transitions.create('width', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    paddingTop: navbarHeight,
    width: sidebarWidthClosed,
    overflowX: 'hidden',
    transition: transitions.create('width', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
  },
  paper: {
    width: sidebarWidthOpen,
    paddingTop: navbarHeight,
  },
}));

export const Sidebar = () => {
  const classes = useStyles();
  const [elements, setElements] = useState<SidebarElementStore[]>([]);
  const { isSidebarOpen, sidebarElements } = useContext(LayoutContext);

  useEffect(() => {
    setElements(
      sidebarElements?.allIds
        .map((id) => sidebarElements.byId[id])
        .filter((el) => !el.parent)
        .sort((a, b) => (a.priority < b.priority ? -1 : 1)) || []
    );
  }, [sidebarElements]);

  return (
    <Drawer
      variant="permanent"
      open={isSidebarOpen}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpen,
        [classes.drawerClose]: !isSidebarOpen,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpen,
          [classes.drawerClose]: !isSidebarOpen,
        }),
      }}
      data-test={DataTest.SIDEBAR_MENU}
    >
      <List>
        {elements.map((element, index) => (
          <SidebarElement key={index} item={element} level={0} />
        ))}
        <SidebarButton />
      </List>
    </Drawer>
  );
};
