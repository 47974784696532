import React from 'react';
import { makeStyles } from '@material-ui/core';
import { barHeight, barHeightGrow, barWidth, delay, duration, gutter } from './constants';

const useStyles = makeStyles(({ palette }) => ({
  loader: {
    background: palette.primary.main,
    animation: `$load ${duration}ms infinite ease-in-out`,
    width: barWidth,
    height: barHeight,

    color: palette.primary.main,
    textIndent: '-9999em',
    position: 'relative',
    transform: 'translateZ(0)',
    animationDelay: `-${delay}ms`,

    '&::after': {
      background: palette.primary.main,
      animation: `$load ${duration}ms infinite ease-in-out`,
      width: barWidth,
      height: barHeight,

      position: 'absolute',
      top: 0,
      content: '""',

      left: barWidth + gutter,
    },
    '&::before': {
      background: palette.primary.main,
      animation: `$load ${duration}ms infinite ease-in-out`,
      width: barWidth,
      height: barHeight,

      position: 'absolute',
      top: 0,
      content: '""',

      left: -(barWidth + gutter),
      animationDelay: `-${delay * 2}ms`,
    },
  },
  '@keyframes load': {
    '0%, 80%, 100%': {
      boxShadow: '0 0',
      height: barHeight,
    },
    '40%': {
      boxShadow: `0 -${barHeightGrow}px`,
      height: barHeight + barHeightGrow,
    },
  },
}));

export const Spinner = () => {
  const classes = useStyles();
  return <div className={classes.loader}>Loading...</div>;
};
