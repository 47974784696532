import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Location } from 'history';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  AuthContext,
  ConfirmPasswordInput,
  ForgotPasswordInput,
  RedirectState,
} from '@hpc/components';

import { ConfirmPasswordCompleted, ConfirmPasswordForm, ForgotPasswordForm } from './components';
import { useStyles } from './styles';

export const ForgotPassword = () => {
  const location = useLocation() as Location<RedirectState>;
  const navigate = useNavigate();
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [completed, setCompleted] = useState(false);
  const [hostname, setHostname] = useState('');
  const [subdomain, setSubdomain] = useState('');
  const { confirmPassword, isAuthenticated, resetPassword } = useContext(AuthContext);

  useEffect(() => {
    const newHostname = window.location.hostname;
    setHostname(newHostname);
    const newSubdomain =
      newHostname !== 'localhost'
        ? newHostname.replace(/(\.?dev|\.?staging|\.?hpc)?.?tos-addons.com/, '')
        : '';
    setSubdomain(newSubdomain);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(location.state?.pathname || '/');
    }
  }, []);

  const handleForgotPassword = async (data: ForgotPasswordInput) => {
    const response = await resetPassword(data);
    setUsername(data.username);
    setSubdomain(data.subdomain);
    return response;
  };

  const handleSetNewPassword = async (data: ConfirmPasswordInput) => {
    await confirmPassword({
      ...data,
      username,
      subdomain,
    });
    setCompleted(true);
  };

  if (!hostname) {
    return null;
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" className={classes.root}>
      {completed ? (
        <ConfirmPasswordCompleted />
      ) : username ? (
        <ConfirmPasswordForm email={username} onSubmit={handleSetNewPassword} />
      ) : (
        <ForgotPasswordForm subdomain={subdomain} onSubmit={handleForgotPassword} />
      )}
    </Box>
  );
};
