import React, { useContext } from 'react';
import { useSnackbar } from 'notistack';

import { AuthContext, Editable, hasRole, NotificationError, UserRoles } from '@hpc/components';

import { Organisation as OrganisationType } from '~/types';
import { api, organisationValidationSchema } from '~/utils';

import { OrganisationEdit, OrganisationView } from './components';

interface IProps {
  organisation: OrganisationType;
  onUpdate: () => Promise<void>;
}

export const Organisation = ({ organisation, onUpdate }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { accessControl } = useContext(AuthContext);

  const updateOrganisation = async (data: OrganisationType) => {
    const updatedOrganisation = (({
      id,
      name,
      tomSubdomain,
      locationCode,
      websiteUrl,
      status,
      country,
      state,
      city,
      postalCode,
      street,
    }) => ({
      id,
      name,
      tomSubdomain,
      locationCode,
      websiteUrl,
      status,
      country,
      state,
      city,
      postalCode,
      street,
    }))(data);
    try {
      await api.put(
        `/organisations/${updatedOrganisation.id}`,
        JSON.stringify(updatedOrganisation)
      );
      await onUpdate();
      enqueueSnackbar('Organisation has been updated.', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Error updating organisation.', { variant: 'error' });
      throw new Error();
    }
  };

  if (!organisation) {
    return <NotificationError isCard title="Organisation not found" />;
  }

  return (
    <Editable
      title={organisation?.name}
      entity={organisation}
      renderEdit={(control, errors) => (
        <OrganisationEdit organisation={organisation} control={control} errors={errors} />
      )}
      renderNonEdit={<OrganisationView organisation={organisation} />}
      canEdit={hasRole(accessControl, UserRoles.ADMIN)}
      validator={organisationValidationSchema}
      onSubmit={updateOrganisation}
    />
  );
};
