import { HandlingStatus } from './Bay';
import { Vessel } from './Vessel';

export enum BaplieStatus {
  PRESENT = 'PRESENT',
  PARSING = 'PARSING',
  ABSENT = 'ABSENT',
  FAILED = 'FAILED',
}

export type VesselCall = {
  atd: Date | null;
  baplieErrorArrival: string;
  baplieErrorDeparture: string;
  baplieStatusArrival: BaplieStatus;
  baplieStatusDeparture: BaplieStatus;
  baysCount: number;
  callNumber: string;
  containersCount: number;
  eta: Date | null;
  etd: Date | null;
  handlingStatus: HandlingStatus;
  imoNumber: Vessel['imoNumber'];
  voyageNumber: string;
  isArchived: boolean;
};

export type VesselCallComplete = Vessel & VesselCall;
export type VesselCallApi = Omit<VesselCallComplete, 'eta' | 'etd' | 'atd'> & {
  eta: string;
  etd: string;
  atd: string;
};
export type VesselCallEditApi = Pick<VesselCall, 'voyageNumber'> & {
  eta: string;
  etd: string;
  atd: string;
};
