import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { NotificationNoData } from '@hpc/components';

import { Bay, Container, VesselCallComplete } from '~/types';
import { BaysTable } from './components';

interface IProps {
  vesselCall: VesselCallComplete;
  bays: Bay[];
  containers: Container[];
}

export const Bays = ({ vesselCall, bays }: IProps) => {
  if (!bays.length) {
    return <NotificationNoData isCard message="The bay list is empty" />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1">Bays</Typography>
        <Typography variant="h2">{`${vesselCall.vesselName} (#${vesselCall.callNumber})`}</Typography>
      </Grid>
      <Grid item xs={12}>
        <BaysTable vesselCall={vesselCall} bays={bays} />
      </Grid>
    </Grid>
  );
};
