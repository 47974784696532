import React from 'react';
import { makeStyles } from '@material-ui/core';
import zxcvbn from 'zxcvbn';

const RECOMMENDED_LENGTH = 16;

interface IStyleProps {
  score: number;
  length: number;
}

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '100%',
  },
  score: ({ score, length }: IStyleProps) => ({
    height: 4,
    backgroundColor:
      [
        palette.error.dark,
        palette.error.main,
        palette.warning.main,
        palette.success.light,
        palette.success.main,
      ][score] || palette.info.main,
    width: `${100 * (length / RECOMMENDED_LENGTH)}%`,
  }),
}));

interface IProps {
  password: string;
}

export const PasswordStrength = ({ password }: IProps) => {
  const { score } = zxcvbn(password);
  const classes = useStyles({ score, length: Math.min(password.length, RECOMMENDED_LENGTH) });

  return (
    <div className={classes.root}>
      <div className={classes.score} />
    </div>
  );
};
