import {
  Bay,
  BayApi,
  Container,
  ContainerApi,
  Vessel,
  VesselCall,
  VesselCallApi,
  VesselCallComplete,
  VesselCallEditApi,
  VesselCallEditInput,
} from '~/types';
import { formatDateTimeBackend } from './dateTime';

export const vesselFromComplete = ({
  callSign,
  imoNumber,
  mmsiNumber,
  operator,
  profileStatus,
  vesselName,
  geometryOriginator,
}: VesselCallComplete): Vessel => ({
  callSign,
  imoNumber,
  mmsiNumber,
  operator,
  profileStatus,
  vesselName,
  geometryOriginator,
});

export const vesselCallFromComplete = ({
  atd,
  baplieErrorArrival,
  baplieErrorDeparture,
  baplieStatusArrival,
  baplieStatusDeparture,
  baysCount,
  callNumber,
  containersCount,
  eta,
  etd,
  handlingStatus,
  imoNumber,
  isArchived,
  voyageNumber,
}: VesselCallComplete): VesselCall => ({
  atd,
  baplieErrorArrival,
  baplieErrorDeparture,
  baplieStatusArrival,
  baplieStatusDeparture,
  baysCount,
  callNumber,
  containersCount,
  eta,
  etd,
  handlingStatus,
  imoNumber,
  isArchived,
  voyageNumber,
});

export const vesselCallParser = (vesselCall: VesselCallApi): VesselCallComplete => ({
  ...vesselCall,
  eta: vesselCall.eta ? new Date(vesselCall.eta) : null,
  etd: vesselCall.etd ? new Date(vesselCall.etd) : null,
  atd: vesselCall.atd ? new Date(vesselCall.atd) : null,
});

export const vesselCallEditComposer = (vesselCall: VesselCallEditInput): VesselCallEditApi => ({
  voyageNumber: vesselCall.voyageNumber,
  eta: vesselCall.eta ? formatDateTimeBackend(vesselCall.eta) : '',
  etd: vesselCall.etd ? formatDateTimeBackend(vesselCall.etd) : '',
  atd: vesselCall.atd ? formatDateTimeBackend(vesselCall.atd) : '',
});

export const vesselCallsParser = (vesselCallApiList: VesselCallApi[]): VesselCallComplete[] =>
  vesselCallApiList?.map((vca) => vesselCallParser(vca));

export const baysParser = (bays: BayApi[], containers: ContainerApi[]): Bay[] => {
  const groupedByBay: Record<string, Bay> = containers.reduce((acc, container) => {
    const key = `${container.plannedPosition.bay}-${container.plannedPosition.deckHold}-${container.workingDirection}`;
    return {
      ...acc,
      [key]: acc[key]
        ? {
            ...acc[key],
            containerCount: acc[key].containerCount + 1,
          }
        : {
            bay: container.plannedPosition.bay,
            deckHold: container.plannedPosition.deckHold,
            containerCount: 1,
          },
    };
  }, {});
  return bays
    .sort((a, b) => {
      if (a?.bay === b?.bay) {
        if (a?.deckHold === b?.deckHold) {
          return a?.workingDirection < b?.workingDirection ? -1 : 1;
        }
        return a?.deckHold > b?.deckHold ? -1 : 1;
      }
      return a?.bay < b?.bay ? -1 : 1;
    })
    .map((bay) => ({
      ...bay,
      closingTime: bay.closingTime ? new Date(bay.closingTime) : null,
      containerCount:
        groupedByBay[`${bay.bay}-${bay.deckHold}-${bay.workingDirection}`]?.containerCount || 0,
    }));
};

// discrepancy - container: confirmed # != 1, confirmed # == 1 && planned != confirmed, has note
const checkDiscrepancy = (container: ContainerApi): boolean =>
  !container.isROB &&
  (!!container.note ||
    container.confirmedPositions.length !== 1 ||
    (container.confirmedPositions.length === 1 &&
      (container.confirmedPositions[0].bay !== container.plannedPosition.bay ||
        container.confirmedPositions[0].row !== container.plannedPosition.row ||
        container.confirmedPositions[0].tier !== container.plannedPosition.tier ||
        container.confirmedPositions[0].deckHold !== container.plannedPosition.deckHold)));

export const containersParser = (containers: ContainerApi[]): Container[] => {
  return containers
    .sort((a, b) => {
      if (a.plannedPosition?.bay === b.plannedPosition?.bay) {
        if (a.plannedPosition?.row === b.plannedPosition?.row) {
          return a.plannedPosition?.tier < b.plannedPosition?.tier ? -1 : 1;
        }
        return a.plannedPosition?.row < b.plannedPosition?.row ? -1 : 1;
      }
      return a.plannedPosition?.bay < b.plannedPosition?.bay ? -1 : 1;
    })
    .map((container) => {
      return {
        ...container,
        confirmedPositions: container.confirmedPositions.reduce(
          (acc, confirmedPosition) => ({
            bay: acc.bay.concat(confirmedPosition.bay),
            row: acc.row.concat(confirmedPosition.row),
            tier: acc.tier.concat(confirmedPosition.tier),
            deckHold: acc.deckHold.concat(confirmedPosition.deckHold),
          }),
          {
            bay: [],
            row: [],
            tier: [],
            deckHold: [],
          }
        ),
        discrepancy: checkDiscrepancy(container),
      };
    });
};
