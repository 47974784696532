const parse = (file: string | ArrayBuffer | null) => {
  if (!file || typeof file !== 'string') {
    return;
  }

  const vesselGeometry = JSON.parse(file);

  return {
    imoNumber: vesselGeometry.imoNumber,
    vesselName: vesselGeometry.vesselName,
    callSign: vesselGeometry.callSign,
    mmsiNumber: vesselGeometry.mmsiNumber,
  };
};

export const vesselGeometry = { parse };
