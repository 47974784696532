import { Organisation } from '~/types';

type OrganisationField = {
  key: keyof Organisation;
  label: string;
  optional?: boolean;
};

export const fields: OrganisationField[] = [
  { key: 'name', label: 'Organisation name' },
  { key: 'tomSubdomain', label: 'Subdomain' },
  { key: 'websiteUrl', label: 'Website URL' },
  { key: 'country', label: 'Country' },
  { key: 'state', label: 'State', optional: true },
  { key: 'city', label: 'City' },
  { key: 'postalCode', label: 'Postal code' },
  { key: 'street', label: 'Street' },
];
