import { makeStyles } from '@material-ui/core';
import { Estimation } from './types';

interface IStyleProps {
  estimation: Estimation;
}

export const useStylesHorizontal = makeStyles(({ palette, spacing }) => ({
  et: {
    padding: 0,
  },
  etHead: ({ estimation }: IStyleProps) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: spacing(14) + 1,
    padding: spacing(2),
    color: 'white',
    background:
      estimation === 'eta'
        ? `linear-gradient(60deg, #65ca6a, #2b862f)`
        : estimation === 'etd'
        ? `linear-gradient(60deg, #ffa726, #fb8c00)`
        : `linear-gradient(60deg, #ef5350, #c11612)`,
  }),
  etTitle: {
    textAlign: 'center',
    fontSize: '1.4rem',
    letterSpacing: 5,
  },
  etTimes: {
    width: '100%',
  },
  etTime: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    '& > .MuiSvgIcon-root': {
      marginRight: spacing(1),
    },
  },
  etEstimated: {
    padding: spacing(1.5),
  },
  etTimer: {
    padding: spacing(1.5),
  },
  etTimerEnd: ({ estimation }: IStyleProps) => ({
    color: estimation === 'eta' ? palette.success.main : palette.warning.main,
    textAlign: 'center',
    textTransform: 'uppercase',
    width: '100%',
  }),
}));

export const useStylesVertical = makeStyles(({ palette, spacing }) => ({
  et: {
    padding: 0,
  },
  etHead: ({ estimation }: IStyleProps) => ({
    color: 'white',
    background:
      estimation === 'eta'
        ? `linear-gradient(60deg, #65ca6a, #2b862f)`
        : estimation === 'etd'
        ? `linear-gradient(60deg, #ffa726, #dc7b01)`
        : `linear-gradient(60deg, #ef5350, #c11612)`,
    padding: spacing(1),
  }),
  etTitle: {
    textAlign: 'center',
    fontSize: '1.4rem',
    letterSpacing: 5,
  },
  divider: {
    width: '100%',
  },
  etTimes: {
    width: '100%',
  },
  etTime: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    padding: spacing(1.25),
    '& > .MuiSvgIcon-root': {
      marginRight: spacing(1),
    },
  },
  etTimerEnd: ({ estimation }: IStyleProps) => ({
    color: estimation === 'eta' ? palette.success.main : palette.warning.main,
    textAlign: 'center',
    textTransform: 'uppercase',
    width: '100%',
    padding: spacing(1.25),
  }),
}));
