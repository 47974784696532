import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import {
  ApiResponse,
  Application,
  AppStatusAdmin,
  AuthContext,
  Loading,
  NotificationNoData,
  useToggle,
} from '@hpc/components';

import { api } from '~/utils';

import { AppCard } from './components';
import { appConfig } from './config';

export const Applications = () => {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { accessControl, user } = useContext(AuthContext);
  const [loading, setLoading] = useToggle(true);
  const [applications, setApplications] = useState<Application[]>([]);

  const organisationId = params?.organisationId || user.organisationId;

  const fetchApps = async () => {
    try {
      const response = await api.get<ApiResponse<Application[]>>(
        `/organisations/${organisationId}/apps`
      );
      const appsObj = response.data.payload?.reduce(
        (acc, app) => ({
          ...acc,
          [app.name]: app,
        }),
        {}
      );
      const apps =
        Object.values(appConfig)
          .map((app) => ({
            ...app,
            status: appsObj[app.name]?.status || app.status,
          }))
          .filter((app) => accessControl.isSuperAdmin || !AppStatusAdmin.includes(app.status)) ||
        [];
      setApplications(apps);
    } catch (e) {
      setApplications(
        Object.values(appConfig).filter(
          (app) => accessControl.isSuperAdmin || !AppStatusAdmin.includes(app.status)
        )
      );
      enqueueSnackbar('Error fetching applications.', { variant: 'error' });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (organisationId) {
      fetchApps();
    }
  }, [organisationId]);

  if (loading) {
    return <Loading />;
  }

  if (!applications.length) {
    return <NotificationNoData isCard message="No applications have been found." />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1">Applications</Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container direction="column">
          {applications.map((app) => (
            <Grid item key={app.name}>
              <AppCard app={app} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
