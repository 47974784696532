import React, { useEffect, useState } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Vessel } from '~/types';

interface IProps {
  label: string;
  vessels: Record<Vessel['imoNumber'], Vessel>;
  vessel: string;
  value: string;
  onChange: (imoNumber: Vessel['imoNumber']) => void;
  error?: TextFieldProps['error'];
  helperText?: TextFieldProps['helperText'];
}

export const SelectVessel = ({ label, vessels, vessel, onChange, error, helperText }: IProps) => {
  const [options, setOptions] = useState<string[]>([]);
  const [selection, setSelection] = useState<string>('');

  useEffect(() => {
    setOptions(['new', ...Object.keys(vessels)]);
  }, [vessels]);

  useEffect(() => {
    if (vessel && vessel !== selection) {
      onVesselChange('', vessel);
    }
  }, [vessel]);

  const onVesselChange = (_, imoNumber: string | null) => {
    onChange(imoNumber);

    if (imoNumber) {
      setSelection(
        imoNumber === 'new'
          ? 'Add a new vessel'
          : `${imoNumber} (${vessels[imoNumber]?.vesselName})`
      );
    } else {
      setSelection('');
    }
  };

  return (
    <Autocomplete
      value={selection}
      options={options}
      renderOption={(imoNumber) =>
        imoNumber === 'new'
          ? 'Add a new vessel'
          : `${imoNumber} (${vessels[imoNumber]?.vesselName})`
      }
      filterOptions={(options, { inputValue }) =>
        options.filter(
          (imoNumber) =>
            imoNumber === 'new' ||
            imoNumber.includes(inputValue.toLowerCase()) ||
            vessels[imoNumber]?.vesselName?.toLowerCase().includes(inputValue?.toLowerCase())
        )
      }
      fullWidth
      freeSolo
      onChange={onVesselChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={helperText}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
          }}
        />
      )}
    />
  );
};
