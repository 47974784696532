import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Controller, Control, FieldErrors } from 'react-hook-form';

import { Card, CardContent, CardHeader, DetailField } from '@hpc/components';

import { Vessel } from '~/types';
import { vesselConfig } from './config';

interface IProps {
  vessel: Vessel;
  control: Control<Vessel>;
  errors: FieldErrors;
}

const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};

export const VesselEdit = ({ control, errors, vessel }: IProps) => {
  return (
    <Card>
      <CardHeader title="Vessel details" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <DetailField label="IMO number" value={vessel.imoNumber} />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="vesselName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Vessel Name"
                  error={!!errors?.vesselName}
                  helperText={errors?.vesselName?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="callSign"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Call sign"
                  error={!!errors?.callSign}
                  helperText={errors?.callSign?.message}
                  fullWidth
                  onInput={toInputUppercase}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="mmsiNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="MMSI number"
                  error={!!errors?.mmsiNumber}
                  helperText={errors?.mmsiNumber?.message}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>

          {/* {vesselConfig.map(({ label, property }) => (
            <Grid item key={label} xs={12}>
              <Controller
                name={property}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={label}
                    error={!!errors?.[property]}
                    helperText={errors?.[property]?.message}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
          ))} */}
          <Grid item xs={12}>
            <DetailField label="Geometry origin" value={vessel.geometryOriginator} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
