import React, { useEffect, useState } from 'react';
import { Dialog } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { ApiResponse } from '@hpc/components';

import { AddUserInput, User } from '~/types';
import { api } from '~/utils';

import { AddUserDialog } from './AddUserDialog';

interface IProps {
  organisationId: string;
  groupName: string;
  groupMembers: User[];
  onClose: () => void;
  onSuccess: () => Promise<void>;
}

export const AddUser = ({
  organisationId,
  groupName,
  groupMembers,
  onClose,
  onSuccess,
}: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState<User[]>([]);

  const fetchUsers = async () => {
    const usersInGroup = groupMembers.map((user) => user.sub);
    try {
      const response = await api.get<ApiResponse<User[]>>(`/organisations/${organisationId}/users`);
      const unassignedUsers =
        response?.data?.payload?.filter((user) => !usersInGroup.includes(user.sub)) || [];
      setUsers(unassignedUsers);
    } catch (e) {
      enqueueSnackbar('Error fetching users.', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (organisationId) {
      fetchUsers();
    }
  }, [organisationId]);

  const handleSubmit = async (data: AddUserInput) => {
    try {
      await api.post(`/organisations/${organisationId}/groups/${groupName}/users/${data.user.sub}`);
      await onSuccess();
      enqueueSnackbar('User has been added.', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Error adding user.', { variant: 'error' });
    }
  };

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="sm">
      <AddUserDialog users={users} onCancel={onClose} onSubmit={handleSubmit} />
    </Dialog>
  );
};
