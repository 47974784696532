import { AccountBalanceOutlined, HomeOutlined, ViewListOutlined } from '@material-ui/icons';

import { SidebarElement } from '@hpc/components';

export const getSidebarElements = (pathname?: string): SidebarElement[] => [
  {
    id: 'home',
    label: 'Home',
    priority: 10,
    url: '/',
    exact: true,
    icon: HomeOutlined,
  },
  {
    id: 'organisation',
    priority: 20,
    label: 'My Organisation',
    url: '/organisation/',
    action: (item, toggleOpen, navigate) => {
      toggleOpen(true);
      navigate(item.url);
    },
    icon: AccountBalanceOutlined,
    open: pathname === '/organisation' || pathname?.startsWith('/organisation/'),
    children: [
      {
        id: 'organisation.details',
        label: 'Details',
        url: '/organisation/',
        exact: true,
      },
      {
        id: 'organisation.users',
        label: 'Users',
        url: '/organisation/users',
        access: (ac) => ac.isAdmin,
      },
      {
        id: 'organisation.groups',
        label: 'Groups',
        url: '/organisation/groups',
        access: (ac) => ac.isAdmin,
      },
      {
        id: 'organisation.apps',
        label: 'Applications',
        url: '/organisation/apps',
        access: (ac) => ac.isAdmin,
      },
    ],
  },
  {
    id: 'superAdmin',
    label: 'Super Admin',
    priority: 20,
    isSection: true,
    visibleCollapsed: true,
    access: (ac) => ac.isSuperAdmin,
    children: [
      {
        id: 'admin.organisations',
        priority: 10,
        label: 'Organisations',
        url: '/organisations',
        icon: ViewListOutlined,
      },
    ],
  },
];
