import { VesselCall } from '~/types';

type VesselCallField = {
  property: keyof VesselCall;
  label: string;
  locked?: boolean;
};

export const vesselCallDetails: VesselCallField[] = [
  { property: 'callNumber', label: 'Call number', locked: true },
  { property: 'voyageNumber', label: 'Voyage number' },
];
