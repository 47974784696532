import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import { Button } from '@hpc/components';

import { FileUpload } from '~/components';
import { VesselInput } from '~/types';
import { vesselGeometry, vesselValidationSchema } from '~/utils';

interface IProps {
  onSubmit: (values: VesselInput) => Promise<void>;
  onCancel: () => void;
}

// remove spin button from umber input
/* const useStyles = makeStyles({
  textfieldNoSpinArrowsClass: {
    '& .MuiInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
}); */

const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};

export const CreateVesselDialog = ({ onCancel, onSubmit }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [geometry, setGeometry] = useState<File | undefined>();
  const { control, formState, handleSubmit, setValue, trigger } = useForm<VesselInput>({
    resolver: yupResolver(vesselValidationSchema),
    defaultValues: {
      imoNumber: '',
      vesselName: '',
      callSign: '',
      mmsiNumber: '',
      geometry: null,
    },
  });

  const handleGeometryUpload = (file: File) => {
    if (!file) {
      return;
    }
    const reader = new FileReader();

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');

    reader.onload = () => {
      setGeometry(file);

      if (file.name.split('.').pop() === 'json') {
        try {
          const parsedGeometry = vesselGeometry.parse(reader.result);
          setValue('imoNumber', parsedGeometry.imoNumber);
          setValue('vesselName', parsedGeometry.vesselName);
          setValue('callSign', parsedGeometry.callSign);
          setValue('mmsiNumber', parsedGeometry.mmsiNumber);
        } catch (e) {
          enqueueSnackbar('Error parsing vessel geometry.', { variant: 'error' });
        }
      }
    };

    reader.readAsText(file);
  };

  const createVessel = async (data: VesselInput) => {
    await onSubmit({
      ...data,
      geometry,
    });
  };

  return (
    <form onSubmit={handleSubmit(createVessel)}>
      <DialogTitle>Create Vessel</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <FileUpload
              label="Upload vessel geometry"
              accept={['.json', '.staf', '.staf.txt']}
              onChange={handleGeometryUpload}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="imoNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="IMO Number"
                  onBlur={() => trigger('imoNumber')}
                  error={!!formState.errors?.imoNumber}
                  helperText={formState.errors?.imoNumber?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="vesselName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Vessel Name"
                  onBlur={() => trigger('vesselName')}
                  error={!!formState.errors?.vesselName}
                  helperText={formState.errors?.vesselName?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="callSign"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Call sign"
                  error={!!formState.errors?.callSign}
                  helperText={formState.errors?.callSign?.message}
                  fullWidth
                  onInput={toInputUppercase}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">optional</InputAdornment>,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="mmsiNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="MMSI number"
                  error={!!formState.errors?.mmsiNumber}
                  helperText={formState.errors?.mmsiNumber?.message}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">optional</InputAdornment>,
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          disabled={formState.isSubmitting}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button variant="contained" color="primary" type="submit" loading={formState.isSubmitting}>
          Create
        </Button>
      </DialogActions>
    </form>
  );
};
