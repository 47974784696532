import React, { useContext } from 'react';

import { AccessLevels, Apps, UserRoles } from '~/types';
import { AuthContext, hasAppAccess, hasRole } from '~/utils';

interface IProps {
  children: React.ReactNode;
  userRole?: UserRoles;
  app?: Apps;
  accessLevel?: AccessLevels;
  renderNoAccess?: React.ReactNode;
}

export const AccessControl = ({
  children,
  userRole,
  app,
  accessLevel = AccessLevels.READ,
  renderNoAccess = null,
}: IProps) => {
  const { accessControl } = useContext(AuthContext);

  if (
    (userRole && !hasRole(accessControl, userRole)) ||
    (app && !hasAppAccess(accessControl, app, accessLevel))
  ) {
    return <>{renderNoAccess}</>;
  }

  return <>{children}</>;
};
