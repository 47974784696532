export * from './dataTest';
export * from './forms';

import { AccessLevels, AppStatus, AppTypes, Products, UserRoles } from '~/types';

export const LOCAL_STORAGE_ACCESS_TOKEN_KEY = 'access_token';
export const LOCAL_STORAGE_ID_TOKEN_KEY = 'id_token';
export const LOCAL_STORAGE_REFRESH_TOKEN_KEY = 'refresh_token';
export const LOCAL_STORAGE_SUBDOMAIN_KEY = 'subdomain';

export const API_URL = '/api/portal/v1';

export const PERMISSION_REGEX = /(.*)\.(.*)\.(ro|rw|admin)$/i;

export const ProductLabels: Record<Products, string> = {
  [Products.HATCH_CLERK]: 'Hatch Clerk',
};

export const AppTypeLabels: Record<AppTypes, string> = {
  [AppTypes.WEBAPP]: 'Web App',
  [AppTypes.MOBILE]: 'Mobile',
};

export const AccessLevelLabels: Partial<Record<AccessLevels, string>> = {
  [AccessLevels.READ]: 'Read',
  [AccessLevels.WRITE]: 'Write',
};

export const UserRoleLabels: Partial<Record<UserRoles, string>> = {
  [UserRoles.USER]: 'User',
  [UserRoles.ADMIN]: 'Admin',
};

export const AppStatusLabel: Partial<Record<AppStatus, string>> = {
  [AppStatus.UNAVAILABLE]: 'Unavailable',
  [AppStatus.UPDATING]: 'Updating',
  [AppStatus.ENABLED]: 'Enabled',
  [AppStatus.DISABLED]: 'Disabled',
  [AppStatus.WARNING]: 'Warning',
  [AppStatus.ERROR]: 'Error',
};

export const AppStatusAdmin: AppStatus[] = [AppStatus.AVAILABLE, AppStatus.UNAVAILABLE];
export const AppStatusEnable: AppStatus[] = [
  AppStatus.AVAILABLE,
  AppStatus.DISABLED,
  AppStatus.ERROR,
];
export const AppStatusDisable: AppStatus[] = [AppStatus.ENABLED, AppStatus.WARNING];
export const AppStatusRefresh: AppStatus[] = [AppStatus.WARNING];
