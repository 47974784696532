import { User } from '~/types';

type UserField = {
  key: keyof User;
  label: string;
  optional?: boolean;
  placeholder?: string;
};

export const fields: UserField[] = [
  { key: 'firstName', label: 'First name' },
  { key: 'name', label: 'Last Name' },
  { key: 'emailAddress', label: 'Email' },
  { key: 'jobTitle', label: 'Job', optional: true },
  { key: 'phone', label: 'Phone', optional: true, placeholder: 'e.g. +12 345-67890' },
  { key: 'department', label: 'Department', optional: true },
];
