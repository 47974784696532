import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import {
  ApiResponse,
  AppStatusAdmin,
  AuthContext,
  Loading,
  Notification,
  NotificationType,
  useToggle,
} from '@hpc/components';

import { api } from '~/utils';

import { AppTile } from './AppTile';
import { appConfig } from './config';
import { App, AppApi } from './types';

export const Apps = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { accessControl, user } = useContext(AuthContext);
  const [loading, setLoading] = useToggle(true);
  const [applications, setApplications] = useState<App[]>([]);

  const fetchApps = async () => {
    try {
      const response = await api.get<ApiResponse<AppApi[]>>(
        `/organisations/${user.organisationId}/apps`
      );

      const appsObj = response.data.payload?.reduce(
        (acc, app) => ({
          ...acc,
          [app.name]: app,
        }),
        {}
      );
      const apps =
        Object.values(appConfig)
          .map((app) => ({
            ...app,
            status: appsObj[app.name]?.status || app.status,
          }))
          .filter((app) => accessControl.isSuperAdmin || !AppStatusAdmin.includes(app.status)) ||
        [];

      setApplications(apps);
    } catch (e) {
      enqueueSnackbar('Error fetching applications.', { variant: 'error' });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (user?.organisationId) {
      fetchApps();
    }
  }, [user]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h2">Apps</Typography>
      </Grid>
      <Grid item xs={12}>
        {applications.length > 0 ? (
          applications.map((app) => <AppTile key={app.name} app={app} />)
        ) : (
          <Notification type={NotificationType.WARNING} message="You have no available apps" />
        )}
      </Grid>
    </Grid>
  );
};
