import React from 'react';
import { makeStyles } from '@material-ui/core';

import { Loading, NotificationNoData, Paper, Table } from '@hpc/components';
import { DataTest } from '~/constants';
import { Organisation } from '~/types';
import { organisationsColumns } from './organisationsColumns';

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
  },
}));

interface IProps {
  loading: boolean;
  organisations: Organisation[];
}

export const OrganisationsTable = ({ loading, organisations }: IProps) => {
  const classes = useStyles();
  const columns = organisationsColumns(organisations);

  if (loading) {
    return <Loading />;
  }

  if (!organisations.length) {
    return <NotificationNoData isCard message="The organisation list is empty" />;
  }

  return (
    <Paper className={classes.root} data-test={DataTest.ORGANISATIONS_TABLE}>
      <Table data={organisations} columns={columns} />
    </Paper>
  );
};
