import React, { useContext } from 'react';
import { makeStyles, Table, TableBody, TableRow, TableCell } from '@material-ui/core';

import { AuthContext } from '@hpc/components';

const useStyles = makeStyles({
  label: {
    fontWeight: 600,
  },
  value: {
    textAlign: 'right',
  },
});

export const UserData = () => {
  const { user } = useContext(AuthContext);
  const classes = useStyles();

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell className={classes.label}>Sub</TableCell>
          <TableCell className={classes.value}>{user?.sub}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.label}>Email</TableCell>
          <TableCell className={classes.value}>{user?.emailAddress}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
