import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { GsVesselCallMenu } from '~/assets';

export const VesselCallClose = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={6} md={8} lg={10}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1">
              The sub-menus bays and containers of a port call allow you to evaluate the work
              executed. Both lists are filterable and exportable in CSV format.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Every Vessel Call has a status. Once the vessel departed, you can close your vessel
              call via the three dots menu at the top-right corner. Vessel calls no longer needed
              for operations can be archived via the same menu.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6} md={4} lg={2}>
        <img src={GsVesselCallMenu} alt="Vessel call menu" />
      </Grid>
    </Grid>
  );
};
