import React from 'react';
import { IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import { TableColumnDef } from '@hpc/components';

import { User } from '~/types';

export const groupMembersColumns = (
  organisationId: string,
  users: User[],
  canEdit: boolean,
  removeUser: (user: User) => void,
  classes: Record<'actions', string>
): TableColumnDef[] => {
  const renderName = (index) => (
    <Link to={`/organisations/${organisationId}/users/${users[index].sub}`}>
      {users[index].firstName} {users[index].name}
    </Link>
  );
  const renderActions = (index) => (
    <IconButton onClick={() => removeUser(users[index])}>
      <DeleteIcon />
    </IconButton>
  );

  return [
    {
      name: 'name',
      label: 'Name',
      options: {
        render: renderName,
      },
    },
    { name: 'emailAddress', label: 'Email' },
    { name: 'phone', label: 'Phone' },
    { name: 'department', label: 'Department' },
    ...(canEdit
      ? [
          {
            name: '',
            options: {
              setCellProps: () => ({
                className: classes.actions,
                padding: 'checkbox',
              }),
              render: renderActions,
            },
          },
        ]
      : []),
  ];
};
