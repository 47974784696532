import React from 'react';
import { Dialog } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { api } from '~/utils';
import { VesselGeometryUploadDialog } from './VesselGeometryUploadDialog';

interface IProps {
  imoNumber: string;
  onUpload: () => Promise<void>;
  onClose: () => void;
}

export const VesselGeometryUpload = ({ imoNumber, onUpload, onClose }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('vesselGeometry', file);
      await api.post(`/vessels/${imoNumber}/vessel-geometry`, formData);
      await onUpload();
      enqueueSnackbar('Vessel geometry has been been uploaded.', { variant: 'success' });
      onClose();
    } catch (e) {
      enqueueSnackbar('Error uploading vessel geometry.', { variant: 'error' });
    }
  };

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="md">
      <VesselGeometryUploadDialog
        imoNumber={imoNumber}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};
