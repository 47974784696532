import { object, string } from 'yup';
import {
  ERROR_FIELD_GROUP_NAME,
  ERROR_FIELD_GROUP_NAME_RESERVED,
  ERROR_FIELD_REQUIRED,
} from '@hpc/components';
import { permissionGroupsRegExp } from '~/constants';

// const awsGroupNameRegExp = /^[\p{L}\p{M}\p{S}\p{N}\p{P}]+$/u; // AWS
const awsGroupNameRegExp = /^[\p{L}\p{M}\p{N}]+$/u;

export const groupValidationSchema = object({
  name: string()
    .required(ERROR_FIELD_REQUIRED)
    .matches(awsGroupNameRegExp, ERROR_FIELD_GROUP_NAME)
    .matches(permissionGroupsRegExp, ERROR_FIELD_GROUP_NAME_RESERVED),
});
