import { Vessel } from '~/types';

type VesselField = {
  property: keyof Vessel;
  label: string;
  locked?: boolean;
};

export const vesselConfig: VesselField[] = [
  { property: 'vesselName', label: 'Vessel name' },
  { property: 'callSign', label: 'Call sign' },
  { property: 'mmsiNumber', label: 'MMSI number' },
];
