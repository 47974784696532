import React from 'react';
import { Dialog } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { ApiResponse } from '@hpc/components';

import { User, UserInput } from '~/types';
import { api } from '~/utils';

import { CreateUserDialog } from './CreateUserDialog';

interface IProps {
  organisationId: string;
  onClose: () => void;
}

export const CreateUser = ({ organisationId, onClose }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (data: UserInput) => {
    try {
      await api.post<ApiResponse<User>>(`/organisations/${organisationId}/users`, {
        ...data,
        phone: data.phone?.replaceAll(/[ -]/g, '') ?? '',
      });
      enqueueSnackbar('User has been created.', { variant: 'success' });
      onClose();
    } catch (error) {
      enqueueSnackbar('Error creating user.', { variant: 'error' });
      throw error;
    }
  };

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="sm">
      <CreateUserDialog onCancel={onClose} onSubmit={handleSubmit} />
    </Dialog>
  );
};
