import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import { GsGroups, GsUserRoles } from '~/assets';

export const UserPermissions = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="body1">
          As an administrator you can manage your user&apos;s rights individually or by using user
          groups.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8} lg={6}>
            <img src={GsUserRoles} alt="User roles" />
          </Grid>
          <Grid item xs={12} md={4} lg={6}>
            <Typography variant="body1">
              To manage a user&apos;s access rights individually, open the respective user by
              selecting them from the user list and scrolling to the roles and permissions sections.
              Activate roles and permissions as required for the individual user.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Especially when you intend to provide several users with specific rights, it is
                  advisable to use groups.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  The groups section is available in the menu on your left-hand side and provides a
                  group for each role and permission available.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <img src={GsGroups} alt="Groups" />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1">
          You can open the group for which you intend to add a user by clicking on it. Upon
          selection, all current members of the group are displayed. You can add a new user to the
          group by selecting the corresponding button.
        </Typography>
      </Grid>
    </Grid>
  );
};
