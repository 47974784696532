import React, { createContext, Dispatch, useEffect, useReducer, useState } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';

import { useToggle } from '~/hooks';
import { Action, BaseEntity, Reducer, State } from '~/types';
import { filterUnique } from '~/utils';

import { SidebarElement, SidebarElementStore } from './Sidebar';

export type LayoutContextType = {
  isSidebarOpen: boolean;
  toggleSidebar: (on?: unknown) => void;
  sidebarElements: State<SidebarElementStore>;
  setSidebarElements: Dispatch<Action<SidebarElement>>;
  rootUrl: string;
  setRootUrl: (url: string) => void;
};

export const LayoutContext = createContext<LayoutContextType>({} as LayoutContextType);
LayoutContext.displayName = 'LayoutContext';

export const flatten = <T extends SidebarElement>(
  values: T[],
  parent?: T['id']
): SidebarElementStore[] => {
  const flattened = values.reduce(
    (acc, el) =>
      [
        ...acc,
        {
          ...el,
          ...(parent && { parent }),
          children: el.children?.length > 0 ? el.children.map((child) => child.id) : [],
        },
      ].concat(el.children ? flatten(el.children, el.id) : []),
    []
  );

  return flattened;
};

export const normalize = <T extends SidebarElementStore>(values: T[]) => {
  const normalized = values.reduce(
    (acc, el) => ({
      allIds: [...acc.allIds, el.id],
      byId: {
        ...acc.byId,
        [el.id]: el,
      },
    }),
    {
      allIds: [],
      byId: {},
    }
  );

  return normalized;
};

export const getElements = (
  state: State<SidebarElementStore>,
  ids: BaseEntity['id'][]
): SidebarElementStore[] =>
  ids
    .map((id) => state.byId[id])
    .reduce(
      (acc, el) => [...acc, el, ...(el?.children ? getElements(state, el.children) : [])],
      []
    );

export const add = (state: State<SidebarElementStore>, values: SidebarElementStore[]) => {
  const normalized = normalize(values);
  return {
    allIds: [...state.allIds, ...normalized.allIds].filter(filterUnique),
    byId: { ...state.byId, ...normalized.byId },
  };
};

export const remove = (state: State<SidebarElementStore>, values: BaseEntity['id'][]) => {
  const elementsToRemove = getElements(state, values).map((el) => el?.id);
  return {
    allIds: state.allIds.filter((id) => !elementsToRemove.includes(id)),
    byId: Object.keys(state.byId).reduce(
      (acc, id) => ({
        ...acc,
        ...(!elementsToRemove.includes(id) && {
          [id]: {
            ...state.byId[id],
            children: state.byId[id].children.filter((el) => !elementsToRemove.includes(el)),
          },
        }),
      }),
      {}
    ),
  };
};

export const set = (state: State<SidebarElementStore>, values: SidebarElementStore[]) => {
  const existingChildren = getElements(
    state,
    values.flatMap((el) => (el.children ? state.byId[el.id]?.children || [] : []))
  );
  const cleared = remove(
    state,
    existingChildren.map((el) => el.id)
  );
  return add(cleared, values);
};

export const update = (state: State<SidebarElementStore>, values: SidebarElement[]) => {
  const existingChildren = getElements(
    state,
    values.flatMap((el) => (el.children ? state.byId[el.id]?.children || [] : []))
  );
  const cleared = remove(
    state,
    existingChildren.map((el) => el.id)
  );
  const flattened = flatten(values);
  const updatedValues = flattened.map((el) => ({
    ...state.byId[el.id],
    ...el,
  }));
  return add(cleared, updatedValues);
};

export const reducer: Reducer<State<SidebarElementStore>, Action<SidebarElement>> = (
  state,
  action
) => {
  const values = [].concat(action?.value || []);
  const flattened = flatten(values);

  switch (action.type) {
    case 'reset':
      return {
        allIds: [],
        byId: {},
      };
    case 'add':
      return add(state, flattened);
    case 'remove':
      return remove(state, values);
    case 'set':
      return set(state, flattened);
    case 'update':
      return update(state, values);
    default:
      throw new Error('Unknown operation.');
  }
};

interface IProps {
  children: React.ReactNode;
}

export const LayoutProvider = ({ children }: IProps) => {
  const [rootUrl, setRootUrl] = useState('');
  const { breakpoints } = useTheme();
  const matches = useMediaQuery(breakpoints.up('md'));
  const [isSidebarOpen, toggleSidebar] = useToggle(matches);
  const [sidebarElements, setSidebarElements] = useReducer(reducer, {
    allIds: [],
    byId: {},
  });

  useEffect(() => {
    toggleSidebar(matches);
  }, [matches]);

  return (
    <LayoutContext.Provider
      value={{
        isSidebarOpen,
        toggleSidebar,
        sidebarElements,
        setSidebarElements,
        rootUrl,
        setRootUrl,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
