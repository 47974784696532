import { useState } from 'react';
import { differenceInDays, formatDuration, intervalToDuration, isFuture } from 'date-fns';

import { useInterval } from './useInterval';

export const calculateDaysLeft = (start: Date) =>
  differenceInDays(start.setMilliseconds(0), new Date().setMilliseconds(0));

export const calculateTimeLeft = (start: Date) =>
  intervalToDuration({ start: start.setMilliseconds(0), end: new Date().setMilliseconds(0) });

export const padZero = (n: number, pad = '00') =>
  ('' + n).length >= pad.length ? '' + n : (pad + n).slice(-pad.length);

export const getDuration = (date: Date) => {
  if (isFuture(date)) {
    const days = calculateDaysLeft(date);
    const duration = calculateTimeLeft(date);
    const time = [
      padZero(duration.hours),
      padZero(duration.minutes),
      padZero(duration.seconds),
    ].join(':');

    return `${formatDuration({ days }, { zero: true })} ${time}`;
  }

  return formatDuration({ days: 0 }, { zero: true });
};

export const useCountdown = (date: Date | null) => {
  const [timeLeft, setTimeLeft] = useState(getDuration(date));

  useInterval(() => {
    setTimeLeft(getDuration(date));
  }, 1000);

  return timeLeft;
};
