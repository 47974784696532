import { Organisation } from '@hpc/components';

export const getSidebarElement = (organisation: Organisation) => ({
  id: 'admin.organisations',
  parent: 'superAdmin',
  url: '/organisations',
  open: true,
  children: [
    {
      id: `organisations-${organisation.tomSubdomain}`,
      label: organisation.name,
      isSection: true,
      children: [
        {
          id: 'organisations.details',
          label: 'Details',
          url: `/organisations/${organisation.id}`,
          exact: true,
        },
        {
          id: 'organisations.users',
          label: 'Users',
          url: `/organisations/${organisation.id}/users`,
        },
        {
          id: 'organisations.groups',
          label: 'Groups',
          url: `/organisations/${organisation.id}/groups`,
        },
        {
          id: 'organisations.apps',
          label: 'Applications',
          url: `/organisations/${organisation.id}/apps`,
        },
      ],
    },
  ],
});

export const getEmptySidebarElement = () => ({
  id: 'admin.organisations',
  parent: 'superAdmin',
  url: '/organisations',
  open: false,
  children: [],
});
