import { Organisation } from '~/types';

type OrganisationField = {
  property: keyof Organisation;
  label: string;
  optional?: boolean;
};

export const organizationDetails: OrganisationField[] = [
  { property: 'name', label: 'Name' },
  { property: 'locationCode', label: 'Location code', optional: true },
];

export const organizationAddress: OrganisationField[] = [
  { property: 'country', label: 'Country' },
  { property: 'state', label: 'State', optional: true },
  { property: 'city', label: 'City' },
  { property: 'postalCode', label: 'Postal code' },
  { property: 'street', label: 'Street' },
];
