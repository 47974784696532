import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { Loading, useToggle } from '@hpc/components';
import {
  api as hcApi,
  VesselCallApi,
  VesselCallComplete,
  vesselCallsParser,
} from '@hpc/hatch-clerk';

import { VesselCallsTable } from './VesselCallsTable';

export const VesselCalls = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useToggle(true);
  const [arrivingVesselCalls, setArrivingVesselCalls] = useState<VesselCallComplete[]>([]);
  const [departingVesselCalls, setDepartingVesselCalls] = useState<VesselCallComplete[]>([]);

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await hcApi.get<VesselCallApi[]>('/vessel-calls');
      const vesselCalls = vesselCallsParser(response.data) || [];
      const now = new Date();
      setArrivingVesselCalls(
        vesselCalls
          .filter(({ eta }) => eta?.getTime() > now.getTime())
          .sort((a, b) => (a.eta?.getTime() > b.eta?.getTime() ? 1 : -1))
      );
      setDepartingVesselCalls(
        vesselCalls
          .filter(({ etd }) => etd?.getTime() > now.getTime())
          .sort((a, b) => (a.etd?.getTime() > b.etd?.getTime() ? 1 : -1))
      );
    } catch (e) {
      enqueueSnackbar('Error fetching vessel calls.', { variant: 'error' });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">Arriving vessels</Typography>
      </Grid>
      <Grid item xs={12}>
        {loading ? <Loading /> : <VesselCallsTable isArriving vesselCalls={arrivingVesselCalls} />}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">Departing vessels</Typography>
      </Grid>
      <Grid item xs={12}>
        {loading ? <Loading /> : <VesselCallsTable vesselCalls={departingVesselCalls} />}
      </Grid>
    </Grid>
  );
};
