import React from 'react';
import { useCountdown } from '@hpc/components';

interface IProps {
  time: Date;
}

export const VesselCallTimer = ({ time }: IProps) => {
  const timer = useCountdown(time);
  return <span>{timer}</span>;
};
