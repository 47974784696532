import React from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Controller, useForm } from 'react-hook-form';

import { Button } from '@hpc/components';
import { AddUserInput, User } from '~/types';

interface IProps {
  users: User[];
  onSubmit: (values: AddUserInput) => Promise<void>;
  onCancel: () => void;
}

export const AddUserDialog = ({ users, onCancel, onSubmit }: IProps) => {
  const { control, formState, handleSubmit } = useForm<AddUserInput>({
    defaultValues: {
      user: null,
    },
  });

  const addUser = async (data: AddUserInput) => {
    await onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(addUser)}>
      <DialogTitle>Add user</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Controller
              name="user"
              control={control}
              render={({ field: { onChange } }) => (
                <Autocomplete
                  options={users}
                  getOptionLabel={(user) => `${user.firstName} ${user.name}`}
                  fullWidth
                  onChange={(e, data) => onChange(data)}
                  renderInput={(params) => <TextField {...params} label="User" />}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          disabled={formState.isSubmitting}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button variant="contained" color="primary" type="submit" loading={formState.isSubmitting}>
          Add
        </Button>
      </DialogActions>
    </form>
  );
};
