import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    margin: spacing(2, 0, 0),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circles: {
    position: 'relative',
    height: 129,
  },
  bottom: {
    color: palette.grey[200],
    position: 'absolute',
    left: 2,
    top: 2,
  },
  red: {
    color: palette.error.main,
  },
  yellow: {
    color: palette.warning.main,
  },
  green: {
    color: palette.success.main,
  },
}));

interface IProps {
  current: number;
  total: number;
}

export const ProgressCircle = ({ current, total }: IProps) => {
  const classes = useStyles();
  const progress = total > 0 ? (current / total) * 100 : 0;
  return (
    <div className={classes.root}>
      <div className={classes.circles}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={125}
          thickness={2}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          className={classNames({
            [classes.red]: progress < 25,
            [classes.yellow]: progress >= 25 && progress < 85,
            [classes.green]: progress >= 85,
          })}
          size={129}
          thickness={4}
          value={progress}
        />
      </div>
    </div>
  );
};
