import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Location } from 'history';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  AuthContext,
  ISignIn,
  RedirectState,
  SetNewPasswordInput,
  SignInInput,
  useToggle,
} from '@hpc/components';

import { SetNewPasswordForm, SignInForm } from './components';
import { useStyles } from './styles';

export const SignIn = () => {
  const location = useLocation() as Location<RedirectState>;
  const navigate = useNavigate();
  const classes = useStyles();
  const [newPasswordChallenge, toggleNewPasswordChallenge] = useToggle();
  const [credentials, setCredentials] = useState<SignInInput | undefined>();
  const [hostname, setHostname] = useState('');
  const [subdomain, setSubdomain] = useState('');
  const { signIn, isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    const newHostname = window.location.hostname;
    setHostname(newHostname);
    const newSubdomain =
      newHostname !== 'localhost'
        ? newHostname.replace(/(\.?dev|\.?staging|\.?hpc)?.?tos-addons.com/, '')
        : '';
    setSubdomain(newSubdomain);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(location.state?.pathname || '/');
    }
  }, []);

  const handleSignIn = async (data: ISignIn) => {
    try {
      const user = await signIn(data);

      if (user) {
        const pathname = location.state?.pathname;
        navigate(pathname && pathname !== '/login' ? pathname : '/');
      }
    } catch (error) {
      if (error.error.code === 'NEW_PASSWORD_CHALLENGE') {
        setCredentials(data);
        toggleNewPasswordChallenge();
      } else {
        throw error;
      }
    }
  };

  const handleSetNewPassword = async (data: SetNewPasswordInput) => {
    await handleSignIn({
      ...credentials,
      newPassword: data.password,
    });
  };

  if (!hostname) {
    return null;
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" className={classes.root}>
      {newPasswordChallenge ? (
        <SetNewPasswordForm onSubmit={handleSetNewPassword} />
      ) : (
        <SignInForm subdomain={subdomain} onSubmit={handleSignIn} />
      )}
    </Box>
  );
};
