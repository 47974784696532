import React, { useContext } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';

import { sidebarWidthClosed, sidebarWidthOpen } from '../constants';
import { LayoutContext } from '../LayoutProvider';

interface IStyleProps {
  isOpen: boolean;
}

const useStyles = makeStyles(({ spacing, transitions }) => ({
  bottomPush: ({ isOpen }: IStyleProps) => ({
    position: 'fixed',
    width: isOpen ? sidebarWidthOpen : sidebarWidthClosed,
    bottom: 0,
    textAlign: 'right',
    paddingBottom: spacing(1),
    transition: transitions.create('width', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
  }),
  icon: ({ isOpen }: IStyleProps) => ({
    marginRight: spacing(1),
    transform: isOpen ? 'rotate(180deg)' : '',
    transition: transitions.create('transform', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
  }),
}));

export const SidebarButton = () => {
  const { isSidebarOpen, toggleSidebar } = useContext(LayoutContext);
  const classes = useStyles({ isOpen: isSidebarOpen });

  return (
    <div className={classes.bottomPush}>
      <IconButton aria-label="Open drawer" onClick={toggleSidebar} className={classes.icon}>
        <ArrowForwardIos />
      </IconButton>
    </div>
  );
};
