import { object, string } from 'yup';
import { ERROR_FIELD_IMO_NUMBER_UNIQUE, ERROR_FIELD_REQUIRED } from '~/constants';

import { api } from '../api';

export const vesselNameValidationSchema = string().required(ERROR_FIELD_REQUIRED);

export const imoNumberValidationSchema = string()
  .required(ERROR_FIELD_REQUIRED)
  .matches(/^\d+$/, 'Only digits are allowed for this field ')
  .test('vessel-imo-number-unique', ERROR_FIELD_IMO_NUMBER_UNIQUE, async (value) => {
    try {
      return !!value && !(await api.get(`/vessels/${value}`)).data;
    } catch (e) {
      return true;
    }
  });

export const mmsiNumberValidationSchema = string().matches(
  /(^[0-9]+$|^$)/,
  'Only digits are allowed for this field '
);

export const vesselEditValidationSchema = object({
  vesselName: vesselNameValidationSchema,
  mmsiNumber: mmsiNumberValidationSchema,
});

export const vesselValidationSchema = vesselEditValidationSchema.concat(
  object({
    imoNumber: imoNumberValidationSchema,
    mmsiNumber: mmsiNumberValidationSchema,
  })
);
