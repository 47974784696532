import React from 'react';
import { useSnackbar } from 'notistack';

import { Button, useToggle } from '@hpc/components';

import { User } from '~/types';
import { api } from '~/utils';

interface IProps {
  organisationId: string;
  user: User;
  onUpdate: () => Promise<void>;
}

export const ActivateUserButton = ({ organisationId, user, onUpdate }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, toggleLoading] = useToggle();

  const handleClick = async () => {
    toggleLoading(true);
    try {
      await api.patch(`/organisations/${organisationId}/users/${user.sub}`, {
        active: !user.active,
      });
      await onUpdate();
      enqueueSnackbar(`User has been ${user.active ? 'deactivated' : 'activated'}.`, {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar('Error updating user.', { variant: 'error' });
    }
    toggleLoading(false);
  };

  return (
    <Button type="button" variant="outlined" loading={isLoading} onClick={handleClick}>
      {user.active ? 'Deactivate' : 'Activate'} User
    </Button>
  );
};
