import React from 'react';
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  SelectProps,
} from '@material-ui/core';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    width: '100%',
  },
  label: {
    fontWeight: 600,
  },
  input: {
    marginTop: spacing(0),
  },
  disabled: {
    color: palette.text.primary,
  },
}));

export interface SelectFieldProps extends SelectProps {
  label: string;
  options: string[];
  shrink?: boolean;
}

export const SelectField = ({
  label,
  options,
  shrink,
  fullWidth,
  name,
  ...props
}: SelectFieldProps) => {
  const classes = useStyles();
  const inputClasses = {
    root: classes.input,
    disabled: classes.disabled,
  };

  return (
    <FormControl fullWidth>
      <InputLabel shrink={shrink} htmlFor={name}>
        {label}
      </InputLabel>
      <Select classes={inputClasses} id={name} name={name} fullWidth={fullWidth} {...props}>
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
