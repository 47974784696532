import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { DataTest } from '~/constants';

interface IStylesProps {
  labelWidth?: number;
  valueWidth?: number;
}

const useStyles = makeStyles(() => ({
  label: ({ labelWidth }: IStylesProps) => ({
    width: labelWidth || 150,
    fontWeight: 600,
    textTransform: 'uppercase',
    lineHeight: 1,
  }),
  value: ({ valueWidth }: IStylesProps) => ({
    width: valueWidth || 150,
    lineHeight: '1.1876em',
    marginTop: 4,
    paddingTop: 6,
    paddingBottom: 7,
  }),
}));

interface IProps extends IStylesProps {
  label: string;
  value: string;
}

export const DetailElement = ({ label, labelWidth, value, valueWidth }: IProps) => {
  const classes = useStyles({ labelWidth, valueWidth });
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="caption" className={classes.label}>
        {label}
      </Typography>
      <Typography
        variant="body1"
        className={classes.value}
        data-test={DataTest.DETAIL_ELEMENT_VALUE}
      >
        {value || '\u00A0'}
      </Typography>
    </Box>
  );
};
