import React, { useEffect, useState } from 'react';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableProps } from 'mui-datatables';

const defaultOptions: MUIDataTableOptions = {
  elevation: 1,
  pagination: false,
  download: false,
  print: false,
  search: false,
  viewColumns: false,
  filter: false,
  filterType: 'dropdown',
  selectableRows: 'none',
};

const getDefaultOptions = (data: MUIDataTableProps['data']) => ({
  ...defaultOptions,
  pagination: data.length > 10,
});

interface IProps extends Omit<MUIDataTableProps, 'title'> {
  title?: MUIDataTableProps['title'];
}

export const DataGrid = ({
  data: gridData,
  options: optionsOverride,
  title = '',
  ...props
}: IProps) => {
  const [options, setOptions] = useState(defaultOptions);
  const [data, setData] = useState<MUIDataTableProps['data']>([]);

  useEffect(() => {
    setOptions({
      ...getDefaultOptions(gridData),
      ...optionsOverride,
    });
    setData(gridData);
  }, [gridData, optionsOverride]);

  return <MUIDataTable title={title} data={data} options={options} {...props} />;
};
