import { FieldValues, UseFormSetError } from 'react-hook-form';
import { ApiResponse, ApiValidationError, FieldValidationError } from '~/types';

const messages = {
  'Incorrect username or password': 'Incorrect username or password.',
  'Attempt limit exceeded': 'Attempt limit exceeded, please try after some time.',
  'User is disabled':
    'Your account has been disabled. Please contact the administration for details.',
};

export const mapMessage = (message: string) =>
  messages[Object.keys(messages).find((match) => message.indexOf(match) > -1)] || message;

const fieldValidationErrors = {
  'UserPool not found': {
    field: 'subdomain',
    message: 'Organisation not found.',
  },
  'Organisation with this domain already exists': {
    field: 'tomSubdomain',
    message: 'Organisation with this domain already exists.',
  },
  'A group with that name already exist': {
    field: 'name',
    message: 'A group with that name already exists.',
  },
  'An account with the given email already exists': {
    field: 'emailAddress',
    message: 'An account with the given email already exists.',
  },
  'Invalid phone number format': {
    field: 'phone',
    message: 'Invalid phone number format.',
  },
  'Invalid verification code provided': {
    field: 'confirmationCode',
    message: 'Invalid verification code provided.',
  },
};

export const tempParser = (message: string): ApiResponse<ApiValidationError> => {
  if (!message) {
    return;
  }

  if (message === 'NEW_PASSWORD_REQUIRED') {
    return {
      status: 'error',
      error: {
        code: 'NEW_PASSWORD_CHALLENGE',
        message: 'Please set a new password.',
      },
    };
  }

  const errors: FieldValidationError[] = Object.keys(fieldValidationErrors)
    .filter((ve) => message.indexOf(ve) > -1)
    .map((errorMatch) => fieldValidationErrors[errorMatch]);

  return {
    status: 'error',
    error: {
      code: 'VALIDATION_ERROR',
      message: mapMessage(message),
      ...(errors.length && { extensions: errors }),
    },
  };
};

export const errorInterceptor = (error?) => {
  if (error?.response?.data) {
    const validationError = tempParser(error.response.data.payload?.message);
    return Promise.reject(validationError || error.response.data);
  }
  return Promise.reject(error?.message || '');
};

export const setErrors = <T extends FieldValues>(
  errors: FieldValidationError<T>[],
  setError: UseFormSetError<T>
) => {
  errors.forEach(({ field, message, type }) => {
    setError(field, { message, type });
  });
};
