import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import { HatchClerkInput } from '~/types';

interface IProps {
  control: Control<HatchClerkInput>;
  errors: FieldErrors;
}

export const HatchClerkEdit = ({ control, errors }: IProps) => {
  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <Controller
          name="locationCode"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Location code"
              error={!!errors?.locationCode}
              helperText={errors?.locationCode?.message}
              fullWidth
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
