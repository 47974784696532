export const DataTest = {
  BUTTON_NEXT: 'button-next',
  BUTTON_PREV: 'button-prev',
  DETAIL_ELEMENT_VALUE: 'detailElementValue',
  EDITABLE_ACTIONS: 'editableActions',
  EDITABLE_CONTENT: 'editableContent',
  EDITABLE_TITLE: 'editableTitle',
  NOTIFICATION: 'notification',
  NOTIFICATION_ICON: 'notificationIcon',
  NOTIFICATION_MESSAGE: 'notificationMessage',
  NOTIFICATION_TITLE: 'notificationTitle',
  SIDEBAR_MENU: 'sidebarMenu',
  STEP: 'step',
  STEP_BUTTON: 'step-button',
  STEP_CONTENT: 'step-content',
};
