import { Assignment, AssignmentTurnedIn, DirectionsBoat } from '@material-ui/icons';
import { SidebarElement } from '@hpc/components';

export const sidebarElements: SidebarElement[] = [
  {
    id: 'hatchClerk',
    label: 'Hatch Clerk',
    priority: 10,
    isSection: true,
    open: true,
    visibleCollapsed: true,
    children: [
      {
        id: 'vesselCalls',
        label: 'Vessel Calls',
        priority: 10,
        url: '/hatch-clerk/vessel-calls',
        icon: Assignment,
      },
      {
        id: 'archivedVesselCalls',
        label: 'Archived Vessel Calls',
        priority: 20,
        url: '/hatch-clerk/archived-vessel-calls',
        icon: AssignmentTurnedIn,
      },
      {
        id: 'vessels',
        label: 'Vessels',
        priority: 30,
        url: '/hatch-clerk/vessels',
        icon: DirectionsBoat,
      },
    ],
  },
];
