import React from 'react';
import { Dialog } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { ApiResponse } from '@hpc/components';

import { Group, GroupInput } from '~/types';
import { api } from '~/utils';

import { CreateGroupDialog } from './CreateGroupDialog';

interface IProps {
  organisationId: string;
  onClose: () => void;
}

export const CreateGroup = ({ organisationId, onClose }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (data: GroupInput) => {
    try {
      await api.post<ApiResponse<Group>>(`/organisations/${organisationId}/groups`, data);
      enqueueSnackbar('Group has been created.', { variant: 'success' });
      onClose();
    } catch (error) {
      enqueueSnackbar('Error creating group.', { variant: 'error' });
      throw error;
    }
  };

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="sm">
      <CreateGroupDialog onCancel={onClose} onSubmit={handleSubmit} />
    </Dialog>
  );
};
