import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Controller, useForm } from 'react-hook-form';

import {
  Button,
  setErrors,
  ForgotPasswordInput,
  forgotPasswordValidationSchema,
  ForgotPasswordResponse,
} from '@hpc/components';
import { useStyles } from '../styles';

interface IProps {
  subdomain: string;
  onSubmit: (data: ForgotPasswordInput) => Promise<ForgotPasswordResponse>;
}

export const ForgotPasswordForm = ({ subdomain, onSubmit }: IProps) => {
  const classes = useStyles();
  const [formError, setFormError] = useState('');
  const { control, formState, handleSubmit, setError, setValue, register } =
    useForm<ForgotPasswordInput>({
      resolver: yupResolver(forgotPasswordValidationSchema),
      defaultValues: {
        subdomain,
        username: '',
      },
    });

  useEffect(() => {
    if (subdomain) {
      setValue('subdomain', subdomain);
      register('subdomain');
    }
  }, [subdomain]);

  const resetPassword = async (data: ForgotPasswordInput) => {
    try {
      await onSubmit(data);
    } catch (e) {
      if (e.error && e.error.code === 'VALIDATION_ERROR') {
        if (e.error.extensions) {
          setErrors(e.error.extensions, setError);
        } else {
          setFormError(e.error.message);
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(resetPassword)}>
      <Card className={classes.card} elevation={6}>
        <CardContent>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h1">Reset your password</Typography>
            </Grid>
            {formError && (
              <Grid item>
                <Alert severity="error">{formError}</Alert>
              </Grid>
            )}
            <Grid item>
              <Typography variant="body2">
                Tell us your username and we’ll send you an email with instructions on how to reset
                your password.
              </Typography>
            </Grid>
            {!subdomain && (
              <Grid item>
                <Controller
                  control={control}
                  name="subdomain"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Organisation subdomain"
                      error={!!formState.errors?.subdomain}
                      helperText={formState.errors?.subdomain?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item>
              <Controller
                control={control}
                name="username"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Username"
                    error={!!formState.errors?.username}
                    helperText={formState.errors?.username?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            loading={formState.isSubmitting}
            fullWidth
          >
            Reset password
          </Button>
        </CardActions>
      </Card>
      <Box mt={1} display="flex" justifyContent="flex-end">
        <Link href="/login" className={classes.forgotPasswordLink}>
          Sign In
        </Link>
      </Box>
    </form>
  );
};
