import { WorkingDirection } from '~/types';

export const capitalizeFirstLetter = (string: string) =>
  string?.charAt(0).toUpperCase() + string.slice(1);

export const getYesNoValue = (value?: boolean | string) => {
  if (typeof value === 'string') {
    value.toLowerCase() === 'true' ? 'Yes' : 'No';
  }

  return value ? 'Yes' : 'No';
};

export const wdUndefinedToRob = (wd: WorkingDirection) =>
  wd === WorkingDirection.UNDEFINED ? 'ROB' : wd;
