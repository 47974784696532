import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  percentValue: {
    display: 'inline-block',
    fontSize: '2rem',
    transform: 'scale(1, 1.8)',
    verticalAlign: 'text-bottom',
    fontWeight: 600,
  },
  percent: {
    fontSize: '1.2rem',
    fontWeight: 600,
  },
  currentTotal: {
    fontWeight: 600,
    color: palette.text.hint,
  },
}));

interface IProps {
  current: number;
  total: number;
}

export const ProgressValues = ({ current, total }: IProps) => {
  const classes = useStyles();
  const progress = Math.round((current / total) * 100);
  return (
    <div className={classes.root}>
      <div>
        <span className={classes.percentValue}>{progress}</span>
        <span className={classes.percent}>%</span>
      </div>
      <div className={classes.currentTotal}>
        {current}/{total}
      </div>
    </div>
  );
};
