import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

import {
  Notification,
  NotificationType,
  Table,
  TableColumnDef,
  TableOptions,
} from '@hpc/components';
import { VesselCallComplete } from '@hpc/hatch-clerk';

import { VesselCallTimer } from './VesselCallTimer';

const useStyles = makeStyles(({ spacing }) => ({
  viewAll: {
    padding: spacing(1),
    float: 'right',
    textDecoration: 'none',
  },
}));

interface IProps {
  isArriving?: boolean;
  vesselCalls: VesselCallComplete[];
}
export const VesselCallsTable = ({ isArriving, vesselCalls }: IProps) => {
  const classes = useStyles();

  const renderName = (index) => (
    <Link to={`/hatch-clerk/vessel-calls/${vesselCalls[index].callNumber}`}>
      {vesselCalls[index].vesselName}
    </Link>
  );

  const renderTime = (index) => (
    <VesselCallTimer time={isArriving ? vesselCalls[index].eta : vesselCalls[index].etd} />
  );

  const columns: TableColumnDef[] = [
    {
      label: 'Vessel',
      name: 'vesselName',
      options: {
        render: renderName,
      },
    },
    {
      label: 'Time left',
      name: isArriving ? 'eta' : 'etd',
      options: {
        render: renderTime,
      },
    },
  ];

  const options: TableOptions = {
    size: 'small',
  };

  return vesselCalls.length > 0 ? (
    <>
      <Table columns={columns} data={vesselCalls.slice(0, 5)} options={options} />
      <Link to="/hatch-clerk/vessel-calls" className={classes.viewAll}>
        view all
      </Link>
    </>
  ) : (
    <Notification
      type={NotificationType.WARNING}
      message={`No ${isArriving ? 'arriving' : 'departing'} vessels`}
    />
  );
};
