import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { Button, useToggle } from '@hpc/components';

import { User } from '~/types';
import { api } from '~/utils';

interface IProps {
  organisationId: string;
  groupName: string;
  user: User;
  onClose: () => void;
  onSuccess: () => Promise<void>;
}

export const RemoveUserDialog = ({
  organisationId,
  groupName,
  user,
  onClose,
  onSuccess,
}: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, toggleLoading] = useToggle();

  const handleSubmit = async () => {
    toggleLoading(true);
    try {
      await api.delete(`/organisations/${organisationId}/groups/${groupName}/users/${user.sub}`);
      await onSuccess();
      enqueueSnackbar('User has been removed.', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Error removing user.', { variant: 'error' });
    }
    toggleLoading(false);
  };

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="sm">
      <DialogTitle>Remove user</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to remove the user{' '}
          <strong>{`${user.firstName} ${user.name}`}</strong> from the group?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" disabled={isLoading} onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" loading={isLoading} onClick={handleSubmit}>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};
