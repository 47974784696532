import React from 'react';
import { Grid } from '@material-ui/core';

import { Card, CardContent, CardHeader, DetailField } from '@hpc/components';

import { Vessel } from '~/types';
import { vesselConfig } from './config';

interface IProps {
  vessel: Vessel;
}

export const VesselView = ({ vessel }: IProps) => {
  return (
    <Card>
      <CardHeader title="Vessel details" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <DetailField label="IMO number" value={vessel.imoNumber} />
          </Grid>
          {vesselConfig.map(({ label, property }) => (
            <Grid item key={label} xs={12}>
              <DetailField label={label} value={vessel[property] || ''} />
            </Grid>
          ))}
          <Grid item xs={12}>
            <DetailField label="Geometry origin" value={vessel.geometryOriginator} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
