import React from 'react';
import { Products } from './base';

export enum AppStatus {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  UPDATING = 'UPDATING',
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export interface IApplicationProps {
  app: Application;
  isEditing?: boolean;
  onSubmit: () => Promise<void>;
}

export interface IApplicationRef {
  submit: () => Promise<void>;
}

export type Application = {
  name: Products;
  title?: string;
  description?: string;
  long?: string;
  image?: string;
  status: AppStatus;
  component: React.ForwardRefExoticComponent<
    IApplicationProps & React.RefAttributes<IApplicationRef>
  >;
};
