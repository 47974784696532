import React, { useEffect, useState } from 'react';
import {
  CardHeader,
  Checkbox,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import {
  AccessLevelLabels,
  AccessLevels,
  ApiResponse,
  AppTypeLabels,
  AppTypes,
  Button,
  Card,
  getValue,
  PERMISSION_REGEX,
  ProductLabels,
  Products,
  User,
  useToggle,
} from '@hpc/components';

import { Group } from '~/types';
import { api } from '~/utils';

const useStyles = makeStyles(({ spacing }) => ({
  card: {
    padding: 0,
  },
  cardHeader: {
    padding: spacing(4, 4, 2, 4),
  },
  table: {
    tableLayout: 'fixed',
  },
  label: {
    width: 350,
  },
  permission: {
    width: 150,
    textAlign: 'center',
  },
  app: {
    '& > td': {
      fontWeight: 600,
    },
  },
  type: {
    '& > td:first-child': {
      paddingLeft: spacing(4),
    },
  },
}));

type PermissionsObject = Record<Products, Record<AppTypes, Record<AccessLevels, boolean>>>;
type Permission = {
  key?: string;
  label?: string;
  permissions?: Record<string, boolean>;
};

interface IProps {
  organisationId: string;
  user: User;
  onSave: () => Promise<void>;
}

export const UserPermissions = ({ organisationId, user, onSave }: IProps) => {
  const classes = useStyles();
  const [isEditing, toggleEdit] = useToggle();
  const { enqueueSnackbar } = useSnackbar();
  const { control, formState, handleSubmit, reset } = useForm<PermissionsObject>();
  const [groups, setGroups] = useState<string[]>([]);
  const [appsAccess, setAppsAccess] = useState<Permission[]>([]);

  const fetchGroups = async () => {
    try {
      const response = await api.get<ApiResponse<Group[]>>(
        `/organisations/${organisationId}/groups`
      );
      const permissionGroups = response?.data?.payload
        .map(({ name }) => name)
        .filter((name) => name.match(PERMISSION_REGEX));
      setGroups(permissionGroups);
    } catch (e) {
      enqueueSnackbar('Error fetching groups.', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (organisationId) {
      fetchGroups();
    }
  }, [organisationId]);

  const resetPermissions = () => {
    const permissionGroups = groups
      .map((name) => name.match(PERMISSION_REGEX))
      .reduce(
        (acc, match) => ({
          ...acc,
          [match[0]]: user.groups.includes(match[0]),
        }),
        {}
      );

    reset(permissionGroups);
  };

  useEffect(() => {
    resetPermissions();

    const permissionObject = groups
      .map((name) => name.match(PERMISSION_REGEX))
      .reduce((acc, match) => {
        return {
          ...acc,
          [match[1]]: {
            ...(acc[match[1]] || {}),
            [match[2]]: {
              ...(acc[match[1]]?.[match[2]] || {}),
              [match[3]]: user.groups.includes(match[0]),
            },
          },
        };
      }, {});

    const permissionTable: Permission[] = Object.keys(permissionObject).flatMap((app) => [
      {
        key: app,
        label: ProductLabels[app],
      },
      ...Object.keys(permissionObject[app]).map((appType) => ({
        key: `${app}.${appType}`,
        label: AppTypeLabels[appType],
        permissions: permissionObject[app][appType],
      })),
    ]);

    setAppsAccess(permissionTable);
  }, [groups]);

  const getAppsAccessOfCell = (permission: boolean) => {
    return permission;
  };

  const updateGroups = async (data: PermissionsObject) => {
    try {
      const permissions = groups.reduce(
        (acc, group) => ({ ...acc, [group]: getValue(data, group) }),
        {}
      );
      const addRequests = Object.keys(permissions)
        .filter((permission) => permissions[permission] && !user.groups.includes(permission))
        .map(
          async (group) =>
            await api.post(`/organisations/${organisationId}/groups/${group}/users/${user.sub}`)
        );
      const removeRequests = Object.keys(permissions)
        .filter((permission) => !permissions[permission] && user.groups.includes(permission))
        .map(
          async (group) =>
            await api.delete(`/organisations/${organisationId}/groups/${group}/users/${user.sub}`)
        );

      await Promise.all([...addRequests, ...removeRequests]);
      await onSave();
      enqueueSnackbar('Permissions have been updated.', { variant: 'success' });
      toggleEdit(false);
    } catch (e) {
      enqueueSnackbar('Error updating permission.', { variant: 'error' });
    }
  };

  const handleCancel = () => {
    toggleEdit();
    resetPermissions();
  };

  return (
    <Card className={classes.card}>
      <form onSubmit={handleSubmit(updateGroups)}>
        <CardHeader
          className={classes.cardHeader}
          title="User permissions"
          action={
            isEditing ? (
              <Grid container>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={formState.isSubmitting}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="button"
                    variant="outlined"
                    disabled={formState.isSubmitting}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Button type="button" color="primary" variant="outlined" onClick={toggleEdit}>
                Edit
              </Button>
            )
          }
        />
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.label} />
              {Object.values(AccessLevelLabels).map((level, index) => (
                <TableCell key={index} className={classes.permission}>
                  {level}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {appsAccess.map((row, index) => (
              <TableRow
                key={index}
                className={classNames({
                  [classes.app]: !row.permissions,
                  [classes.type]: !!row.permissions,
                })}
              >
                <TableCell>{row.label}</TableCell>
                {row.permissions ? (
                  <>
                    {Object.keys(AccessLevelLabels).map((level) => (
                      <TableCell key={level} padding="checkbox" className={classes.permission}>
                        <Controller
                          control={control}
                          name={`${row.key}.${level}` as keyof PermissionsObject}
                          render={({ field: { onChange, ...props } }) => (
                            <Checkbox
                              {...props}
                              disabled={!isEditing}
                              onChange={(e) => onChange(e.target.checked)}
                              checked={getAppsAccessOfCell(props.value as unknown as boolean)}
                            />
                          )}
                        />
                      </TableCell>
                    ))}
                  </>
                ) : (
                  <TableCell colSpan={2} />
                )}
                <TableCell />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </form>
    </Card>
  );
};
