import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import {
  AccessControl,
  ApiResponse,
  AuthContext,
  Editable,
  hasRole,
  NotificationError,
  UserRoles as UserRolesType,
  useToggle,
} from '@hpc/components';

import { User as UserType } from '~/types';
import { api, userValidationSchema } from '~/utils';

import {
  ActivateUserButton,
  SendInvitationButton,
  UserEdit,
  UserHeader,
  UserPermissions,
  UserRoles,
  UserView,
} from './components';

export const User = () => {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { user: currentUser, accessControl } = useContext(AuthContext);
  const [loading, setLoading] = useToggle(true);
  const [user, setUser] = useState<UserType>(null);

  const organisationId = params?.organisationId || currentUser.organisationId;

  const fetchUser = async () => {
    try {
      const response = await api.get<ApiResponse<UserType>>(
        `/organisations/${organisationId}/users/${params.userId}`
      );
      setUser(response?.data?.payload);
    } catch (e) {
      enqueueSnackbar('Error fetching user details.', { variant: 'error' });
    }
    setLoading(false);
  };

  const updateUser = async (data: UserType) => {
    const updatedUser = {
      ...(({ firstName, name, emailAddress, jobTitle, phone, department }) => ({
        firstName,
        name,
        emailAddress,
        jobTitle,
        phone,
        department,
      }))(data),
      phone: data.phone?.replaceAll(/[ -]/g, '') ?? '',
    };
    try {
      await api.patch(`/organisations/${organisationId}/users/${user.sub}`, updatedUser);
      await fetchUser();
      enqueueSnackbar('User has been updated.', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error updating user.', { variant: 'error' });
      throw error;
    }
  };

  useEffect(() => {
    if (params?.userId) {
      fetchUser();
    }
  }, [params?.userId]);

  if (!loading && !user) {
    return <NotificationError isCard title="User not found" />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Editable
          title={user && <UserHeader user={user} />}
          entity={user}
          renderEdit={(control, errors) => <UserEdit control={control} errors={errors} />}
          renderNonEdit={<UserView loading={loading} user={user} />}
          canEdit={hasRole(accessControl, UserRolesType.ADMIN)}
          validator={userValidationSchema}
          onSubmit={updateUser}
        />
      </Grid>
      {user && (
        <AccessControl userRole={UserRolesType.ADMIN}>
          <Grid item container>
            {!user?.verified && (
              <Grid item>
                <SendInvitationButton organisationId={organisationId} userId={params.userId} />
              </Grid>
            )}
            <Grid item>
              <ActivateUserButton
                organisationId={organisationId}
                user={user}
                onUpdate={fetchUser}
              />
            </Grid>
          </Grid>
        </AccessControl>
      )}
      {user && (
        <Grid item xs={12}>
          <UserRoles organisationId={organisationId} user={user} onSave={fetchUser} />
        </Grid>
      )}
      {user && (
        <Grid item xs={12}>
          <UserPermissions organisationId={organisationId} user={user} onSave={fetchUser} />
        </Grid>
      )}
    </Grid>
  );
};
