import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import {
  AccessControl,
  AccessLevels,
  Apps,
  AuthContext,
  Editable,
  hasAppAccess,
  Loading,
  NotificationError,
  useToggle,
} from '@hpc/components';

import { StatusIcon } from '~/components';
import { Vessel as VesselType, VesselEditInput } from '~/types';
import { api, vesselEditValidationSchema } from '~/utils';

import { VesselEdit, VesselGeometryUpload, VesselView } from './components';

export const Vessel = () => {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { accessControl } = useContext(AuthContext);
  const [loading, setLoading] = useToggle(true);
  const [vessel, setVessel] = useState<VesselType>(null);
  const [isUpdatingVessel, toggleUpdatingVessel] = useToggle();

  const fetchVessel = async () => {
    try {
      const response = await api.get<VesselType>(`/vessels/${params.imoNumber}`);
      setVessel(response?.data);
    } catch (e) {
      enqueueSnackbar('Error fetching vessel details.', { variant: 'error' });
    }
    setLoading(false);
  };

  const updateVessel = async (data: VesselEditInput) => {
    const updatedVessel = (({ callSign, mmsiNumber, vesselName }) => ({
      callSign,
      mmsiNumber,
      vesselName,
    }))(data);
    try {
      await api.patch(`/vessels/${vessel.imoNumber}`, updatedVessel);
      await fetchVessel();
      enqueueSnackbar('Vessel has been updated.', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Error updating vessel.', { variant: 'error' });
      throw new Error();
    }
  };

  useEffect(() => {
    if (params?.imoNumber) {
      fetchVessel();
    }
  }, [params?.imoNumber]);

  // const handleDelete = () => {
  //   navigate('/hatch-clerk/vessels');
  // };

  if (loading) {
    return <Loading />;
  }

  if (!vessel) {
    return <NotificationError isCard title="Vessel not found" />;
  }

  return (
    <>
      <Grid container alignItems="stretch">
        <Grid item xs={12}>
          <Editable
            title={`${vessel?.vesselName} (#${vessel?.imoNumber})`}
            entity={vessel}
            renderEdit={(control, errors) => (
              <VesselEdit control={control} errors={errors} vessel={vessel} />
            )}
            renderNonEdit={<VesselView vessel={vessel} />}
            canEdit={hasAppAccess(accessControl, Apps.HATCH_CLERK, AccessLevels.WRITE)}
            onSubmit={updateVessel}
            validator={vesselEditValidationSchema}
          />
        </Grid>

        <AccessControl app={Apps.HATCH_CLERK} accessLevel={AccessLevels.WRITE}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={toggleUpdatingVessel}
                  endIcon={<StatusIcon status={vessel?.profileStatus} />}
                >
                  Vessel geometry
                </Button>
              </Grid>
              {/*<Grid item>*/}
              {/*  <VesselDeleteButton imoNumber={vessel.imoNumber} onDelete={handleDelete} />*/}
              {/*</Grid>*/}
            </Grid>
          </Grid>
        </AccessControl>
      </Grid>

      {isUpdatingVessel && (
        <VesselGeometryUpload
          imoNumber={vessel.imoNumber}
          onUpload={fetchVessel}
          onClose={() => toggleUpdatingVessel(false)}
        />
      )}
    </>
  );
};
