import React, { useEffect, useState } from 'react';
import { Chip, Grid, makeStyles, Typography } from '@material-ui/core';

import { AccessControl, AccessLevels, Apps } from '@hpc/components';

import {
  Bay,
  Container,
  HandlingStatus,
  Vessel,
  VesselCall as VesselCallType,
  VesselCallComplete,
  WorkingDirection,
} from '~/types';
import { vesselCallFromComplete, vesselFromComplete } from '~/utils';

import {
  BaysTable,
  ContainersTable,
  EstimatedTimeVertical,
  Progress,
  VesselCallDetails,
  VesselCallMenu,
  VesselDetails,
} from './components';

const useStyles = makeStyles(({ breakpoints }) => ({
  header: {
    order: -2,
  },
  archived: {
    textTransform: 'uppercase',
  },
  menu: {
    marginLeft: 'auto',
  },
  vesselDetails: {
    [breakpoints.up('xl')]: {
      order: -1,
    },
  },
}));

interface IProps {
  vesselCallComplete: VesselCallComplete;
  bays: Bay[];
  containers: Container[];
  onUpdate: () => Promise<void>;
}

export const VesselCall = ({ vesselCallComplete, bays, containers, onUpdate }: IProps) => {
  const classes = useStyles();
  const [vessel, setVessel] = useState<Vessel>(vesselFromComplete(vesselCallComplete));
  const [vesselCall, setVesselCall] = useState<VesselCallType>(
    vesselCallFromComplete(vesselCallComplete)
  );

  useEffect(() => {
    setVessel(vesselFromComplete(vesselCallComplete));
    setVesselCall(vesselCallFromComplete(vesselCallComplete));
  }, [vesselCallComplete]);

  const baysDischargeCurrent = bays.filter(
    (bay) =>
      bay.workingDirection === WorkingDirection.DISCHARGE && bay.status === HandlingStatus.CLOSED
  ).length;
  const baysDischargeTotal = bays.filter(
    (bay) => bay.workingDirection === WorkingDirection.DISCHARGE
  ).length;
  const baysLoadCurrent = bays.filter(
    (bay) => bay.workingDirection === WorkingDirection.LOAD && bay.status === HandlingStatus.CLOSED
  ).length;
  const baysLoadTotal = bays.filter((bay) => bay.workingDirection === WorkingDirection.LOAD).length;

  const containersDischargeCurrent = containers.filter(
    (container) =>
      container.workingDirection === WorkingDirection.DISCHARGE &&
      container.confirmedPositions.bay.length > 0
  ).length;
  const containersDischargeTotal = containers.filter(
    (container) => container.workingDirection === WorkingDirection.DISCHARGE
  ).length;
  const containersLoadCurrent = containers.filter(
    (container) =>
      container.workingDirection === WorkingDirection.LOAD &&
      container.confirmedPositions.bay.length > 0
  ).length;
  const containersLoadTotal = containers.filter(
    (container) => container.workingDirection === WorkingDirection.LOAD
  ).length;

  return (
    <Grid container>
      <Grid item xs={12} className={classes.header}>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="h1">{`${vessel.vesselName} (#${vesselCall.callNumber})`}</Typography>
          </Grid>
          <Grid item>
            {vesselCall.isArchived && (
              <Chip className={classes.archived} color="secondary" label="Archived" />
            )}
          </Grid>

          <AccessControl app={Apps.HATCH_CLERK} accessLevel={AccessLevels.WRITE}>
            <Grid item className={classes.menu}>
              <VesselCallMenu
                vesselCall={vesselCall}
                bays={bays}
                containers={containers}
                onChange={onUpdate}
              />
            </Grid>
          </AccessControl>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={9} xl={5}>
        <VesselCallDetails vesselCall={vesselCall} onUpdate={onUpdate} />
      </Grid>
      <Grid item xs={12} md={4} lg={3} xl={2}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <EstimatedTimeVertical estimatedTime={vesselCall.eta} estimation="eta" />
          </Grid>
          <Grid item xs={12} md={12}>
            <EstimatedTimeVertical estimatedTime={vesselCall.etd} estimation="etd" />
          </Grid>
          <Grid item xs={12} md={12}>
            <EstimatedTimeVertical estimatedTime={vesselCall.atd} estimation="atd" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} xl={5} className={classes.vesselDetails}>
        <VesselDetails vessel={vessel} onUpdate={onUpdate} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Progress
          title="Bays"
          dischargeCurrent={baysDischargeCurrent}
          dischargeTotal={baysDischargeTotal}
          loadCurrent={baysLoadCurrent}
          loadTotal={baysLoadTotal}
          table={<BaysTable bays={bays} />}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Progress
          title="Containers"
          dischargeCurrent={containersDischargeCurrent}
          dischargeTotal={containersDischargeTotal}
          loadCurrent={containersLoadCurrent}
          loadTotal={containersLoadTotal}
          table={<ContainersTable containers={containers} />}
        />
      </Grid>
    </Grid>
  );
};
