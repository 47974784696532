import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider, LayoutProvider, ThemeProvider } from '@hpc/components';
import { App } from './App';

export const AppWrapper = () => {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <AuthProvider>
          <LayoutProvider>
            <App />
          </LayoutProvider>
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};
