import React from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { Link } from 'react-router-dom';

import { StatusIcon } from '~/components';
import { Vessel, VesselProfileStatus } from '~/types';

const getProfileFilter = (profileStatus: string): string =>
  profileStatus === VesselProfileStatus.PRESENT ? 'Yes' : 'No';

export const getVesselColumns = (vessels: Vessel[]): MUIDataTableColumnDef[] => {
  const renderImoNumber = (value) => (
    <Link to={`${vessels[value].imoNumber}`}>{vessels[value].imoNumber}</Link>
  );
  const renderProfileStatus = (value) => <StatusIcon status={vessels[value].profileStatus} />;

  const setCellProps = () => ({
    padding: 'checkbox',
    align: 'center',
  });

  return [
    {
      name: 'imoNumber',
      label: 'IMO #',
      options: {
        customBodyRenderLite: renderImoNumber,
        customFilterListOptions: {
          render: (v) => `IMO: ${v}`,
        },
      },
    },
    { name: 'vesselName', label: 'Vessel Name' },
    {
      name: 'callSign',
      label: 'Call Sign',
      options: {
        customFilterListOptions: {
          render: (v) => `Call Sign: ${v}`,
        },
      },
    },
    {
      name: 'mmsiNumber',
      label: ' MMSI #',
      options: {
        customFilterListOptions: {
          render: (v) => `MMSI: ${v}`,
        },
      },
    },
    {
      name: 'profileStatus',
      label: 'Geometry',
      options: {
        customBodyRenderLite: renderProfileStatus,
        customFilterListOptions: {
          render: (v) => `Profile: ${getProfileFilter(v)}`,
        },
        filterOptions: {
          renderValue: getProfileFilter,
        },
        filterType: 'dropdown',
        setCellProps,
      },
    },
    {
      name: 'geometryOriginator',
      label: 'Geometry origin',
    },
  ];
};
