import { SvgIcon } from '@material-ui/core';
import { NavigateFunction } from 'react-router';
import { AccessControlType, BaseEntity, User } from '~/types';

type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

export enum SidebarAction {
  SET = 'set',
  ADD = 'add',
  UPDATE = 'update',
  REMOVE = 'remove',
  RESET = 'reset',
}

export type SidebarBase = BaseEntity & {
  parent?: string;
  label?: string;
  priority?: number;
  dividerBefore?: boolean;
  dividerAfter?: boolean;
  hidden?: boolean;
  access?: (accessControl: AccessControlType, user: User) => boolean;
  open?: boolean;
  visibleCollapsed?: boolean;
  children?: SidebarElement[];
};

export type SidebarItem = SidebarBase & {
  icon?: typeof SvgIcon;
  url?: string;
  exact?: boolean;
  action?: (
    item: SidebarItem,
    toggleOpen: (open?: boolean) => void,
    navigate: NavigateFunction
  ) => void;
};

export type SidebarSection = SidebarBase & {
  isSection?: boolean;
};

export type SidebarElement = SidebarItem & SidebarSection;

export type SidebarItemStore = Overwrite<
  SidebarItem,
  {
    action?: (
      item: SidebarItemStore,
      toggleOpen: (open?: boolean) => void,
      navigate: NavigateFunction
    ) => void;
    children: string[];
  }
>;

export type SidebarSectionStore = Overwrite<SidebarSection, { children: string[] }>;

export type SidebarElementStore = SidebarItemStore & SidebarSectionStore;
