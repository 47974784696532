import React, { createContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AccessControlType,
  ForgotPasswordInput,
  ForgotPasswordResponse,
  SignInInput,
  User,
} from '../../types';
import { getAccessControl } from './accessControl';
import {
  confirmPassword,
  getUser,
  IConfirmPassword,
  ISignIn,
  resetPassword,
  signIn as authSign,
  signOut as authSignOut,
} from './auth';

export type AuthContextType = {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  user?: User;
  signIn: (input: ISignIn) => Promise<User>;
  signOut: () => void;
  resetPassword: (input: ForgotPasswordInput) => Promise<ForgotPasswordResponse>;
  confirmPassword: (input: IConfirmPassword) => Promise<void>;
  accessControl: AccessControlType;
};

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);
AuthContext.displayName = 'AuthContext';

type AuthContextProps = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: AuthContextProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState<User | undefined>();
  const [isAuthenticating, setAuthenticating] = useState<boolean>(true);
  const isAuthenticated = !!user;
  const [accessControl, setAccessControl] = useState(getAccessControl());

  const signIn = async (input: SignInInput) => {
    await authSign(input);
    const newUser = await getUser();
    setUser(newUser);
    setAccessControl(getAccessControl(newUser));
    return newUser;
  };

  const fetchUser = async () => {
    setAuthenticating(true);
    try {
      const user = await getUser();
      setUser(user);
      setAccessControl(getAccessControl(user));
    } catch (e) {
      if (pathname !== '/forgot-password' && pathname !== '/confirm-password') {
        navigate('/login', { state: { pathname } });
      }
    }
    setAuthenticating(false);
  };

  const signOut = () => {
    authSignOut();
    setUser(undefined);
    navigate('/login', { state: { pathname } });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        isAuthenticating,
        signIn,
        signOut,
        resetPassword,
        confirmPassword,
        accessControl,
      }}
    >
      {!isAuthenticating && children}
    </AuthContext.Provider>
  );
};
