import React, { useEffect, useRef, useState } from 'react';
import {
  CircularProgress,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { MoreVert } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { useToggle } from '@hpc/components';

import { Bay, Container, HandlingStatus, VesselCall, VesselCallCloseInput } from '~/types';
import { api } from '~/utils';

import { ArchiveVesselCallDialog } from './ArchiveVesselCallDialog';
import { CloseVesselCallDialog } from './CloseVesselCallDialog';
import { DeleteVesselCallDialog } from './DeleteVesselCallDialog';

const useStyles = makeStyles(() => ({
  menu: {
    '& ul': {
      minWidth: 100,
    },
  },
  delete: {
    color: red[700],
  },
}));

interface IProps {
  vesselCall: VesselCall;
  bays: Bay[];
  containers: Container[];
  onChange: () => Promise<void>;
}

export const VesselCallMenu = ({ vesselCall, bays, containers, onChange }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, toggleLoading] = useToggle();
  const [isClosingVesselCall, toggleCloseVesselCall] = useToggle();
  const [isArchivingVesselCall, toggleArchiveVesselCall] = useToggle();
  const [isDeleteDisabled, toggleDeleteDisabled] = useToggle();
  const [isDeletingVesselCall, toggleDeleteVesselCall] = useToggle();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  useEffect(() => {
    toggleDeleteDisabled(
      bays.some((bay) => bay.status === HandlingStatus.CLOSED || bay.note) ||
        containers.some(
          (container) => container.confirmedPositions.bay.length > 0 || container.note
        )
    );
  }, [bays, containers]);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleCloseClick = () => {
    setOpen(false);
    if (vesselCall.handlingStatus === HandlingStatus.OPEN) {
      toggleCloseVesselCall(true);
    } else {
      openVesselCall();
    }
  };

  const closeVesselCall = async (data: VesselCallCloseInput) => {
    toggleLoading(true);
    try {
      await api.post<VesselCall>(`/vessel-calls/${vesselCall.callNumber}/close`, data);
      await onChange();
      enqueueSnackbar('Vessel call has been closed.', { variant: 'success' });
      toggleCloseVesselCall(false);
    } catch (e) {
      enqueueSnackbar('Error closing Vessel call.', { variant: 'error' });
    }
    toggleLoading(false);
  };

  const openVesselCall = async () => {
    toggleLoading(true);
    try {
      await api.post<VesselCall>(`/vessel-calls/${vesselCall.callNumber}/open`);
      await onChange();
      enqueueSnackbar('Vessel call has been opened.', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Error opening Vessel call.', { variant: 'error' });
    }
    toggleLoading(false);
  };

  const handleArchiveClick = () => {
    setOpen(false);
    if (vesselCall.isArchived) {
      dearchiveVesselCall();
    } else {
      toggleArchiveVesselCall(true);
    }
  };

  const archiveVesselCall = async () => {
    toggleLoading(true);
    try {
      await api.post<VesselCall>(`/vessel-calls/${vesselCall.callNumber}/archive`);
      await onChange();
      enqueueSnackbar('Vessel call has been archived.', { variant: 'success' });
      toggleArchiveVesselCall(false);
    } catch (e) {
      enqueueSnackbar('Error archiving Vessel call.', { variant: 'error' });
    }
    toggleLoading(false);
  };

  const dearchiveVesselCall = async () => {
    toggleLoading(true);
    try {
      await api.post<VesselCall>(`/vessel-calls/${vesselCall.callNumber}/dearchive`);
      await onChange();
      enqueueSnackbar('Vessel call has been dearchived.', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Error dearchiving Vessel call.', { variant: 'error' });
    }
    toggleLoading(false);
  };

  const handleDeleteClick = () => {
    setOpen(false);
    toggleDeleteVesselCall();
  };

  const deleteVesselCall = async () => {
    toggleLoading(true);
    try {
      await api.delete<VesselCall>(`/vessel-calls/${vesselCall.callNumber}`);
      enqueueSnackbar('Vessel call has been deleted.', { variant: 'success' });
      navigate(`/hatch-clerk/${vesselCall.isArchived ? 'archived-' : ''}vessel-calls`);
    } catch (e) {
      enqueueSnackbar('Error deleting Vessel call.', { variant: 'error' });
    }
    toggleLoading(false);
  };

  return (
    <div>
      <IconButton color="inherit" ref={anchorRef} disabled={isLoading} onClick={toggleOpen}>
        {isLoading ? <CircularProgress color="inherit" size={24} /> : <MoreVert />}
      </IconButton>
      <Menu
        className={classes.menu}
        anchorEl={anchorRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        open={open}
        onClose={toggleOpen}
        keepMounted
      >
        {!vesselCall.isArchived && (
          <MenuItem disabled={isLoading} onClick={handleCloseClick}>
            {vesselCall.handlingStatus === HandlingStatus.OPEN ? 'Close' : 'Open'}
          </MenuItem>
        )}
        <MenuItem disabled={isLoading} onClick={handleArchiveClick}>
          {vesselCall.isArchived ? 'Dearchive' : 'Archive'}
        </MenuItem>
        <Tooltip
          arrow
          title={
            isDeleteDisabled
              ? 'This vessel call has already been processed, you can no longer delete it.'
              : ''
          }
        >
          <div>
            <MenuItem
              className={classes.delete}
              disabled={isLoading || isDeleteDisabled}
              onClick={handleDeleteClick}
            >
              Delete
            </MenuItem>
          </div>
        </Tooltip>
      </Menu>

      {isClosingVesselCall && (
        <CloseVesselCallDialog onCancel={toggleCloseVesselCall} onConfirm={closeVesselCall} />
      )}

      {isArchivingVesselCall && (
        <ArchiveVesselCallDialog onCancel={toggleArchiveVesselCall} onConfirm={archiveVesselCall} />
      )}

      {isDeletingVesselCall && (
        <DeleteVesselCallDialog onCancel={toggleDeleteVesselCall} onConfirm={deleteVesselCall} />
      )}
    </div>
  );
};
