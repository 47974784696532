import axios, { AxiosInstance } from 'axios';
import { API_URL, LOCAL_STORAGE_ID_TOKEN_KEY } from '~/constants';
import { errorInterceptor } from './errors';

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// temporary solution until server will handle error correctly
api.interceptors.response.use(undefined, errorInterceptor);

export const setAuthorizationHeader = (api: AxiosInstance) => {
  const idToken = localStorage.getItem(LOCAL_STORAGE_ID_TOKEN_KEY);

  api.defaults.headers = {
    ...api.defaults.headers,
    ...(idToken && { Authorization: `Bearer ${idToken}` }),
  };
};
