import React from 'react';
import { Button as MuiButton, ButtonProps, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  loadingIndicator: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%)',
    display: 'flex',
    visibility: 'visible',
  },
  labelLoading: {
    visibility: 'hidden',
  },
}));

interface IProps extends ButtonProps {
  loading?: boolean;
  loadingIndicator?: React.ReactNode;
}

export const LoadingIndicator = <CircularProgress color="inherit" size={16} />;

export const Button = React.forwardRef<HTMLButtonElement, IProps>(function Button(
  { children, disabled = false, loading = false, loadingIndicator = LoadingIndicator, ...props },
  ref
) {
  const classes = useStyles();

  return (
    <MuiButton
      disabled={disabled || loading}
      ref={ref}
      classes={{
        label: loading && classes.labelLoading,
      }}
      {...props}
    >
      {loading && <div className={classes.loadingIndicator}>{loadingIndicator}</div>}
      {children}
    </MuiButton>
  );
});
