import React, { useContext } from 'react';
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import {
  AccessLevels,
  Apps,
  AuthContext,
  Card,
  Editable,
  hasAppAccess,
  useToggle,
} from '@hpc/components';

import { DataTest } from '~/constants';
import { Vessel, VesselEditInput } from '~/types';
import { api, vesselCallEditValidationSchema } from '~/utils';

import { FileStatus } from '../FileStatus';
import { VesselGeometryUpdate } from '../VesselGeometry';

import { VesselDetailsEdit } from './VesselDetailsEdit';
import { VesselDetailsView } from './VesselDetailsView';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    height: '100%',
    padding: 0,
  },
  wrapper: {
    height: '100%',
  },
  title: {
    fontSize: '1.1rem',
  },
  details: {
    padding: spacing(2),
  },
  uploads: {
    marginTop: 'auto',
  },
  divider: {
    width: '100%',
  },
}));

interface IProps {
  vessel: Vessel;
  onUpdate: () => Promise<void>;
}

export const VesselDetails = ({ vessel, onUpdate }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { accessControl } = useContext(AuthContext);
  const classes = useStyles();
  const [isUpdatingProfile, toggleUpdatingProfile] = useToggle();

  const updateVesselCall = async (data: VesselEditInput) => {
    try {
      await api.patch(`/vessels/${vessel.imoNumber}`, data);
      await onUpdate();
      enqueueSnackbar('Vessel has been updated.', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Error updating vessel.', { variant: 'error' });
    }
  };

  return (
    <Card className={classes.root} data-test={DataTest.HC_VESSEL_DETAILS}>
      <Grid container spacing={0} alignContent="space-between" className={classes.wrapper}>
        <Grid item xs={12} className={classes.details}>
          <Editable
            title={
              <Typography variant="h5" className={classes.title}>
                Vessel details
              </Typography>
            }
            entity={vessel}
            renderEdit={(control, errors) => (
              <VesselDetailsEdit vessel={vessel} control={control} errors={errors} />
            )}
            renderNonEdit={<VesselDetailsView vessel={vessel} />}
            canEdit={hasAppAccess(accessControl, Apps.HATCH_CLERK, AccessLevels.WRITE)}
            buttonSize="small"
            onSubmit={updateVesselCall}
            validator={vesselCallEditValidationSchema}
          />
        </Grid>

        <Grid item xs={12} className={classes.uploads}>
          <Divider className={classes.divider} />
          <FileStatus
            title="Vessel Geometry"
            status={vessel.profileStatus}
            canEdit={hasAppAccess(accessControl, Apps.HATCH_CLERK, AccessLevels.WRITE)}
            onEdit={toggleUpdatingProfile}
          />
        </Grid>
      </Grid>

      {isUpdatingProfile && (
        <VesselGeometryUpdate
          vessel={vessel}
          onSuccess={onUpdate}
          onClose={() => toggleUpdatingProfile(false)}
        />
      )}
    </Card>
  );
};
