import React, { useContext } from 'react';
import { RouteProps } from 'react-router';
import { Navigate, Route } from 'react-router-dom';

import { AccessLevels, Apps, UserRoles } from '~/types';
import { AuthContext, hasAppAccess, hasRole } from '~/utils';

import { NotificationForbidden } from '../Notification';

interface IProps extends RouteProps {
  userRole?: UserRoles;
  app?: Apps;
  accessLevel?: AccessLevels;
  renderNoAccess?: React.ReactNode;
}

export const PrivateRoute = ({
  userRole,
  app,
  accessLevel = AccessLevels.READ,
  renderNoAccess = <NotificationForbidden isCard />,
  ...routeProps
}: IProps) => {
  const { user, accessControl } = useContext(AuthContext);

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (
    (userRole && !hasRole(accessControl, userRole)) ||
    (app && !hasAppAccess(accessControl, app, accessLevel))
  ) {
    return <Route element={<>{renderNoAccess}</>} />;
  }

  return <Route {...routeProps} />;
};
