import { date, object, string } from 'yup';

import {
  ERROR_FIELD_CALL_NUMBER_UNIQUE,
  ERROR_FIELD_ETA_ETD,
  ERROR_FIELD_REQUIRED,
} from '~/constants';

import { api } from '../api';
import {
  imoNumberValidationSchema,
  mmsiNumberValidationSchema,
  vesselNameValidationSchema,
} from './vessel';

export const validDateRange = (first: Date, second: Date) => {
  if (first && second) {
    return first < second;
  }
  return true;
};

export const vesselCallNumberValidationSchema = string()
  .required(ERROR_FIELD_REQUIRED)
  .test('vc-call-number-unique', ERROR_FIELD_CALL_NUMBER_UNIQUE, async (value) => {
    try {
      return !!value && !(await api.get(`/vessel-calls/${value}`)).data;
    } catch (e) {
      return true;
    }
  });

export const vesselCallEditValidationSchema = object({
  eta: date()
    .nullable()
    .test('eta-etd-valid-date-range', ERROR_FIELD_ETA_ETD, function (eta) {
      const { etd } = this.parent;
      return validDateRange(eta, etd);
    }),
  etd: date()
    .nullable()
    .test('eta-etd-valid-date-range', ERROR_FIELD_ETA_ETD, function (etd) {
      const { eta } = this.parent;
      return validDateRange(eta, etd);
    }),
  mmsiNumber: mmsiNumberValidationSchema,
});

export const vesselCallValidationSchema = object({
  callNumber: vesselCallNumberValidationSchema,
})
  .concat(vesselCallEditValidationSchema)
  .concat(
    object({
      vessel: string().nullable().required(ERROR_FIELD_REQUIRED),
      imoNumber: string().when('vessel', {
        is: 'new',
        then: imoNumberValidationSchema,
      }),
      mmsiNumber: string().when('vessel', {
        is: 'new',
        then: mmsiNumberValidationSchema,
      }),
      vesselName: string().when('vessel', {
        is: 'new',
        then: vesselNameValidationSchema,
      }),
    })
  );
