import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { ApiResponse, useToggle } from '@hpc/components';

import { DataTest } from '~/constants';
import { Organisation } from '~/types';
import { api } from '~/utils';

import { CreateOrganisation } from './CreateOrganisation';
import { OrganisationsTable } from './OrganisationsTable';

export const Organisations = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isAddingOrganisation, toggleAddingOrganisation] = useToggle();
  const [loading, setLoading] = useToggle(true);
  const [organisations, setOrganisations] = useState<Organisation[]>([]);

  const fetchData = async () => {
    try {
      const response = await api.get<ApiResponse<Organisation[]>>('/organisations');
      setOrganisations(response?.data?.payload || []);
    } catch (e) {
      enqueueSnackbar('Error fetching organisations.', { variant: 'error' });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [isAddingOrganisation]);

  return (
    <>
      <Grid container direction="column">
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography variant="h1">Organisations</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={loading}
              onClick={toggleAddingOrganisation}
              data-test={DataTest.ORGANISATIONS_ADD_NEW_BUTTON}
            >
              New Organisation
            </Button>
          </Grid>
        </Grid>

        <Grid item>
          <OrganisationsTable loading={loading} organisations={organisations} />
        </Grid>
      </Grid>

      {isAddingOrganisation && (
        <CreateOrganisation onClose={() => toggleAddingOrganisation(false)} />
      )}
    </>
  );
};
