export const DataTest = {
  SIGNIN_SUBDOMAIN_FIELD: 'signinSubdomainField',
  SIGNIN_EMAIL_FIELD: 'signinEmailField',
  SIGNIN_PASSWORD_FIELD: 'signinPasswordField',
  SIGNIN_SUBMIT_BUTTON: 'signinSubmitButton',
  SIGNIN_FORGOT_PASSWORD_LINK: 'signinForgotPasswordLink',
  DASHBOARD_BANNER: 'dashboardBanner',
  ORGANISATION_DETAILS_CARD: 'organisationDetailsCard',
  ORGANISATION_ADDRESS_CARD: 'organisationAddressCard',
  ORGANISATION_USER_TABLE: 'organisationUserTable',
  ORGANISATIONS_TABLE: 'organisationsTable',
  ORGANISATIONS_ADD_NEW_BUTTON: 'organisationsAddNewButton',
};
