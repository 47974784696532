import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import { Button, Loading, useToggle } from '@hpc/components';

import { FileUpload } from '~/components';
import { DATETIME_FORMAT } from '~/constants';
import { VesselCallInput, Vessel } from '~/types';
import { api, baplie, vesselCallValidationSchema, vesselGeometry } from '~/utils';

import { SelectVessel } from './SelectVessel';

interface IProps {
  onSubmit: (values: VesselCallInput, createVessel: boolean) => void;
  onCancel: () => void;
}

const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};

export const CreateVesselCallDialog = ({ onCancel, onSubmit }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useToggle(true);
  const [vessel, setVessel] = useState('');
  const [vessels, setVessels] = useState<Record<Vessel['imoNumber'], Vessel>>({});
  const [arrivalBaplieFile, setArrivalBaplieFile] = useState<File | undefined>();
  const [departureBaplieFile, setDepartureBaplieFile] = useState<File | undefined>();
  const [vesselGeometryFile, setVesselGeometryFile] = useState<File | undefined>();
  const { control, formState, handleSubmit, setValue, trigger } = useForm<VesselCallInput>({
    resolver: yupResolver(vesselCallValidationSchema),
    defaultValues: {
      callNumber: '',
      vesselName: '',
      mmsiNumber: '',
      callSign: '',
      voyageNumber: '',
      vessel: '',
      imoNumber: '',
      eta: null,
      etd: null,
    },
  });

  const fetchVessels = async () => {
    try {
      const response = await api.get<Vessel[]>('/vessels');
      setVessels(
        response?.data.reduce(
          (acc, vessel) => ({
            ...acc,
            [vessel.imoNumber]: vessel,
          }),
          {}
        ) || []
      );
    } catch (e) {
      enqueueSnackbar('Error fetching vessels.', { variant: 'warning' });
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchVessels();
  }, []);

  const handleArrivalBaplieParse = (file: File) => {
    if (!file) {
      setArrivalBaplieFile(undefined);
      return;
    }
    const reader = new FileReader();

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');

    reader.onload = () => {
      setArrivalBaplieFile(file);
      try {
        const parsedBaplie = baplie.parse(reader.result);
        if (parsedBaplie.vesselName) {
          const vessel = Object.values(vessels).find(
            (vessel) => vessel.vesselName === parsedBaplie.vesselName
          );
          if (vessel) {
            handleImoNumberChange(vessel.imoNumber);
          }
        }
      } catch (e) {
        // silent
        // enqueueSnackbar('Error parsing the BAPLIE file.', { variant: 'error' });
      }
    };

    reader.readAsText(file);
  };

  const handleDepartureBaplieParse = (file: File) => {
    if (!file) {
      setDepartureBaplieFile(undefined);
      return;
    }
    const reader = new FileReader();

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');

    reader.onload = () => {
      setDepartureBaplieFile(file);
    };

    reader.readAsText(file);
  };

  const handleVesselGeometryParse = (file: File) => {
    if (!file) {
      setVesselGeometryFile(undefined);
      return;
    }
    const reader = new FileReader();

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');

    reader.onload = () => {
      setVesselGeometryFile(file);

      if (file.name.split('.').pop() === 'json') {
        try {
          const parsedGeometry = vesselGeometry.parse(reader.result);
          setValue('imoNumber', parsedGeometry.imoNumber);
          setValue('vesselName', parsedGeometry.vesselName);
          setValue('callSign', parsedGeometry.callSign);
        } catch (e) {
          // silent
          // enqueueSnackbar('Error parsing vessel geometry.', { variant: 'error' });
        }
      }
    };

    reader.readAsText(file);
  };

  const createVesselCall = async (data: VesselCallInput) => {
    await onSubmit(
      {
        ...data,
        arrivalBaplie: arrivalBaplieFile,
        departureBaplie: departureBaplieFile,
        geometry: vesselGeometryFile,
      },
      vessel === 'new'
    );
  };

  const handleImoNumberChange = (vessel: string) => {
    setVessel(vessel);
  };

  useEffect(() => {
    if (vessel) {
      if (vessels[vessel]) {
        setValue('imoNumber', vessel);
        setValue('vesselName', vessels[vessel].vesselName);
        setValue('callSign', vessels[vessel].callSign);
        setValue('mmsiNumber', vessels[vessel].mmsiNumber);
      } else if (vessel !== 'new') {
        enqueueSnackbar('Could not find vessel data.', { variant: 'error' });
      }
    }
  }, [vessel]);

  if (loading) {
    return <Loading />;
  }

  return (
    <form onSubmit={handleSubmit(createVesselCall)}>
      <DialogTitle>Create Vessel Call</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Vessel call details</Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="callNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Call number"
                      onBlur={() => trigger('callNumber')}
                      error={!!formState.errors?.callNumber}
                      helperText={formState.errors?.callNumber?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="voyageNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Voyage number"
                      onBlur={() => trigger('voyageNumber')}
                      error={!!formState.errors?.voyageNumber}
                      helperText={formState.errors?.voyageNumber?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="eta"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <KeyboardDateTimePicker
                      onChange={onChange}
                      label="Estimated time of arrival"
                      error={!!formState.errors?.eta}
                      helperText={formState.errors?.eta?.message}
                      value={value}
                      fullWidth
                      variant="inline"
                      format={DATETIME_FORMAT}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="etd"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <KeyboardDateTimePicker
                      onChange={onChange}
                      label="Estimated time of departure"
                      error={!!formState.errors?.etd}
                      helperText={formState.errors?.etd?.message}
                      value={value}
                      fullWidth
                      variant="inline"
                      format={DATETIME_FORMAT}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FileUpload
                  label="Upload arrival BAPLIE"
                  accept=".edi"
                  onChange={handleArrivalBaplieParse}
                />
              </Grid>
              <Grid item xs={12}>
                <FileUpload
                  label="Upload departure BAPLIE"
                  accept=".edi"
                  onChange={handleDepartureBaplieParse}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Vessel details</Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="vessel"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectVessel
                      label="Vessel"
                      vessels={vessels}
                      vessel={vessel}
                      value={value}
                      error={!!formState.errors?.vessel}
                      helperText={formState.errors?.vessel?.message}
                      onChange={(data) => {
                        onChange(data);
                        handleImoNumberChange(data);
                      }}
                    />
                  )}
                />
              </Grid>
              {vessel && (
                <>
                  <Grid item xs={12}>
                    <Controller
                      name="imoNumber"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="IMO number"
                          onBlur={() => trigger('imoNumber')}
                          error={!!formState.errors?.imoNumber}
                          helperText={formState.errors?.imoNumber?.message}
                          disabled={vessel !== 'new'}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="vesselName"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Vessel name"
                          onBlur={() => trigger('vesselName')}
                          error={!!formState.errors?.vesselName}
                          helperText={formState.errors?.vesselName?.message}
                          disabled={vessel !== 'new'}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="callSign"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Call sign"
                          onBlur={() => trigger('callSign')}
                          error={!!formState.errors?.callSign}
                          helperText={formState.errors?.callSign?.message}
                          disabled={vessel !== 'new'}
                          fullWidth
                          onInput={toInputUppercase}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">optional</InputAdornment>,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="mmsiNumber"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="MMSI number"
                          onBlur={() => trigger('mmsiNumber')}
                          error={!!formState.errors?.mmsiNumber}
                          helperText={formState.errors?.mmsiNumber?.message}
                          disabled={vessel !== 'new'}
                          fullWidth
                          InputProps={{
                            endAdornment: <InputAdornment position="end">optional</InputAdornment>,
                          }}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              {vessel === 'new' && (
                <Grid item xs={12}>
                  <FileUpload
                    label="Upload vessel geometry"
                    accept={['.json', '.staf', '.staf.txt']}
                    onChange={handleVesselGeometryParse}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          disabled={formState.isSubmitting}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button variant="contained" color="primary" type="submit" loading={formState.isSubmitting}>
          Create
        </Button>
      </DialogActions>
    </form>
  );
};
