import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import {
  AuthContext,
  Layout,
  LayoutContext,
  PrivateRoute,
  setAuthorizationHeader,
  SidebarAction,
  UserRoles,
} from '@hpc/components';
import { api as hcApi } from '@hpc/hatch-clerk';

import { api } from '~/utils';

import { getSidebarElements } from './getSidebarElements';
import {
  ForgotPassword,
  GettingStarted,
  Home,
  OrganisationRoutes,
  Organisations,
  SignIn,
  UserProfile,
} from './modules';

export const AppRoutes = () => {
  const location = useLocation();
  const { setSidebarElements, setRootUrl } = useContext(LayoutContext);
  const { user, isAuthenticating, isAuthenticated, accessControl } = useContext(AuthContext);

  useEffect(() => {
    setRootUrl('');
    setSidebarElements({ type: SidebarAction.RESET });
    setSidebarElements({
      type: SidebarAction.SET,
      value: getSidebarElements(location.pathname),
    });
  }, [accessControl]);

  useLayoutEffect(() => {
    if (!isAuthenticating && isAuthenticated) {
      setAuthorizationHeader(api);
      setAuthorizationHeader(hcApi);
    }
  }, [user]);

  return (
    <Routes>
      <Route path="login" element={<SignIn />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route
        path="*"
        element={
          <Layout>
            <Routes>
              <Route path="" element={<Home />} />
              <Route path="getting-started" element={<GettingStarted />} />
              <PrivateRoute
                path="organisation/*"
                userRole={UserRoles.USER}
                element={<OrganisationRoutes />}
              />
              <PrivateRoute path="organisations" userRole={UserRoles.SUPER_ADMIN}>
                <Route path="" element={<Organisations />} />
                <Route path=":organisationId/*" element={<OrganisationRoutes />} />
              </PrivateRoute>
              <PrivateRoute path="user" element={<UserProfile />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Layout>
        }
      />
    </Routes>
  );
};
