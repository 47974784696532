import React from 'react';
import { Grid, InputAdornment, TextField } from '@material-ui/core';
import { Controller, Control, FieldErrors } from 'react-hook-form';

import { Card, CardContent, CardHeader, DetailField, Organisation } from '@hpc/components';

import { useStyles } from '../styles';
import { organizationAddress, organizationDetails } from './config';

interface IProps {
  organisation: Organisation;
  control: Control<Organisation>;
  errors: FieldErrors;
}

export const OrganisationEdit = ({ organisation, control, errors }: IProps) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="stretch">
      <Grid item xs={12} md={8}>
        <Card className={classes.paper}>
          <CardHeader title="Organisation details" />
          <CardContent>
            <Grid container>
              {organizationDetails.map(({ label, property, optional }) => (
                <Grid item key={label} xs={12} lg={6}>
                  <Controller
                    name={property}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={label}
                        error={!!errors?.[property]}
                        helperText={errors?.[property]?.message}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          endAdornment: optional && (
                            <InputAdornment position="end">optional</InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              ))}
              <Grid item xs={12} lg={6}>
                <DetailField label="Subdomain" value={organisation.tomSubdomain} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Controller
                  name="websiteUrl"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Website URL"
                      error={!!errors?.websiteUrl}
                      helperText={errors?.websiteUrl?.message}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <DetailField label="Status" value={organisation.status} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <Card className={classes.paper}>
          <CardHeader title="Address" />
          <CardContent>
            <Grid container>
              {organizationAddress.map(({ label, property, optional }) => (
                <Grid item key={label} xs={12}>
                  <Controller
                    name={property}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={label}
                        error={!!errors?.[property]}
                        helperText={errors?.[property]?.message}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          endAdornment: optional && (
                            <InputAdornment position="end">optional</InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
