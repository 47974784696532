import React from 'react';
import { Dialog } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { VesselCallInput } from '~/types';
import { api, capitalizeFirstLetter } from '~/utils';

import { CreateVesselCallDialog } from './CreateVesselCallDialog';

interface IProps {
  onClose: () => void;
}

export const CreateVesselCall = ({ onClose }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleUploadBaplie = async (callNumber: string, baplie: File, baplieType: string) => {
    try {
      if (baplie) {
        const formData = new FormData();
        formData.append('baplie', baplie);
        await api.post(`/vessel-calls/${callNumber}/baplie/${baplieType}`, formData);
        enqueueSnackbar(`${capitalizeFirstLetter(baplieType)} BAPLIE has been been uploaded`, {
          variant: 'success',
        });
      }
    } catch (e) {
      enqueueSnackbar(
        `Error uploading ${baplieType} BAPLIE. Please try to upload BAPLIE in vessel call details dialog`,
        { variant: 'error' }
      );
    }
  };

  const handleUploadVesselGeometry = async (imoNumber: string, geometry: File) => {
    try {
      if (geometry) {
        const formData = new FormData();
        formData.append('vesselGeometry', geometry);
        await api.post(`/vessels/${imoNumber}/vessel-geometry`, formData);
        enqueueSnackbar(`Vessel geometry has been been uploaded`, {
          variant: 'success',
        });
      }
    } catch (e) {
      enqueueSnackbar(
        `Error uploading vessel geometry. Please try to upload vessel geometry from vessel details page.`,
        { variant: 'error' }
      );
    }
  };

  const handleSubmit = async (data: VesselCallInput, createVessel = false) => {
    try {
      if (createVessel) {
        await api.post('/vessels', {
          imoNumber: data.imoNumber,
          vesselName: data.vesselName,
          callSign: data.callSign,
          mmsiNumber: data.mmsiNumber,
        });

        enqueueSnackbar('Vessel has been created.', { variant: 'success' });
      }
      await api.post('/vessel-calls', {
        callNumber: data.callNumber,
        imoNumber: data.imoNumber,
        voyageNumber: data.voyageNumber,
        eta: data.eta,
        etd: data.etd,
      });

      enqueueSnackbar('Vessel call has been created.', { variant: 'success' });

      if (data.geometry) {
        await handleUploadVesselGeometry(data.imoNumber, data.geometry);
      }
      if (data.arrivalBaplie) {
        await handleUploadBaplie(data.callNumber, data.arrivalBaplie, 'arrival');
      }
      if (data.departureBaplie) {
        await handleUploadBaplie(data.callNumber, data.departureBaplie, 'departure');
      }
      onClose();
    } catch (e) {
      enqueueSnackbar('Error creating Vessel call.', { variant: 'error' });
    }
  };

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="md">
      <CreateVesselCallDialog onCancel={onClose} onSubmit={handleSubmit} />
    </Dialog>
  );
};
