import React from 'react';
import { MUIDataTableColumn } from 'mui-datatables';
import { Link } from 'react-router-dom';

import { Bay } from '~/types';
import { formatDateTime } from '~/utils';

export const getBaysColumns = (bays: Bay[]): MUIDataTableColumn[] => {
  const renderBay = (value) => (
    <Link
      to="bays"
      state={{
        filters: {
          bay: bays[value].bay,
          dh: bays[value].deckHold,
          dir: bays[value].workingDirection,
        },
      }}
    >
      {`${bays[value].bay}${bays[value].deckHold[0]}-${bays[value].workingDirection}`}
    </Link>
  );

  return [
    {
      name: 'workingBay',
      label: 'Bay',
      options: {
        customBodyRenderLite: renderBay,
      },
    },
    { name: 'status', label: 'Status' },
    {
      name: 'closingTime',
      label: 'Closing time',
      options: {
        customBodyRenderLite: (value) => formatDateTime(bays[value].closingTime),
      },
    },
    { name: 'closedBy', label: 'Closed by' },
  ];
};
