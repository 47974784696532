export enum VesselProfileStatus {
  PRESENT = 'PRESENT',
  ABSENT = 'ABSENT',
}

export enum GeometryOriginator {
  STAF = 'STAF',
  JSON = 'JSON',
  BAPLIE = 'BAPLIE',
}

export type Vessel = {
  callSign: string;
  imoNumber: string;
  mmsiNumber: string;
  operator: string;
  profileStatus: VesselProfileStatus;
  vesselName: string;
  geometryOriginator: GeometryOriginator;
};

export type VesselAudit = Vessel & {
  updatedBy: string;
  updateDate: string;
};
