import React from 'react';
import { Link } from 'react-router-dom';

import { TableColumnDef } from '@hpc/components';
import { Group } from '~/types';

export const groupColumns = (groups: Group[]): TableColumnDef[] => {
  const renderName = (index) => <Link to={`${groups[index].name}`}>{groups[index].name}</Link>;

  return [
    {
      name: 'name',
      label: 'Name',
      options: {
        render: renderName,
      },
    },
    { name: 'description', label: 'Description' },
  ];
};
