import React from 'react';
import { GettingStarted as GettingStartedTemplate } from '@hpc/components';
import { NavigateApp, Organisation, UserPermissions, Users } from './steps';

export const GettingStarted = () => {
  const steps = [
    {
      label: 'Navigate the App',
      content: <NavigateApp />,
    },
    {
      label: 'Manage Organisation',
      content: <Organisation />,
    },
    {
      label: 'Create User',
      content: <Users />,
    },
    {
      label: 'Manage User Rights',
      content: <UserPermissions />,
    },
  ];

  return <GettingStartedTemplate steps={steps} />;
};
