import React, { useContext, useRef, useState } from 'react';
import { Box, Divider, IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { AccountCircle as UserIcon } from '@material-ui/icons';

import { AuthContext } from '~/utils';

const useStyles = makeStyles(() => ({
  menu: {
    '& ul': {
      minWidth: 200,
    },
  },
  signOut: {
    color: red[700],
  },
}));

export const UserMenu = () => {
  const classes = useStyles();
  const { signOut, user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <IconButton color="inherit" ref={anchorRef} onClick={toggleOpen}>
        <UserIcon />
      </IconButton>
      <Menu
        className={classes.menu}
        anchorEl={anchorRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        open={open}
        onClose={toggleOpen}
        keepMounted
      >
        {user && (
          <Box>
            <MenuItem disabled>{user?.emailAddress}</MenuItem>
            <Divider />
          </Box>
        )}
        <MenuItem className={classes.signOut} onClick={signOut}>
          Sign Out
        </MenuItem>
      </Menu>
    </>
  );
};
