import React from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';

import { navbarHeight } from './constants';
import { Navbar } from './Navbar';
import { Sidebar } from './Sidebar';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  content: {
    flexGrow: 1,
    padding: spacing(2),
    paddingTop: spacing(2) + navbarHeight,
    overflow: 'hidden',
  },
}));

type Props = {
  children: React.ReactNode;
};

export const Layout = ({ children }: Props) => {
  const classes = useStyles();
  return (
    <Box display="flex">
      <Navbar />
      <Sidebar />
      <main className={classes.content}>{children}</main>
    </Box>
  );
};
