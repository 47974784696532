import React from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { Link } from 'react-router-dom';
import { StatusIcon } from '~/components';
import { VesselCallComplete } from '~/types';
import { formatDateTime } from '~/utils';

export const getVesselCallsColumns = (
  vesselCalls: VesselCallComplete[]
): MUIDataTableColumnDef[] => {
  const renderCallNumber = (value) => (
    <Link to={`${vesselCalls[value].callNumber}`}>{vesselCalls[value].callNumber}</Link>
  );
  const renderBaplieStatusArival = (value) => (
    <StatusIcon
      status={vesselCalls[value].baplieStatusArrival}
      message={vesselCalls[value].baplieErrorArrival}
    />
  );
  const renderBaplieStatusDeparture = (value) => (
    <StatusIcon
      status={vesselCalls[value].baplieStatusDeparture}
      message={vesselCalls[value].baplieErrorDeparture}
    />
  );
  const renderProfileStatus = (value) => <StatusIcon status={vesselCalls[value].profileStatus} />;

  const setCellHeaderProps = () => ({
    style: {
      whiteSpace: 'nowrap',
    },
  });

  const setCellProps = () => ({
    padding: 'checkbox',
    align: 'center',
  });

  return [
    {
      name: 'callNumber',
      label: 'Call #',
      options: {
        customBodyRenderLite: renderCallNumber,
        setCellHeaderProps,
      },
    },
    {
      name: 'vesselName',
      label: 'Vessel Name',
      options: {
        setCellHeaderProps,
      },
    },
    {
      name: 'callSign',
      label: 'Call Sign',
      options: {
        setCellHeaderProps,
      },
    },
    {
      name: 'imoNumber',
      label: ' IMO #',
      options: {
        setCellHeaderProps,
      },
    },
    {
      name: 'voyageNumber',
      label: ' Voyage #',
      options: {
        setCellHeaderProps,
      },
    },
    { name: 'handlingStatus', label: 'Status' },
    {
      name: 'eta',
      label: 'ETA',
      options: {
        customBodyRenderLite: (value) => formatDateTime(vesselCalls[value].eta),
      },
    },
    {
      name: 'etd',
      label: 'ETD',
      options: {
        customBodyRenderLite: (value) => formatDateTime(vesselCalls[value].etd),
      },
    },
    {
      name: 'baplieStatusArrival',
      label: 'Arrival BAPLIE',
      options: {
        customBodyRenderLite: renderBaplieStatusArival,
        setCellProps,
      },
    },
    {
      name: 'baplieStatusDeparture',
      label: 'Departure BAPLIE',
      options: {
        customBodyRenderLite: renderBaplieStatusDeparture,
        setCellProps,
      },
    },
    {
      name: 'profileStatus',
      label: 'Geometry',
      options: {
        customBodyRenderLite: renderProfileStatus,
        setCellProps,
      },
    },
    {
      name: 'geometryOriginator',
      label: 'Geometry origin',
    },
  ];
};
