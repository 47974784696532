import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';

import {
  ApiResponse,
  AuthContext,
  LayoutContext,
  Loading,
  NotificationError,
  Organisation as OrganisationType,
  PrivateRoute,
  SidebarAction,
  UserRoles,
  useToggle,
} from '@hpc/components';

import { api } from '~/utils';

import { Application, Applications } from '../applications';
import { Group } from '../group';
import { Groups } from '../groups';
import { User } from '../user';
import { Users } from '../users';

import { Organisation } from './Organisation';
import { getSidebarElement } from './sidebar';

export const OrganisationRoutes = () => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const params = useParams();
  const { setSidebarElements } = useContext(LayoutContext);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useToggle(true);
  const [organisation, setOrganisation] = useState<OrganisationType>(null);

  const organisationId = params?.organisationId || user.organisationId;

  const fetchOrganisation = async () => {
    try {
      const response = await api.get<ApiResponse<OrganisationType>>(
        `/organisations/${organisationId}`
      );
      setOrganisation(response?.data?.payload);
    } catch (e) {
      enqueueSnackbar('Error fetching organisation details.', { variant: 'error' });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (organisationId) {
      fetchOrganisation();
    }
  }, [organisationId]);

  useEffect(() => {
    if (location.pathname.startsWith('/organisations') && organisation) {
      setSidebarElements({ type: SidebarAction.UPDATE, value: getSidebarElement(organisation) });
    }

    return () => {
      if (location.pathname.startsWith('/organisations') && organisation) {
        setSidebarElements({
          type: SidebarAction.REMOVE,
          value: `organisations-${organisation.tomSubdomain}`,
        });
      }
    };
  }, [organisation]);

  if (loading) {
    return <Loading />;
  }

  if (!loading && !organisation) {
    return <NotificationError isCard title="Organisation not found" />;
  }

  return (
    <Routes>
      <Route
        path=""
        element={<Organisation organisation={organisation} onUpdate={fetchOrganisation} />}
      />
      <PrivateRoute path="groups" userRole={UserRoles.ADMIN}>
        <Route path="" element={<Groups />} />
        <Route path=":groupName" element={<Group />} />
      </PrivateRoute>
      <PrivateRoute path="users" userRole={UserRoles.ADMIN}>
        <Route path="" element={<Users />} />
        <Route path=":userId" element={<User />} />
      </PrivateRoute>
      <PrivateRoute path="apps" userRole={UserRoles.ADMIN}>
        <Route path="" element={<Applications />} />
        <Route path=":appId" element={<Application onUpdate={fetchOrganisation} />} />
      </PrivateRoute>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
