export const ERROR_FIELD_REQUIRED = 'This field is required.';
export const ERROR_FIELD_EMAIL = 'Enter a valid email address.';
export const ERROR_FIELD_PHONE = 'Invalid phone number format.';
export const ERROR_FIELD_SUBDOMAIN = 'Only lowercase alphanumeric characters are allowed.';
export const ERROR_FIELD_GROUP_NAME = 'Invalid group name.';
export const ERROR_FIELD_GROUP_NAME_RESERVED = 'Restricted group name.';

export const ERROR_FIELD_PASSWORD_SHORT = 'Password should be at least 8 characters long.';
export const ERROR_FIELD_PASSWORD_UPPERCASE =
  'Password should contain at least one uppercase letter.';
export const ERROR_FIELD_PASSWORD_LOWERCASE =
  'Password should contain at least one lowercase letter.';
export const ERROR_FIELD_PASSWORD_NUMBER = 'Password should contain at least one number.';
export const ERROR_FIELD_PASSWORD_WEAK = 'Password is too weak.';
