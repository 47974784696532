import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';

import {
  ApiResponse,
  AuthContext,
  Editable,
  hasRole,
  Loading,
  NotificationError,
  PERMISSION_REGEX,
  UserRoles,
  useToggle,
} from '@hpc/components';

import { fixedGroups } from '~/constants';
import { Group as GroupType } from '~/types';
import { api } from '~/utils';

import { DeleteGroupButton, GroupEdit, GroupMembers, GroupView } from './components';

export const Group = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const params = useParams();
  const { user, accessControl } = useContext(AuthContext);
  const [loading, setLoading] = useToggle(true);
  const [group, setGroup] = useState<GroupType>(null);

  const organisationId = params?.organisationId || user.organisationId;

  const fetchGroup = async () => {
    try {
      const response = await api.get<ApiResponse<GroupType>>(
        `/organisations/${organisationId}/groups/${params.groupName}`
      );
      setGroup(response?.data?.payload);
    } catch (e) {
      enqueueSnackbar('Error fetching group details.', { variant: 'error' });
    }
    setLoading(false);
  };

  const updateGroup = async (data: GroupType) => {
    const updatedGroup = {
      description: data.description,
    };
    try {
      await api.patch(
        `/organisations/${organisationId}/groups/${group.name}`,
        JSON.stringify(updatedGroup)
      );
      await fetchGroup();
      enqueueSnackbar('Group has been updated.', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Error updating group.', { variant: 'error' });
      throw new Error();
    }
  };

  useEffect(() => {
    if (organisationId && params?.groupName) {
      fetchGroup();
    }
  }, [organisationId, params?.groupName]);

  const handleDelete = () => {
    navigate(
      params?.organisationId ? `/organisations/${organisationId}/groups` : '/organisation/groups'
    );
  };

  if (loading) {
    return <Loading />;
  }

  if (!group) {
    return <NotificationError isCard title="Group not found" />;
  }

  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <Editable
            title={group?.name}
            entity={group}
            renderEdit={(control, errors) => <GroupEdit control={control} errors={errors} />}
            renderNonEdit={<GroupView group={group} />}
            canEdit={hasRole(accessControl, UserRoles.ADMIN)}
            onSubmit={updateGroup}
          />
        </Grid>
        <Grid item>
          <GroupMembers users={group?.users || []} onChange={fetchGroup} />
        </Grid>
        {group && !fixedGroups.includes(group.name) && !group.name.match(PERMISSION_REGEX) && (
          <Grid item>
            <DeleteGroupButton
              organisationId={organisationId}
              groupName={params.groupName}
              onDelete={handleDelete}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
