import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import { GsOrganisationApplicationsMenu, GsOrganisationDetails } from '~/assets';

export const Organisation = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={5} lg={3}>
            <img src={GsOrganisationDetails} alt="Organisation details" />
          </Grid>
          <Grid item xs={12} sm={6} md={7} lg={9}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Administrators possess the additional right to adjust the organisation&apos;s
                  details if required. Specific changes have an impact on all your users, such as
                  changing location code or subdomain. Therefore, changes should be executed with
                  care.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  The initial setup of your organisation is provided to you when your account is
                  activated.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={7} lg={9}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1">
                  As an administrator, the platform additionally provides you with an overview of
                  all applications available to your organisation. To access this overview select
                  the option Applications from the menu on your left-hand side.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  If you are missing an application, don&apos;t hesitate to get in touch with us so
                  we can help you with this matter.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={5} lg={3}>
            <img src={GsOrganisationApplicationsMenu} alt="Organisation applications menu" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
