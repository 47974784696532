import React from 'react';
import { Grid } from '@material-ui/core';

import { Card, CardContent, CardHeader, DetailField, trimUrl } from '@hpc/components';

import { DataTest } from '~/constants';
import { Organisation } from '~/types';

import { useStyles } from '../styles';
import { organizationAddress, organizationDetails } from './config';

interface IProps {
  organisation: Organisation;
}

export const OrganisationView = ({ organisation }: IProps) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="stretch">
      <Grid item xs={12} md={8}>
        <Card className={classes.paper} data-test={DataTest.ORGANISATION_DETAILS_CARD}>
          <CardHeader title="Organisation details" />
          <CardContent>
            <Grid container>
              {organizationDetails.map(({ label, property }) => (
                <Grid item key={label} xs={12} lg={6}>
                  <DetailField label={label} value={organisation?.[property] || ''} />
                </Grid>
              ))}
              <Grid item xs={12} lg={6}>
                <DetailField label="Subdomain" value={organisation.tomSubdomain} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <DetailField label="Website URL" url={trimUrl(organisation.websiteUrl)} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <DetailField label="Status" value={organisation.status} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <Card className={classes.paper} data-test={DataTest.ORGANISATION_ADDRESS_CARD}>
          <CardHeader title="Address" />
          <CardContent>
            <Grid container>
              {organizationAddress.map(({ label, property }) => (
                <Grid item key={label} xs={12}>
                  <DetailField label={label} value={organisation?.[property] || ''} />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
