import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { AccessControl, AccessLevels, Apps, useToggle } from '@hpc/components';

import { DataTest } from '~/constants';
import { Vessel } from '~/types';
import { api } from '~/utils';

import { CreateVessel, VesselTable } from './components';

export const Vessels = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isCreatingVessel, toggleCreatingVessel] = useToggle();
  const [loading, setLoading] = useToggle(true);
  const [vessels, setVessels] = useState([]);

  const fetchVessels = async () => {
    try {
      const response = await api.get<Vessel[]>('/vessels');
      setVessels(response?.data || []);
    } catch (e) {
      enqueueSnackbar('Error fetching vessels.', { variant: 'error' });
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchVessels();
  }, [isCreatingVessel]);

  return (
    <>
      <Grid container direction="column">
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h1">Vessels</Typography>
            </Grid>

            <AccessControl app={Apps.HATCH_CLERK} accessLevel={AccessLevels.WRITE}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={loading}
                  onClick={toggleCreatingVessel}
                >
                  New Vessel
                </Button>
              </Grid>
            </AccessControl>
          </Grid>
        </Grid>
        <Grid item xs={12} data-test={DataTest.HC_VESSEL_LIST}>
          <VesselTable loading={loading} vessels={vessels} />
        </Grid>
      </Grid>

      {isCreatingVessel && <CreateVessel onClose={() => toggleCreatingVessel(false)} />}
    </>
  );
};
