import { DeckHold, WorkingDirection } from './Container';

export enum HandlingStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export type Bay = {
  bay: string;
  deckHold: DeckHold;
  workingDirection: WorkingDirection;
  status: HandlingStatus;
  closingTime: Date;
  closedBy: string;
  containerCount: number;
  note: string;
};

export type BayApi = Omit<Bay, 'closingTime' | 'containerCount'> & { closingTime: string };
