import React from 'react';
import { makeStyles } from '@material-ui/core';
import { NotInterested } from '@material-ui/icons';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    fontWeight: 600,
    color: palette.text.hint,
    textTransform: 'uppercase',
  },
}));

export const ProgressNoData = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <NotInterested />
      <div>No Data</div>
    </div>
  );
};
