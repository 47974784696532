import React from 'react';
import { GettingStarted as GettingStartedTemplate } from '@hpc/components';
import { Vessels, VesselCallClose, VesselCallProgress, VesselCalls } from './steps';

export const GettingStarted = () => {
  const steps = [
    {
      label: 'Insert Vessel',
      content: <Vessels />,
    },
    {
      label: 'Create Vessel Call ',
      content: <VesselCalls />,
    },
    {
      label: 'Monitor Progress',
      content: <VesselCallProgress />,
    },
    {
      label: 'Finalize Vessel Call',
      content: <VesselCallClose />,
    },
  ];

  return <GettingStartedTemplate steps={steps} />;
};
