import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { User } from '~/types';
import { UserStatus } from './UserStatus';

interface IProps {
  user: User;
}

export const UserHeader = ({ user }: IProps) => {
  const header = [user.firstName, user.name].filter((el) => !!el).join(' ') || user.emailAddress;
  return (
    <Grid container alignItems="center">
      <Grid item>
        <Typography variant="h1">{header}</Typography>
      </Grid>
      <Grid item>
        <UserStatus enabled={user.active} labelEnabled="Active" labelDisabled="Inactive" />
      </Grid>
      <Grid item>
        <UserStatus enabled={user.verified} labelEnabled="Verified" labelDisabled="Unverified" />
      </Grid>
    </Grid>
  );
};
