import React, { useState } from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { Button } from '@hpc/components';
import { FileUpload } from '~/components';

interface IProps {
  onSubmit: (file: File) => Promise<void>;
  onCancel: () => void;
  baplieType: string;
}

export const BaplieUpdateDialog = ({ onCancel, onSubmit, baplieType }: IProps) => {
  const [baplieFile, setBaplieFile] = useState<File | null>(null);

  const { formState, handleSubmit } = useForm<File>();

  const handleBaplieUpload = (file: File) => {
    if (!file) {
      setBaplieFile(null);
      return;
    }
    const reader = new FileReader();
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = () => {
      setBaplieFile(file);
    };
    reader.readAsText(file);
  };

  const updateBaplie = async () => {
    await onSubmit(baplieFile);
  };

  return (
    <form onSubmit={handleSubmit(updateBaplie)}>
      <DialogTitle>Upload {baplieType} BAPLIE</DialogTitle>
      <DialogContent>
        <FileUpload accept=".edi" onChange={handleBaplieUpload} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          disabled={formState.isSubmitting}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!baplieFile}
          loading={formState.isSubmitting}
        >
          Upload
        </Button>
      </DialogActions>
    </form>
  );
};
