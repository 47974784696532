import React from 'react';
import { Grid } from '@material-ui/core';
import { Card, CardContent, DetailField, Loading } from '@hpc/components';

import { User } from '~/types';
import { userDetails } from './config';

interface IProps {
  loading: boolean;
  user: User;
}

export const UserView = ({ loading, user }: IProps) => {
  if (loading) {
    return <Loading />;
  }

  return (
    <Card>
      <CardContent>
        <Grid container>
          {userDetails.map(({ label, property }) => (
            <Grid item key={label} xs={12}>
              <DetailField label={label} value={user?.[property] || ''} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};
