import React from 'react';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { useToggle } from '~/hooks';

export const PasswordInput = React.forwardRef(function PasswordInput(
  { type: _, ...props }: TextFieldProps,
  ref
) {
  const [showPassword, setShowPassword] = useToggle(false);

  return (
    <TextField
      {...props}
      innerRef={ref}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end" onClick={setShowPassword}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
});
