import React, { useContext, useEffect, useRef, useState } from 'react';
import { Divider, IconButton, Menu, MenuItem } from '@material-ui/core';
import { Apps as AppsIcon } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

import { ApiResponse, Application, AppStatus, Products } from '~/types';
import { api, AuthContext } from '~/utils';

import { AppItem, apps } from './apps';

const displayStatus: AppStatus[] = [AppStatus.ENABLED, AppStatus.UPDATING, AppStatus.WARNING];

export const AppsPicker = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { accessControl, user } = useContext(AuthContext);
  const [applications, setApplications] = useState<Partial<Record<Products, Application>>>({});
  const [userApps, setUserApps] = useState<AppItem[]>([]);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const fetchApps = async () => {
    try {
      const response = await api.get<ApiResponse<Application[]>>(
        `/organisations/${user.organisationId}/apps`
      );
      const applications = response.data.payload
        ?.filter(({ status }) => displayStatus.includes(status))
        .reduce(
          (acc, app) => ({
            ...acc,
            [app.name]: app,
          }),
          {}
        );
      setApplications(applications);
    } catch (e) {
      enqueueSnackbar('Error fetching applications.', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (user.organisationId) {
      fetchApps();
    }
  }, [user.organisationId]);

  useEffect(() => {
    setUserApps(
      apps
        .map((app) => ({
          ...app,
          status: applications[app.app.split('.')?.[0]]?.status,
        }))
        .filter(({ status }) => displayStatus.includes(status))
        .filter(({ app }) => accessControl.hasAppRead(app))
    );
  }, [accessControl, applications]);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <IconButton ref={anchorRef} color="inherit" onClick={toggleOpen}>
        <AppsIcon />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        open={open}
        onClose={toggleOpen}
        keepMounted
      >
        {userApps.map(({ app, label, url, external, status }) => (
          <MenuItem
            key={app}
            component={Link}
            to={url}
            target={external && '_blank'}
            disabled={status !== AppStatus.ENABLED}
          >
            {label}
            {status !== AppStatus.ENABLED && ` (status: ${status})`}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem component={Link} to="/">
          Administration
        </MenuItem>
      </Menu>
    </>
  );
};
