import { Application, AppStatus as AppStatusType, ProductLabels, Products } from '@hpc/components';
import { hatchClerk } from '~/assets';
import { HatchClerk } from './apps';

export const appConfig: Record<Products, Application> = {
  [Products.HATCH_CLERK]: {
    name: Products.HATCH_CLERK,
    title: ProductLabels[Products.HATCH_CLERK],
    description: 'Speed up your vessel operations. Be always up to date. Digital vessel bay plans.',
    image: hatchClerk,
    status: AppStatusType.UNAVAILABLE,
    component: HatchClerk,
  },
};
