import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import { AccessControl, ApiResponse, AuthContext, UserRoles, useToggle } from '@hpc/components';

import { User } from '~/types';
import { api } from '~/utils';

import { CreateUser } from './CreateUser';
import { UserTable } from './UsersTable';

export const Users = () => {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { user } = useContext(AuthContext);
  const [isCreatingUser, toggleCreatingUser] = useToggle();
  const [loading, setLoading] = useToggle(true);
  const [users, setUsers] = useState<User[]>([]);

  const organisationId = params?.organisationId || user.organisationId;

  const fetchUsers = async () => {
    try {
      const response = await api.get<ApiResponse<User[]>>(`/organisations/${organisationId}/users`);
      setUsers(response?.data?.payload || []);
    } catch (e) {
      enqueueSnackbar('Error fetching users.', { variant: 'error' });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (organisationId) {
      fetchUsers();
    }
  }, [organisationId, isCreatingUser]);

  return (
    <>
      <Grid container direction="column">
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography variant="h1">Users</Typography>
          </Grid>
          {!loading && (
            <AccessControl userRole={UserRoles.ADMIN}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={loading}
                  onClick={toggleCreatingUser}
                >
                  New User
                </Button>
              </Grid>
            </AccessControl>
          )}
        </Grid>
        <Grid item>
          <UserTable loading={loading} users={users} />
        </Grid>
      </Grid>

      {isCreatingUser && organisationId && (
        <CreateUser organisationId={organisationId} onClose={() => toggleCreatingUser(false)} />
      )}
    </>
  );
};
