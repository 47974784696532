import React from 'react';
import { makeStyles, Paper as MuiPaper, PaperProps, Theme } from '@material-ui/core';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    padding: spacing(2),
  },
}));

type IProps = PaperProps;

export const Paper = ({ children, classes, ...props }: IProps) => {
  const customClasses = useStyles();

  return (
    <MuiPaper classes={{ ...customClasses, ...classes }} {...props}>
      {children}
    </MuiPaper>
  );
};
