export enum WorkingDirection {
  DISCHARGE = 'DISCHARGE',
  LOAD = 'LOAD',
  UNDEFINED = 'UNDEFINED',
}

export enum DeckHold {
  DECK = 'DECK',
  HOLD = 'HOLD',
}

export type BaySlot = {
  bay: string;
  row: string;
  tier: string;
  deckHold: DeckHold;
};

export type BaySlotConfirmed = {
  bay: string[];
  row: string[];
  tier: string[];
  deckHold: DeckHold[];
};

export enum CargoStatus {
  FULL = 'FULL',
  EMPTY = 'EMPTY',
}

export enum ContainerCondition {
  INTACT = 'INTACT',
  DAMAGED = 'DAMAGED',
}

export enum OogType {
  VC = 'VC',
  VCUHR = 'VCUHR',
  TRUCKTRAILOR = 'TRUCKTRAILOR',
  TRUCKTRAILORUHR = 'TRUCKTRAILORUHR',
  TRUCKTRAILORCHAINS = 'TRUCKTRAILORCHAINS',
}

export type OogInfo = {
  overlengthFront: number;
  overlength: number;
  overlengthRear: number;
  overwidthLeft: number;
  overwidthRight: number;
  overwidth: number;
  oogType: OogType;
  overheight: number;
};

export type Container = {
  containerNumber: string;
  workingDirection: WorkingDirection;
  plannedPosition: BaySlot;
  confirmedPositions: BaySlotConfirmed;
  isoCode: string;
  weight: number;
  weightUnit: string;
  pol: string;
  pod: string;
  cargoStatus: CargoStatus;
  containerCondition: ContainerCondition;
  oogInfo: OogInfo;
  owner: string;
  note: string;
  isROB: boolean;
  isReefer: boolean;
  isHighCube: boolean;
  isIMDG: boolean;
  discrepancy: boolean;
};

export type ContainerApi = Omit<Container, 'confirmedPositions' | 'discrepancy'> & {
  confirmedPositions: BaySlot[];
};
