export type UnPromisify<T> = T extends Promise<infer U> ? U : T;

export enum UserRoles {
  USER = 'user',
  ADMIN = 'admin',
  SUPER_ADMIN = 'superAdmin',
}

export enum AccessLevels {
  READ = 'ro',
  WRITE = 'rw',
  ADMIN = 'admin',
}

// options: Product, Segment, Package, Suite, Collection, Module
export enum Products {
  HATCH_CLERK = 'hatch-clerk',
}

export enum AppTypes {
  WEBAPP = 'webapp',
  MOBILE = 'mobile',
}

export enum Apps {
  HATCH_CLERK = 'hatch-clerk.webapp',
  HATCH_CLERK_MOBILE = 'hatch-clerk.mobile',
}
