import React from 'react';
import { Link } from 'react-router-dom';
import { StatusBoolean, TableColumnDef } from '@hpc/components';
import { User } from '~/types';

export const userColumns = (users: User[]): TableColumnDef[] => {
  const renderName = (value) => (
    <Link to={`${users[value].sub}`}>
      {[users[value].firstName, users[value].name].filter((el) => !!el).join(' ')}
    </Link>
  );
  const renderEmail = (value) => (
    <Link to={`${users[value].sub}`}>{users[value].emailAddress}</Link>
  );
  const renderVerified = (value) => <StatusBoolean status={users[value].verified} />;
  const renderActive = (value) => <StatusBoolean status={users[value].active} />;

  const setCellProps = () => ({
    padding: 'checkbox',
    align: 'center',
  });

  return [
    {
      name: 'name',
      label: 'Name',
      options: {
        render: renderName,
      },
    },
    {
      name: 'emailAddress',
      label: 'Email',
      options: {
        render: renderEmail,
      },
    },
    { name: 'phone', label: 'Phone' },
    { name: 'department', label: 'Department' },
    {
      name: 'verified',
      label: 'Verified',
      options: {
        render: renderVerified,
        setCellProps,
      },
    },
    {
      name: 'active',
      label: 'Active',
      options: {
        render: renderActive,
        setCellProps,
      },
    },
  ];
};
