import React, { useState } from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { Button } from '@hpc/components';

import { FileUpload } from '~/components';
import { VesselCallInput } from '~/types';
import { vesselGeometry } from '~/utils';

interface IProps {
  imoNumber: string;
  onSubmit: (file: File) => Promise<void>;
  onCancel: () => void;
}

export const VesselGeometryUploadDialog = ({ imoNumber, onCancel, onSubmit }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [profileFile, setProfileFile] = useState<File | null>(null);
  const [error, setError] = useState('');
  const { formState, handleSubmit } = useForm<VesselCallInput>();

  const handleProfileUpload = (file: File) => {
    setError('');

    if (!file) {
      setProfileFile(null);
      return;
    }

    const reader = new FileReader();

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');

    reader.onload = () => {
      setProfileFile(file);

      if (file.name.split('.').pop() === 'json') {
        try {
          const parsedGeometry = vesselGeometry.parse(reader.result);
          if (imoNumber !== parsedGeometry.imoNumber) {
            setError(
              "The IMO number from the file doesn't match the IMO number of this vessel profile."
            );
          }
        } catch (e) {
          enqueueSnackbar('Error parsing vessel geometry.', { variant: 'error' });
        }
      }
    };

    reader.readAsText(file);
  };

  const updateProfile = async () => {
    await onSubmit(profileFile);
  };

  return (
    <form onSubmit={handleSubmit(updateProfile)}>
      <DialogTitle>Update vessel geometry</DialogTitle>
      <DialogContent>
        <Grid container>
          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}

          <Grid item xs={12}>
            <FileUpload accept={['.json', '.staf', '.staf.txt']} onChange={handleProfileUpload} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          disabled={formState.isSubmitting}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button variant="contained" color="primary" type="submit" loading={formState.isSubmitting}>
          Upload
        </Button>
      </DialogActions>
    </form>
  );
};
