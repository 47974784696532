import { Group } from '~/types';

type CreateGroupField = {
  key: keyof Group;
  label: string;
  optional?: boolean;
};

export const fields: CreateGroupField[] = [
  { key: 'name', label: 'Name' },
  { key: 'description', label: 'Description', optional: true },
];
