import React from 'react';
import {
  FormControl,
  InputBase,
  InputBaseProps,
  InputLabel,
  Link,
  makeStyles,
} from '@material-ui/core';
import { trimUrl, withHttp } from '~/utils';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    width: '100%',
  },
  label: {
    fontWeight: 600,
  },
  link: {
    marginTop: spacing(2),
    padding: spacing(0.5, 0),
  },
  input: {
    marginTop: spacing(2),
  },
  disabled: {
    color: palette.text.primary,
  },
}));

export interface DetailFieldProps extends InputBaseProps {
  label: string;
  url?: string;
}

export const DetailField = ({ name, label, url, ...props }: DetailFieldProps) => {
  const classes = useStyles();
  const inputClasses = {
    root: classes.input,
    disabled: classes.disabled,
  };

  return (
    <FormControl className={classes.root}>
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      {url ? (
        <Link
          href={withHttp(url)}
          target="_blank"
          rel="noopener"
          variant="body1"
          className={classes.link}
        >
          {trimUrl(url)}
        </Link>
      ) : (
        <InputBase classes={inputClasses} id={name} name={name} disabled {...props} />
      )}
    </FormControl>
  );
};
