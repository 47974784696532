import React, { useEffect, useMemo, useState } from 'react';
import { FormControlLabel, makeStyles, Switch, useMediaQuery, useTheme } from '@material-ui/core';
import { createTheme, MuiThemeProvider, ThemeOptions } from '@material-ui/core/styles';

import { Location } from 'history';
import { MUIDataTableOptions } from 'mui-datatables';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { DataGrid, useToggle } from '@hpc/components';
import { Container, ContainersState, VesselCallComplete, WorkingDirection } from '~/types';
import { filterMap, getContainerColumns } from './getContainerColumns';

const useStyles = makeStyles(() => ({
  confirmed: {
    whiteSpace: 'pre-wrap',
  },
  discrepancies: {
    order: -1,
  },
}));

interface IProps {
  vesselCall: VesselCallComplete;
  containers: Container[];
}

export const ContainersTable = ({ vesselCall, containers }: IProps) => {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const location = useLocation() as Location<ContainersState>;
  const matches = useMediaQuery(breakpoints.up('md'));
  const navigate = useNavigate();
  const params = useSearchParams();
  const [showDiscrepancies, toggleDiscrepancies] = useToggle();
  const [filters, setFilters] = useState(location.state?.filters || Object.fromEntries(params[0]));

  useEffect(() => {
    // clear the state
    navigate(location, { replace: true });
  }, []);

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableToolbar: {
          actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          },
        },
      },
    } as ThemeOptions);

  const columns = useMemo(
    () => getContainerColumns(containers, filters, classes, matches),
    [containers, filters]
  );

  const handleDiscrepancies = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    toggleDiscrepancies(checked);
    setFilters({ ...filters, discrepancy: checked ? '1' : '' });
  };

  const customToolbar = () => (
    <FormControlLabel
      className={classes.discrepancies}
      label="Discrepancies"
      control={
        <Switch checked={showDiscrepancies} onChange={handleDiscrepancies} color="primary" />
      }
    />
  );

  const options: MUIDataTableOptions = {
    customToolbar,
    download: true,
    downloadOptions: {
      filename: `${vesselCall.callNumber}-${vesselCall.vesselName}-containers.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
      separator: ';',
    },
    enableNestedDataAccess: '.',
    filter: true,
    filterType: 'multiselect',
    onDownload: (buildHead, buildBody, columns, data: { data }[]) => {
      const oogInfoIndex = columns.findIndex((column) => column.name === 'oogInfo');
      const wdIndex = columns.findIndex((column) => column.name === 'workingDirection');
      const rows = data.map((container) => {
        const newData = [...container.data];
        if (newData[wdIndex] === WorkingDirection.UNDEFINED) {
          newData[wdIndex] = 'ROB';
        }
        if (newData[oogInfoIndex]) {
          newData.splice(
            oogInfoIndex,
            1,
            `OH: ${newData[oogInfoIndex].overheight || 0}; ` +
              `OW: ${newData[oogInfoIndex].overwidthLeft || 0}/${
                newData[oogInfoIndex].overwidthRight || 0
              }; ` +
              `OL: ${newData[oogInfoIndex].overlengthFront || 0}/${
                newData[oogInfoIndex].overlengthRear || 0
              }`
          );
        }
        return {
          ...container,
          data: newData,
        };
      });
      return `sep=;\n${buildHead(columns)}${buildBody(rows)}`.trim();
    },
    onFilterChange: (changedColumn, filterList, type, changedColumnIndex) => {
      if (changedColumn && filters[filterMap[changedColumn.toString()]]) {
        const newFilters = { ...filters };
        delete newFilters[filterMap[changedColumn.toString()]];
        setFilters(newFilters);
      }
      if (changedColumn === 'discrepancy') {
        if (filterList[changedColumnIndex]?.[0]) {
          toggleDiscrepancies(true);
        } else {
          toggleDiscrepancies(false);
        }
      }
    },
    search: true,
  };

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <DataGrid data={containers} columns={columns} options={options} />
    </MuiThemeProvider>
  );
};
