import React from 'react';
import { MUIDataTableColumn } from 'mui-datatables';
import { Link } from 'react-router-dom';
import { DeckHold, WorkingDirection } from '~/types';

export type WorkingBay = {
  workingBay: string;
  bay: string;
  deckHold: DeckHold;
  workingDirection: WorkingDirection;
  confirmed: number;
  planned: number;
  percentage: number;
};

export const getContainerColumns = (bays: WorkingBay[]): MUIDataTableColumn[] => {
  const renderBay = (value) => (
    <Link
      to="containers"
      state={{
        filters: {
          bay: bays[value]?.bay,
          dh: bays[value]?.deckHold,
          dir: bays[value]?.workingDirection,
        },
      }}
    >
      {`${bays[value].bay}${bays[value].deckHold?.[0]}-${bays[value].workingDirection}`}
    </Link>
  );

  return [
    {
      name: 'workingBay',
      label: 'Bay',
      options: {
        customBodyRenderLite: renderBay,
      },
    },
    {
      name: 'confirmed',
      label: 'Confirmed',
    },
    {
      name: 'planned',
      label: 'Planned',
    },
    {
      name: 'percentage',
      label: '%',
    },
  ];
};
