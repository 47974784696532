import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Clear as Invalid, Done as Valid } from '@material-ui/icons';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette, spacing }) => ({
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  listItem: {
    marginTop: spacing(0.5),
    marginBottom: spacing(0.5),
  },
  valid: {
    color: palette.success.main,
  },
  icon: {
    marginRight: spacing(1),
  },
}));

const requirements = [
  {
    key: 'lowercase',
    label: 'One lowercase character',
    match: (password: string) => /[a-z]/.test(password),
  },
  {
    key: 'uppercase',
    label: 'One uppercase character',
    match: (password: string) => /[A-Z]/.test(password),
  },
  {
    key: 'number',
    label: 'One number',
    match: (password: string) => /\d/.test(password),
  },
  {
    key: 'length',
    label: '8 characters',
    match: (password: string) => password.length >= 8,
  },
];

interface IProps {
  password: string;
}

export const PasswordRequirements = ({ password }: IProps) => {
  const classes = useStyles();
  return (
    <ul className={classes.list}>
      {requirements.map(({ key, label, match }) => {
        const isValid = match(password);
        const listItemClasses = classNames(classes.listItem, {
          [classes.valid]: isValid,
        });
        return (
          <li key={key} className={listItemClasses}>
            <Box display="flex">
              {isValid ? (
                <Valid className={classes.icon} fontSize="small" />
              ) : (
                <Invalid className={classes.icon} fontSize="small" />
              )}
              {label}
            </Box>
          </li>
        );
      })}
    </ul>
  );
};
