import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { AccessControl, AccessLevels, Apps, useToggle } from '@hpc/components';

import { DataTest } from '~/constants';
import { VesselCallApi, VesselCallComplete } from '~/types';
import { api, vesselCallsParser } from '~/utils';

import { CreateVesselCall } from './CreateVesselCall';
import { VesselCallsTable } from './VesselCallsTable';

interface IProps {
  archived?: boolean;
}

export const VesselCalls = ({ archived }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isCreatingVesselCall, toggleCreatingVesselCall] = useToggle();
  const [loading, setLoading] = useToggle(true);
  const [vesselCalls, setVesselCalls] = useState<VesselCallComplete[]>([]);

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await api.get<VesselCallApi[]>(
        `/vessel-calls${archived ? '?archived=true' : ''}`
      );
      setVesselCalls(vesselCallsParser(response.data) || []);
    } catch (e) {
      enqueueSnackbar('Error fetching vessel calls.', { variant: 'error' });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [isCreatingVesselCall, archived]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h1">{archived ? 'Archived ' : ''}Vessel Calls</Typography>
            </Grid>

            <AccessControl app={Apps.HATCH_CLERK} accessLevel={AccessLevels.WRITE}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={loading}
                  onClick={toggleCreatingVesselCall}
                >
                  New Vessel Call
                </Button>
              </Grid>
            </AccessControl>
          </Grid>
        </Grid>
        <Grid item xs={12} data-test={DataTest.HC_VESSEL_CALL_LIST}>
          <VesselCallsTable loading={loading} vesselCalls={vesselCalls} />
        </Grid>
      </Grid>

      {isCreatingVesselCall && <CreateVesselCall onClose={() => toggleCreatingVesselCall(false)} />}
    </>
  );
};
