import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { CheckCircle as Success, Error, Info, Warning } from '@material-ui/icons';
import classNames from 'classnames';

import { DataTest } from '~/constants';
import { Card } from '../Card';

export enum NotificationType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    padding: spacing(8),
  },
  title: {
    fontSize: '1.5rem',
  },
  icon: {
    '& > .MuiSvgIcon-root': {
      fontSize: '4rem',
      paddingBottom: 0,
    },
  },
  success: {
    color: palette.success.main,
  },
  error: {
    color: palette.error.main,
  },
  info: {
    color: palette.info.main,
  },
  warning: {
    color: palette.warning.main,
  },
}));

const getIcon = (type: NotificationType) =>
  ({
    [NotificationType.SUCCESS]: <Success />,
    [NotificationType.INFO]: <Info />,
    [NotificationType.WARNING]: <Warning />,
    [NotificationType.ERROR]: <Error />,
  }[type]);

interface IProps {
  isCard?: boolean;
  type?: NotificationType;
  title?: string;
  message?: string;
}

export const Notification = ({
  isCard = false,
  type = NotificationType.INFO,
  title,
  message,
}: IProps) => {
  const classes = useStyles();

  const content = (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={1}
      data-test={DataTest.NOTIFICATION}
    >
      {title && (
        <Grid item className={classes.title} data-test={DataTest.NOTIFICATION_TITLE}>
          {title}
        </Grid>
      )}
      <Grid
        item
        className={classNames(classes.icon, classes[type])}
        data-test={DataTest.NOTIFICATION_ICON}
      >
        {getIcon(type)}
      </Grid>
      <Grid item>
        {message && (
          <Typography variant="body1" data-test={DataTest.NOTIFICATION_MESSAGE}>
            {message}
          </Typography>
        )}
      </Grid>
    </Grid>
  );

  return isCard ? (
    <Card className={classes.root}>{content}</Card>
  ) : (
    <div className={classes.root}>{content}</div>
  );
};
