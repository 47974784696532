import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  ApiResponse,
  AppStatusRefresh,
  AuthContext,
  IApplicationProps,
  IApplicationRef,
  Loading,
  Organisation,
  useToggle,
} from '@hpc/components';

import { HatchClerkInput } from '~/types';
import { api, hatchClerkAppValidationSchema } from '~/utils';

import { HatchClerkEdit } from './HatchClerkEdit';
import { HatchClerkView } from './HatchClerkView';

export const HatchClerk: React.ForwardRefExoticComponent<
  IApplicationProps & React.RefAttributes<IApplicationRef>
> = forwardRef<IApplicationRef, IApplicationProps>(({ app, isEditing, onSubmit }, ref) => {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { user } = useContext(AuthContext);
  const [loading, toggleLoading] = useToggle(true);
  const [organisation, setOrganisation] = useState<Organisation>(null);

  useImperativeHandle(ref, () => ({
    submit: handleSubmit(updateApp),
  }));

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<HatchClerkInput>({
    resolver: yupResolver(hatchClerkAppValidationSchema),
    defaultValues: {
      locationCode: '',
    },
  });

  const organisationId = params?.organisationId || user.organisationId;

  const fetchOrganisation = async () => {
    try {
      const response = await api.get<ApiResponse<Organisation>>(`/organisations/${organisationId}`);
      setOrganisation(response.data.payload);
      reset({ locationCode: response.data.payload?.locationCode });
    } catch (e) {
      enqueueSnackbar('Error fetching organisation details.', { variant: 'error' });
    }
    toggleLoading(false);
  };

  useEffect(() => {
    if (organisationId) {
      fetchOrganisation();
    }
  }, [organisationId]);

  const updateLocationCode = async (data: HatchClerkInput) => {
    const updatedOrganisation = { ...organisation, locationCode: data.locationCode };
    try {
      await api.put(`/organisations/${updatedOrganisation.id}`, updatedOrganisation);
      await fetchOrganisation();
      enqueueSnackbar('Location code has been updated.', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Error updating location code.', { variant: 'error' });
    }
  };

  const updateApp = async (data: HatchClerkInput) => {
    await updateLocationCode(data);

    if (AppStatusRefresh.includes(app.status)) {
      try {
        await api.post(`/organisations/${organisation.id}/apps/${app.name}/refresh`);
        await onSubmit();
        enqueueSnackbar('App has been updated.', { variant: 'success' });
      } catch (e) {
        enqueueSnackbar('Error updating app.', { variant: 'error' });
      }
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <form>
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <Typography variant="body1">
            Speed up your vessel operations. Be always up to date. Digital vessel bay plans:
          </Typography>
          <ul>
            <li>No more huge paper stacks with stowage plans</li>
            <li>No more waiting for stowage plan updates</li>
            <li>No more confirming container positions with pencil on deck</li>
            <li>No more post-processing of paper stowage plans in the office</li>
          </ul>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            Hatch Clerk application requires location code to operate.
          </Typography>
        </Grid>
        <Grid item>
          {isEditing ? (
            <HatchClerkEdit control={control} errors={errors} />
          ) : (
            <HatchClerkView organisation={organisation} />
          )}
        </Grid>
      </Grid>
    </form>
  );
});
