import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { Controller, useForm } from 'react-hook-form';

import { Button, useToggle } from '@hpc/components';

import { DATETIME_FORMAT } from '~/constants';
import { VesselCallCloseInput } from '~/types';

interface IProps {
  onConfirm: (data: VesselCallCloseInput) => Promise<void>;
  onCancel: () => void;
}

export const CloseVesselCallDialog = ({ onCancel, onConfirm }: IProps) => {
  const [isLoading, toggleLoading] = useToggle();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<VesselCallCloseInput>({
    defaultValues: {
      atd: new Date(),
    },
  });

  const closeVesselCall = async (data: VesselCallCloseInput) => {
    toggleLoading(true);
    await onConfirm(data);
    toggleLoading(false);
  };

  return (
    <Dialog onClose={onCancel} open fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit(closeVesselCall)}>
        <DialogTitle>Close vessel call</DialogTitle>
        <DialogContent>
          <Controller
            name="atd"
            control={control}
            render={({ field: { onChange, value } }) => (
              <KeyboardDateTimePicker
                onChange={onChange}
                label="Actual time of departure"
                error={!!errors?.atd}
                helperText={errors?.atd?.message}
                value={value}
                fullWidth
                variant="inline"
                format={DATETIME_FORMAT}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" disabled={isLoading} onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit" loading={isLoading}>
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
