import React, { useEffect, useMemo } from 'react';
import { Location } from 'history';
import { MUIDataTableOptions } from 'mui-datatables';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { DataGrid } from '@hpc/components';
import { Bay, ContainersState, VesselCallComplete } from '~/types';
import { getBaysColumns } from './getBaysColumns';

interface IProps {
  vesselCall: VesselCallComplete;
  bays: Bay[];
}

export const BaysTable = ({ vesselCall, bays }: IProps) => {
  const location = useLocation() as Location<ContainersState>;
  const navigate = useNavigate();
  const params = useSearchParams();
  const filters = location.state?.filters || Object.fromEntries(params[0]);

  useEffect(() => {
    // clear the state
    navigate(location, { replace: true });
  }, []);

  const columns = useMemo(
    () => getBaysColumns(bays, vesselCall, filters),
    [bays, vesselCall, filters]
  );
  const options: MUIDataTableOptions = {
    download: true,
    downloadOptions: {
      filename: `${vesselCall.callNumber}-${vesselCall.vesselName}-bays.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
      separator: ';',
    },
    filter: true,
    filterType: 'multiselect',
    onDownload: (buildHead, buildBody, columns, data: { data }[]) => {
      return `sep=;\r\n${buildHead(columns)}${buildBody(data)}`.trim();
    },
    search: true,
  };

  return <DataGrid data={bays} columns={columns} options={options} />;
};
