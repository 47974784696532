import { VesselCallComplete } from '~/types';

export const getSidebarElement = ({
  callNumber,
  imoNumber,
  isArchived,
  vesselName,
}: VesselCallComplete) => ({
  id: isArchived ? 'archivedVesselCalls' : 'vesselCalls',
  open: true,
  children: [
    {
      id: `vesselCall-${callNumber}`,
      label: vesselName || imoNumber,
      isSection: true,
      children: [
        {
          id: 'vesselCall.details',
          label: 'Details',
          url: `/hatch-clerk/${isArchived ? 'archived-' : ''}vessel-calls/${callNumber}`,
          exact: true,
        },
        {
          id: 'vesselCall.bays',
          label: 'Bays',
          url: `/hatch-clerk/${isArchived ? 'archived-' : ''}vessel-calls/${callNumber}/bays`,
        },
        {
          id: 'vesselCall.containers',
          label: 'Containers',
          url: `/hatch-clerk/${isArchived ? 'archived-' : ''}vessel-calls/${callNumber}/containers`,
        },
      ],
    },
  ],
});
