import jwt_decode from 'jwt-decode';

import {
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_ID_TOKEN_KEY,
  LOCAL_STORAGE_REFRESH_TOKEN_KEY,
  LOCAL_STORAGE_SUBDOMAIN_KEY,
} from '~/constants';
import {
  AccessToken,
  ApiResponse,
  ForgotPasswordInput,
  ForgotPasswordResponse,
  IdToken,
  Organisation,
  TokenResponse,
  User,
} from '~/types';

import { api, setAuthorizationHeader } from '../api';

export const NOT_AUTHENTICATED = 'User is not authenticated.';
export const USER_DATA_MISSING = 'User data is missing.';
export const ORGANISATION_DATA_MISSING = 'Organisation data is missing.';

export const verifyTokens = (accessToken: AccessToken, idToken: IdToken) => {
  const now = Date.now();
  return now < idToken.exp * 1000 && now < accessToken.exp * 1000;
};

export const refreshToken = async (username: string) => {
  const refreshToken = localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
  const subdomain = localStorage.getItem(LOCAL_STORAGE_SUBDOMAIN_KEY);

  try {
    const response = await api.post<ApiResponse<TokenResponse>>('/login/refresh-token', {
      subdomain,
      username,
      refreshToken,
    });

    const tokens = response.data.payload;
    const id: IdToken = jwt_decode(tokens.idToken);
    const access: AccessToken = jwt_decode(tokens.accessToken);

    if (!verifyTokens(access, id)) {
      throw '';
    }

    localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, tokens.accessToken);
    localStorage.setItem(LOCAL_STORAGE_ID_TOKEN_KEY, tokens.idToken);

    return id.sub;
  } catch (error) {
    throw new Error(NOT_AUTHENTICATED);
  }
};

export const getUserId = async () => {
  const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
  const idToken = localStorage.getItem(LOCAL_STORAGE_ID_TOKEN_KEY);

  if (!idToken || !accessToken) {
    throw new Error('Tokens are missing.');
  }

  try {
    const id: IdToken = jwt_decode(idToken);
    const access: AccessToken = jwt_decode(accessToken);

    return verifyTokens(access, id) ? id.sub : await refreshToken(id.sub);
  } catch (error) {
    throw new Error('Could not get valid token data.');
  }
};

export const checkPermissions = (user: User) => {
  const idToken = localStorage.getItem(LOCAL_STORAGE_ID_TOKEN_KEY);
  const id: IdToken = jwt_decode(idToken);
  return (
    user.groups?.every((g) => id['cognito:groups']?.includes(g)) &&
    id['cognito:groups']?.every((g) => user.groups.includes(g))
  );
};

export const getUser = async () => {
  try {
    await getUserId();
    setAuthorizationHeader(api);
    const userResponse = await api.get<ApiResponse<User>>('/account');
    const user = userResponse.data.payload;
    if (!user || !user.organisationId) {
      throw Error(USER_DATA_MISSING);
    }
    if (!checkPermissions(user)) {
      await refreshToken(user.sub);
      setAuthorizationHeader(api);
    }
    const organisationResponse = await api.get<ApiResponse<Organisation>>(
      `/organisations/${user.organisationId}`
    );
    const organisation = organisationResponse.data.payload;
    if (!organisation || !organisation.tomSubdomain) {
      throw new Error(ORGANISATION_DATA_MISSING);
    }
    user.organisationSubdomain = organisation.tomSubdomain;
    return user;
  } catch (error) {
    if ([USER_DATA_MISSING, ORGANISATION_DATA_MISSING].includes(error.message)) {
      throw error;
    }

    throw new Error(NOT_AUTHENTICATED);
  }
};

export interface ISignIn {
  subdomain: string;
  username: string;
  password: string;
  newPassword?: string;
}

export const signIn = async ({ subdomain, username, password, newPassword }: ISignIn) => {
  try {
    const response = await api.post<ApiResponse<TokenResponse>>('/login', {
      subdomain,
      username,
      password,
      ...(newPassword && { newPassword }),
    });

    const tokens = response.data.payload;

    localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, tokens.accessToken);
    localStorage.setItem(LOCAL_STORAGE_ID_TOKEN_KEY, tokens.idToken);
    localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY, tokens.refreshToken);
    localStorage.setItem(LOCAL_STORAGE_SUBDOMAIN_KEY, subdomain);

    return response;
  } catch (error) {
    throw error.response?.data?.payload || error;
  }
};

export const signOut = () => {
  try {
    localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
    localStorage.removeItem(LOCAL_STORAGE_ID_TOKEN_KEY);
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
    localStorage.removeItem(LOCAL_STORAGE_SUBDOMAIN_KEY);
  } catch (error) {
    console.log('error signing out: ', error);
  }
};

export const resetPassword = async ({ subdomain, username }: ForgotPasswordInput) => {
  try {
    const response = await api.post<ApiResponse<ForgotPasswordResponse>>('/login/forgot-password', {
      subdomain,
      username,
    });

    return response.data.payload;
  } catch (error) {
    throw error.response?.data?.payload || error;
  }
};

export interface IConfirmPassword {
  subdomain: string;
  username: string;
  password: string;
  confirmationCode: string;
}

export const confirmPassword = async ({
  subdomain,
  username,
  password,
  confirmationCode,
}: IConfirmPassword) => {
  await api.post('/login/confirm-password', {
    subdomain,
    username,
    password,
    confirmationCode,
  });
};
