import React, { useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import { Card } from '@hpc/components';

import { App } from './types';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: 0,
    width: 160,
    height: 160,
    cursor: 'pointer',
    borderRadius: 20,
    border: `1px solid ${palette.grey.A100}`,
  },
  wrapper: {
    height: '100%',
  },
  icon: {
    width: 80,
    height: 80,
  },
}));

interface IProps {
  app: App;
}
export const AppTile = ({ app: { title, url, icon } }: IProps) => {
  const navigate = useNavigate();
  const [isRaised, setIsRaised] = useState(false);
  const classes = useStyles();

  const handleMouseOver = () => setIsRaised(true);
  const handleMouseOut = () => setIsRaised(false);
  const handleClick = () => navigate(url);

  return (
    <Card
      raised={isRaised}
      className={classes.root}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={handleClick}
    >
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={0}
        className={classes.wrapper}
      >
        <Grid item>
          <Typography variant="subtitle1">{title}</Typography>
        </Grid>
        <Grid item>
          <img src={icon} className={classes.icon} alt={title} />
        </Grid>
      </Grid>
    </Card>
  );
};
