import React, { useContext, useState } from 'react';
import { Button, CardHeader, Grid, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import {
  AccessControl,
  AuthContext,
  Card,
  NotificationNoData,
  Table,
  UserRoles,
  useToggle,
} from '@hpc/components';

import { User } from '~/types';

import { AddUser } from './AddUser';
import { groupMembersColumns } from './groupMembersColumns';
import { RemoveUserDialog } from './RemoveUserDialog';

const useStyles = makeStyles(({ spacing }) => ({
  actions: {
    width: spacing(6),
  },
  members: {
    padding: 0,
  },
  membersHeader: {
    padding: spacing(4, 4, 2, 4),
  },
}));

interface IProps {
  users: User[];
  onChange: () => void;
}

export const GroupMembers = ({ users, onChange }: IProps) => {
  const classes = useStyles();
  const params = useParams();
  const { user, accessControl } = useContext(AuthContext);
  const [isAddingUser, toggleAddingUser] = useToggle();
  const [isRemovingUser, toggleRemoveUser] = useToggle();
  const [userToRemove, setUserToRemove] = useState<User>(null);

  const organisationId = params?.organisationId || user.organisationId;

  const onUserAdded = async () => {
    await onChange();
    toggleAddingUser(false);
  };

  const onUserRemoved = async () => {
    await onChange();
    toggleRemoveUser(false);
  };

  const handleRemoveUser = (user: User) => {
    setUserToRemove(user);
    toggleRemoveUser();
    onChange();
  };

  const columns = groupMembersColumns(
    organisationId,
    users,
    accessControl.isAdmin,
    handleRemoveUser,
    classes
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card className={classes.members}>
          <CardHeader
            title="Members"
            action={
              <AccessControl userRole={UserRoles.ADMIN}>
                <Button variant="outlined" color="primary" onClick={toggleAddingUser}>
                  Add User
                </Button>
              </AccessControl>
            }
            className={classes.membersHeader}
          />
          {!users.length ? (
            <NotificationNoData message="The group has no members" />
          ) : (
            <Table data={users} columns={columns} />
          )}
        </Card>
      </Grid>

      {isAddingUser && organisationId && params.groupName && (
        <AddUser
          organisationId={organisationId}
          groupName={params.groupName}
          groupMembers={users}
          onClose={toggleAddingUser}
          onSuccess={onUserAdded}
        />
      )}

      {isRemovingUser && organisationId && params.groupName && (
        <RemoveUserDialog
          organisationId={organisationId}
          groupName={params.groupName}
          user={userToRemove}
          onClose={toggleRemoveUser}
          onSuccess={onUserRemoved}
        />
      )}
    </Grid>
  );
};
