import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

const useStyles = makeStyles(({ palette }) => ({
  icon: {
    color: palette.error.main,
  },
}));

export const StatusNegative = () => {
  const classes = useStyles();
  return <Clear className={classes.icon} />;
};
