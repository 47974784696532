import React, { useEffect } from 'react';
import { CardContent, CardHeader, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import {
  Button,
  Card,
  User,
  UserRoleLabels,
  UserRoles as UserRolesType,
  useToggle,
} from '@hpc/components';

import { api } from '~/utils';

type RolesObject = Record<UserRolesType, boolean>;

interface IProps {
  organisationId: string;
  user: User;
  onSave: () => Promise<void>;
}

export const UserRoles = ({ organisationId, user, onSave }: IProps) => {
  const [isEditing, toggleEdit] = useToggle();
  const { enqueueSnackbar } = useSnackbar();
  const { control, formState, handleSubmit, reset, watch } = useForm<RolesObject>({
    defaultValues: Object.keys(UserRoleLabels).reduce(
      (acc, role) => ({
        ...acc,
        [role]: false,
      }),
      {}
    ),
  });

  const watchUser: boolean = watch('user');
  const watchAdmin: boolean = watch('admin');

  const resetRoles = () => {
    const userRoles = Object.keys(UserRoleLabels).reduce(
      (acc, role) => ({
        ...acc,
        [role]: user.groups.includes(role),
      }),
      {}
    );

    reset(userRoles);
  };

  useEffect(() => {
    resetRoles();
  }, [user]);

  const updateGroups = async (roles: RolesObject) => {
    try {
      const addRequests = Object.keys(roles)
        .filter((role) => roles[role] && !user.groups.includes(role))
        .map(
          async (role) =>
            await api.post(`/organisations/${organisationId}/groups/${role}/users/${user.sub}`)
        );

      const removeRequests = Object.keys(roles)
        .filter((role) => !roles[role] && user.groups.includes(role))
        .map(
          async (role) =>
            await api.delete(`/organisations/${organisationId}/groups/${role}/users/${user.sub}`)
        );

      await Promise.all([...addRequests, ...removeRequests]);
      await onSave();
      enqueueSnackbar('Roles have been updated.', { variant: 'success' });
      toggleEdit(false);
    } catch (e) {
      enqueueSnackbar('Error updating roles.', { variant: 'error' });
    }
  };

  const handleCancel = () => {
    toggleEdit();
    resetRoles();
  };

  return (
    <Card>
      <form onSubmit={handleSubmit(updateGroups)}>
        <CardHeader
          title="User roles"
          action={
            isEditing ? (
              <Grid container>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={formState.isSubmitting}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="button"
                    variant="outlined"
                    disabled={formState.isSubmitting}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Button type="button" color="primary" variant="outlined" onClick={toggleEdit}>
                Edit
              </Button>
            )
          }
        />
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <FormControlLabel
                label="User"
                control={
                  <Controller
                    control={control}
                    name="user"
                    render={({ field: { onChange, ...props } }) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        disabled={!isEditing || watchAdmin}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    )}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label="Admin"
                control={
                  <Controller
                    control={control}
                    name="admin"
                    render={({ field: { onChange, ...props } }) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        disabled={!isEditing || !watchUser}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    )}
                  />
                }
              />
            </Grid>
          </Grid>
        </CardContent>
      </form>
    </Card>
  );
};
