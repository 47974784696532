import React from 'react';
import { Grid, InputAdornment, TextField } from '@material-ui/core';
import { Controller, Control, FieldErrors } from 'react-hook-form';

import { Card, CardContent, User } from '@hpc/components';

import { userEdit } from './config';

interface IProps {
  control: Control<User>;
  errors: FieldErrors;
}

export const UserEdit = ({ control, errors }: IProps) => {
  return (
    <Card>
      <CardContent>
        <Grid container>
          {userEdit.map(({ label, property, optional }) => (
            <Grid item key={label} xs={12}>
              <Controller
                name={property}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={label}
                    error={!!errors?.[property]}
                    helperText={errors?.[property]?.message}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: optional && (
                        <InputAdornment position="end">optional</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};
