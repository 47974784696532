import React, { useState } from 'react';
import {
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import classNames from 'classnames';

import { Card } from '@hpc/components';
import { DataTest } from '~/constants';
import { ProgressInfo } from './ProgressInfo';

export const useStyles = makeStyles(({ palette, spacing, transitions }) => ({
  progress: {
    padding: 0,
  },
  progressHeader: {
    background: 'linear-gradient(10deg, #3ca9ff, #026dc8)',
    color: palette.getContrastText('#026dc8'),
    '& .MuiCardHeader-title': {
      fontSize: '1.1rem',
    },
  },
  progressContent: {
    position: 'relative',
    '&:last-child': {
      paddingBottom: spacing(2),
    },
  },
  progressSectionTitle: {
    display: 'inline-block',
    textTransform: 'uppercase',
    fontSize: '1rem',
    letterSpacing: 2,
    wordSpacing: 5,
    whiteSpace: 'nowrap',
    color: palette.text.secondary,
    width: '100%',
    textAlign: 'center',
  },
  progressTableExpand: {
    position: 'absolute',
    bottom: spacing(1),
    right: spacing(1),
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: transitions.create('transform', {
      duration: transitions.duration.shortest,
    }),
  },
  progressTableExpandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const ProgressDataTest: Record<string, string> = {
  bays: DataTest.HC_VESSEL_CALL_BAYS,
  containers: DataTest.HC_VESSEL_CALL_CONTAINERS,
};

interface IProps {
  title: string;
  dischargeCurrent: number;
  dischargeTotal: number;
  loadCurrent: number;
  loadTotal: number;
  table?: React.ReactNode;
}

export const Progress = ({
  title,
  dischargeCurrent,
  dischargeTotal,
  loadCurrent,
  loadTotal,
  table,
}: IProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.progress} data-test={ProgressDataTest[title?.toLowerCase()]}>
      <CardHeader className={classes.progressHeader} title={title} />
      <CardContent className={classes.progressContent}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.progressSectionTitle}>
              Discharge
            </Typography>
            <ProgressInfo current={dischargeCurrent} total={dischargeTotal} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.progressSectionTitle}>
              Load
            </Typography>
            <ProgressInfo current={loadCurrent} total={loadTotal} />
          </Grid>
        </Grid>
        {table && (dischargeTotal > 0 || loadTotal > 0) && (
          <IconButton
            className={classNames(classes.progressTableExpand, {
              [classes.progressTableExpandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMore />
          </IconButton>
        )}
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {table}
      </Collapse>
    </Card>
  );
};
