import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import { GsEta, GsVesselDetailsBays } from '~/assets';

export const VesselCallProgress = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6} sm={7} md={8} lg={10}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1">
                  You can check the progress of each vessel call by clicking on the vessel call
                  number to open the vessel call details.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  This screen provides you with an overview of vessel details, vessel call details,
                  and progress regarding completed bays and confirmed containers.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={5} md={4} lg={2}>
            <img src={GsEta} alt="Estimated time of arrival" />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <img src={GsVesselDetailsBays} alt="Vessel details bays summary" />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Typography variant="body1">
              For more detail, open the drop-down at the right-hand side of the progress charts or
              select Bays or Containers from the menu on your left-hand side.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
