import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import { DetailField } from '@hpc/components';

import { DATETIME_FORMAT } from '~/constants';
import { HandlingStatus, VesselCall } from '~/types';
import { formatDateTime } from '~/utils';

import { vesselCallDetails } from './config';

interface IProps {
  vesselCall: VesselCall;
  control: Control<VesselCall>;
  errors: FieldErrors;
}

export const VesselCallDetailsEdit = ({ vesselCall, control, errors }: IProps) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <DetailField label="Call number" value={vesselCall.callNumber} />
      </Grid>
      {vesselCallDetails
        .filter((detail) => !detail.locked)
        .map(({ label, property }) => (
          <Grid item key={label} xs={12}>
            <Controller
              name={property}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={label}
                  error={!!errors?.[property]}
                  helperText={errors?.[property]?.message}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
        ))}

      <Grid item xs={12}>
        <Controller
          name="eta"
          control={control}
          render={({ field: { onChange, value } }) => (
            <KeyboardDateTimePicker
              onChange={onChange}
              label="Estimated time of arrival"
              error={!!errors?.eta}
              helperText={errors?.eta?.message}
              value={value}
              fullWidth
              variant="inline"
              format={DATETIME_FORMAT}
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="etd"
          control={control}
          render={({ field: { onChange, value } }) => (
            <KeyboardDateTimePicker
              onChange={onChange}
              label="Estimated time of departure"
              error={!!errors?.etd}
              helperText={errors?.etd?.message}
              value={value}
              fullWidth
              variant="inline"
              format={DATETIME_FORMAT}
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </Grid>

      {vesselCall.handlingStatus === HandlingStatus.CLOSED ? (
        <Grid item xs={12}>
          <Controller
            name="atd"
            control={control}
            render={({ field: { onChange, value } }) => (
              <KeyboardDateTimePicker
                onChange={onChange}
                label="Actual time of departure"
                error={!!errors?.atd}
                helperText={errors?.atd?.message}
                value={value}
                fullWidth
                variant="inline"
                format={DATETIME_FORMAT}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <DetailField
            label="Actual time of departure"
            value={vesselCall.atd ? formatDateTime(vesselCall.atd) : '-'}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <DetailField label="Status" value={vesselCall.handlingStatus} />
      </Grid>
    </Grid>
  );
};
