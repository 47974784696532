import React from 'react';
import { TextField } from '@material-ui/core';
import { Controller, Control, FieldErrors } from 'react-hook-form';

import { Card, CardContent } from '@hpc/components';
import { Group } from '~/types';

interface IProps {
  control: Control<Group>;
  errors: FieldErrors;
}

export const GroupEdit = ({ control, errors }: IProps) => {
  return (
    <Card>
      <CardContent>
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Description"
              error={!!errors?.description}
              helperText={errors?.description?.message}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </CardContent>
    </Card>
  );
};
