export * from './api';
export * from './auth';
export { errorInterceptor, setErrors } from './errors';
export { trimUrl, withHttp } from './text';
export * from './validators';

export const getValue = (object, path) =>
  path
    .replace(/\[/g, '.')
    .replace(/\]/g, '')
    .split('.')
    .reduce((o, k) => (o || {})[k], object);

export const filterUnique = <T>(value: T, index: number, self: T[]) =>
  self.indexOf(value) === index;
