import React from 'react';
import { Dialog } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { ApiResponse } from '@hpc/components';

import { Organisation, OrganisationInput } from '~/types';
import { api } from '~/utils';

import { CreateOrganisationDialog } from './CreateOrganisationDialog';

interface IProps {
  onClose: () => void;
}

export const CreateOrganisation = ({ onClose }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (data: OrganisationInput) => {
    try {
      await api.post<ApiResponse<Organisation>>('/organisations/', data);
      enqueueSnackbar('Organisation has been created.', { variant: 'success' });
      onClose();
    } catch (error) {
      enqueueSnackbar('Error creating organisation.', { variant: 'error' });
      throw error;
    }
  };

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="sm">
      <CreateOrganisationDialog onCancel={onClose} onSubmit={handleSubmit} />
    </Dialog>
  );
};
