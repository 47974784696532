import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { NotificationNoData } from '@hpc/components';

import { Bay, Container, VesselCallComplete } from '~/types';

import { ContainersTable } from './components';

interface IProps {
  vesselCall: VesselCallComplete;
  bays: Bay[];
  containers: Container[];
}

export const Containers = ({ vesselCall, containers }: IProps) => {
  if (!containers?.length) {
    return <NotificationNoData isCard message="The container list is empty" />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1">Containers</Typography>
        <Typography variant="h2">{`${vesselCall.vesselName} (#${vesselCall.callNumber})`}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ContainersTable vesselCall={vesselCall} containers={containers} />
      </Grid>
    </Grid>
  );
};
