import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import { GsBaplieStatus } from '~/assets';

export const VesselCalls = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1">
              To create a vessel call for a ship calling your port, select the Vessel Calls option
              in the menu on your left-hand side.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              All vessel calls already existing will be displayed. Insert a new Vessel Call by
              selecting the New Vessel Call button on top of the list on your right-hand side.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={6} md={8} lg={9}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Upon selection, a creation screen will open for you to insert the master data of
                  the vessel call, reference the respective ship, and upload arrival and departure
                  BAPLIE via drag and drop.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Refresh your page to check whether the upload has been successful.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6} md={4} lg={3}>
            <img src={GsBaplieStatus} alt="Baplie status" />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1">
          After successfully uploading the BAPLIEs, your hatch clerks can access the respective ship
          in the mobile app via the ship menu (top left corner) and select their place of work from
          the discharge and load bays.
        </Typography>
      </Grid>
    </Grid>
  );
};
