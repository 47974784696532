import { object, string } from 'yup';
import { ERROR_FIELD_EMAIL, ERROR_FIELD_PHONE, ERROR_FIELD_REQUIRED } from '@hpc/components';

const phoneRegExp = /^\+[1-9](?:[0-9][ -]?){6,14}$/;

export const userValidationSchema = object({
  firstName: string().required(ERROR_FIELD_REQUIRED),
  name: string().required(ERROR_FIELD_REQUIRED),
  emailAddress: string().required(ERROR_FIELD_REQUIRED).email(ERROR_FIELD_EMAIL),
  phone: string()
    .nullable()
    .matches(phoneRegExp, { message: ERROR_FIELD_PHONE, excludeEmptyString: true }),
});
