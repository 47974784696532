import React, { useMemo } from 'react';
import { MUIDataTableOptions } from 'mui-datatables';

import { DataGrid, Loading, NotificationNoData } from '@hpc/components';

import { Vessel } from '~/types';
import { getVesselColumns } from './getVesselColumns';

interface IProps {
  loading: boolean;
  vessels: Vessel[];
}

export const VesselTable = ({ loading, vessels }: IProps) => {
  const columns = useMemo(() => getVesselColumns(vessels), [vessels]);

  const options: MUIDataTableOptions = {
    search: true,
    filter: true,
    filterType: 'multiselect',
  };

  if (loading) {
    return <Loading />;
  }

  if (!vessels.length) {
    return <NotificationNoData isCard message="The vessel list is empty" />;
  }

  return <DataGrid data={vessels} columns={columns} options={options} />;
};
