import React from 'react';
import { Chip, makeStyles } from '@material-ui/core';
import { FiberManualRecord as DotIcon } from '@material-ui/icons';

const useStyles = makeStyles(({ palette }) => ({
  enabled: {
    color: palette.success.main,
  },
  disabled: {
    color: palette.error.main,
  },
}));

interface IProps {
  enabled: boolean;
  labelEnabled?: string;
  labelDisabled?: string;
}

export const UserStatus = ({
  enabled,
  labelEnabled = 'Enabled',
  labelDisabled = 'Disabled',
}: IProps) => {
  const classes = useStyles();
  return enabled ? (
    <Chip
      size="small"
      variant="outlined"
      icon={<DotIcon className={classes.enabled} />}
      label={labelEnabled}
    />
  ) : (
    <Chip
      size="small"
      variant="outlined"
      icon={<DotIcon className={classes.disabled} />}
      label={labelDisabled}
    />
  );
};
