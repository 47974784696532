import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Controller, useForm } from 'react-hook-form';

import {
  Button,
  PasswordInput,
  setErrors,
  SignInInput,
  signInValidationSchema,
} from '@hpc/components';
import { DataTest } from '~/constants';
import { useStyles } from '../styles';

interface IProps {
  subdomain: string;
  onSubmit: (data: SignInInput) => Promise<void>;
}

export const SignInForm = ({ subdomain, onSubmit }: IProps) => {
  const classes = useStyles();
  const [formError, setFormError] = useState('');
  const { control, formState, handleSubmit, register, setValue, setError } = useForm<SignInInput>({
    resolver: yupResolver(signInValidationSchema),
    defaultValues: {
      subdomain,
      username: '',
      password: '',
    },
  });

  useEffect(() => {
    if (subdomain) {
      setValue('subdomain', subdomain);
      register('subdomain');
    }
  }, [subdomain]);

  const signIn = async (data: SignInInput) => {
    try {
      await onSubmit(data);
    } catch (e) {
      if (e.error && e.error.code === 'VALIDATION_ERROR') {
        if (e.error.extensions) {
          setErrors(e.error.extensions, setError);
        } else {
          setFormError(e.error.message);
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(signIn)}>
      <Card className={classes.card} elevation={6}>
        <CardContent>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h1">Sign In</Typography>
            </Grid>
            {formError && (
              <Grid item>
                <Alert severity="error">{formError}</Alert>
              </Grid>
            )}
            {!subdomain && (
              <Grid item>
                <Controller
                  control={control}
                  name="subdomain"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Organisation subdomain"
                      error={!!formState.errors?.subdomain}
                      helperText={formState.errors?.subdomain?.message}
                      fullWidth
                      data-test={DataTest.SIGNIN_SUBDOMAIN_FIELD}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item>
              <Controller
                control={control}
                name="username"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Username"
                    error={!!formState.errors?.username}
                    helperText={formState.errors?.username?.message}
                    fullWidth
                    data-test={DataTest.SIGNIN_EMAIL_FIELD}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <PasswordInput
                    {...field}
                    label="Password"
                    error={!!formState.errors?.password}
                    helperText={formState.errors?.password?.message}
                    fullWidth
                    data-test={DataTest.SIGNIN_PASSWORD_FIELD}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            loading={formState.isSubmitting}
            fullWidth
            data-test={DataTest.SIGNIN_SUBMIT_BUTTON}
          >
            Sign In
          </Button>
        </CardActions>
      </Card>
      <Box mt={1} display="flex" justifyContent="flex-end">
        <Link
          href="/forgot-password"
          className={classes.forgotPasswordLink}
          data-test={DataTest.SIGNIN_FORGOT_PASSWORD_LINK}
        >
          Forgot password?
        </Link>
      </Box>
    </form>
  );
};
