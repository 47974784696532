import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { useSnackbar } from 'notistack';

import { useToggle } from '@hpc/components';

import { api } from '~/utils';
import { DeleteGroupDialog } from './DeleteGroupDialog';

const ColorButton = withStyles(() => ({
  root: {
    color: red[700],
    borderColor: red[700],
    '&:hover': {
      color: red[800],
      borderColor: red[800],
    },
  },
}))(Button);

interface IProps {
  organisationId: string;
  groupName: string;
  onDelete: () => void;
}

export const DeleteGroupButton = ({ organisationId, groupName, onDelete }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isDeletingGroup, toggleDeleteGroup] = useToggle();

  const removeGroup = async () => {
    try {
      await api.delete(`/organisations/${organisationId}/groups/${groupName}`);
      enqueueSnackbar('Group has been deleted.', { variant: 'success' });
      onDelete();
    } catch (e) {
      enqueueSnackbar('Error deleting group.', { variant: 'error' });
    }
  };

  return (
    <>
      <ColorButton type="button" variant="outlined" onClick={toggleDeleteGroup}>
        Delete group
      </ColorButton>
      {isDeletingGroup && (
        <DeleteGroupDialog
          groupName={groupName}
          onCancel={toggleDeleteGroup}
          onConfirm={removeGroup}
        />
      )}
    </>
  );
};
