import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { GsAdministration } from '~/assets';

export const NavigateApp = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4} lg={2}>
            <img src={GsAdministration} alt="Administration menu link" />
          </Grid>

          <Grid item xs={12} sm={8} lg={10}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1">
                  The platform home screen automatically opens when you log in. If you are currently
                  using another application inside the platform, quickly return to the home screen
                  by selecting Administration in app menu (top-left corner) or clicking on the HPC
                  logo. The home screen will display all applications currently available to your
                  user.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  You can access the applications by either clicking on them or opening them via the
                  app menu.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1">
          Furthermore, you have the option to access your organisation&apos;s details. As a standard
          user, these details will be read-only.
        </Typography>
      </Grid>
    </Grid>
  );
};
