import React, { useEffect, useState } from 'react';
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { Delete as DeleteIcon, InsertDriveFile as FileIcon } from '@material-ui/icons';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

import { DataTest } from '~/constants';

const useStyles = makeStyles(({ palette, spacing }) => ({
  dropzone: {
    height: 88,
    border: `1px dashed ${palette.grey[400]}`,
    background: palette.secondary[100],
    padding: spacing(2),
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
  },
}));

type Props = {
  label?: string;
  onChange?: (file: File) => void;
} & DropzoneOptions;

export const FileUpload = ({ label, onChange, ...options }: Props) => {
  const classes = useStyles();
  const [files, setFiles] = useState<File[]>([]);

  const onDrop = (acceptedFiles: File[]) => {
    setFiles([...files, ...acceptedFiles]);
  };

  useEffect(() => {
    onChange?.(files[0]);
  }, [onChange, files]);

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    ...options,
    onDrop:
      typeof options.onDrop === 'function'
        ? (files: File[], ...args) => {
            onDrop(files);
            options.onDrop(files, ...args);
          }
        : onDrop,
  });

  const handleDelete = (event, index: number) => {
    event.stopPropagation();
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  return (
    <Grid container direction="column">
      {label && (
        <Grid item>
          <Typography variant="h4" data-test={DataTest.FILE_UPLOAD_LABEL}>
            {label}
          </Typography>
        </Grid>
      )}
      {files.length > 0 ? (
        <Grid item>
          <List>
            {files.map((file, index) => (
              <ListItem key={file.name}>
                <ListItemIcon>
                  <FileIcon />
                </ListItemIcon>
                <ListItemText
                  primary={file.name}
                  secondary={`${Math.floor(file.size / 1024)} kB`}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(event) => handleDelete(event, index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
      ) : (
        <Grid item>
          <Paper
            variant="outlined"
            className={classes.dropzone}
            {...getRootProps()}
            data-test={DataTest.FILE_UPLOAD_DROPZONE}
          >
            <input {...getInputProps()} />
            <Typography variant="body1">
              Drag &apos;n&apos; drop some files here, or click to select files
            </Typography>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};
