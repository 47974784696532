export const DataTest = {
  FILE_UPLOAD_LABEL: 'fileUploadLabel',
  FILE_UPLOAD_DROPZONE: 'fileUploadDropzone',
  HC_VESSEL_LIST: 'vesselList',
  HC_VESSEL_DETAILS: 'vesselDetails',
  HC_VESSEL_CALL_LIST: 'vesselCallList',
  HC_VESSEL_CALL_DETAILS: 'vesselCallDetails',
  HC_VESSEL_CALL_ETA: 'vesselCallEta',
  HC_VESSEL_CALL_ETD: 'vesselCallEtd',
  HC_VESSEL_CALL_ATD: 'vesselCallAtd',
  HC_VESSEL_CALL_BAYS: 'vesselCallBays',
  HC_VESSEL_CALL_CONTAINERS: 'vesselCallContainers',
  STATUS_ICON: 'statusIcon',
};
