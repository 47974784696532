import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Navigate, Route, Routes } from 'react-router-dom';

import {
  api as coreApi,
  ApiResponse,
  Application,
  Apps,
  AppStatus,
  AuthContext,
  Layout,
  LayoutContext,
  Loading,
  Notification,
  NotificationError,
  NotificationType,
  PrivateRoute,
  Products,
  setAuthorizationHeader,
  SidebarAction,
  useToggle,
} from '@hpc/components';

import { api } from '~/utils';

import { GettingStarted, Vessel, VesselCallRoutes, VesselCalls, Vessels } from './modules';
import { sidebarElements } from './sidebarElements';

interface IProps {
  path?: string;
}

export const AppRoutes = (_: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setSidebarElements, setRootUrl } = useContext(LayoutContext);
  const { user, isAuthenticating, isAuthenticated } = useContext(AuthContext);
  const [loading, setLoading] = useToggle(true);
  const [application, setApplication] = useState<Application | undefined>();

  const fetchHatchClerkApp = async () => {
    try {
      const response = await coreApi.get<ApiResponse<Application[]>>(
        `/organisations/${user.organisationId}/apps`
      );
      const hatchClerk = response.data.payload?.find(({ name }) => name === Products.HATCH_CLERK);
      setApplication(hatchClerk);
    } catch (e) {
      enqueueSnackbar('Error fetching applications.', { variant: 'error' });
    }
    setLoading(false);
  };

  useEffect(() => {
    setRootUrl('/hatch-clerk');
    setSidebarElements({ type: SidebarAction.RESET });
    setSidebarElements({
      type: SidebarAction.SET,
      value: sidebarElements,
    });
    fetchHatchClerkApp();
  }, []);

  useLayoutEffect(() => {
    if (!isAuthenticating && isAuthenticated) {
      setAuthorizationHeader(api);
    }
  }, [user]);

  if (loading) {
    return <Loading />;
  }

  if (!application) {
    return <NotificationError message="Error getting application data." />;
  }

  if (application.status === AppStatus.WARNING) {
    return (
      <Notification
        type={NotificationType.WARNING}
        message="Please update the application configuration and try again."
      />
    );
  }

  if (application.status === AppStatus.UPDATING) {
    return (
      <Notification
        type={NotificationType.WARNING}
        title="Updating"
        message="The application is being updated, please try again later."
      />
    );
  }

  if (application.status !== AppStatus.ENABLED) {
    return (
      <NotificationError
        title="Unavailable"
        message="The application is not available for your organisation, please contact our support."
      />
    );
  }

  return (
    <Layout>
      <Routes>
        <Route path="getting-started" element={<GettingStarted />} />
        <PrivateRoute path="vessel-calls" app={Apps.HATCH_CLERK}>
          <Route path="" element={<VesselCalls />} />
          <Route path=":vesselCallId/*" element={<VesselCallRoutes />} />
        </PrivateRoute>
        <PrivateRoute path="archived-vessel-calls" app={Apps.HATCH_CLERK}>
          <Route path="" element={<VesselCalls archived />} />
          <Route path=":vesselCallId/*" element={<VesselCallRoutes />} />
        </PrivateRoute>
        <PrivateRoute path="vessels" app={Apps.HATCH_CLERK}>
          <Route path="" element={<Vessels />} />
          <Route path=":imoNumber" element={<Vessel />} />
        </PrivateRoute>
        <Route path="*" element={<Navigate to="vessel-calls" replace />} />
      </Routes>
    </Layout>
  );
};
