import React from 'react';
import { MUIDataTableOptions } from 'mui-datatables';

import { DataGrid } from '@hpc/components';
import { Bay, WorkingDirection } from '~/types';
import { getBaysColumns } from './getBaysColumns';

interface IProps {
  bays: Bay[];
}

export const BaysTable = ({ bays }: IProps) => {
  const workingBays = bays.filter((bay) => bay.workingDirection !== WorkingDirection.UNDEFINED);
  const columns = getBaysColumns(workingBays);
  const options: MUIDataTableOptions = {
    pagination: false,
    tableBodyMaxHeight: '350px',
    setTableProps: () => ({
      size: 'small',
    }),
  };

  return <DataGrid data={workingBays} columns={columns} options={options} />;
};
