import React from 'react';
import { Divider, Grid, Paper, Typography } from '@material-ui/core';
import { Alarm, Schedule, Today } from '@material-ui/icons';
import { isFuture } from 'date-fns';

import { useCountdown } from '@hpc/components';

import { DataTest } from '~/constants';
import { VesselCall } from '~/types';
import { formatDate, formatTime } from '~/utils';

import { useStylesVertical } from './styles';
import { Estimation } from './types';

const EstimationDataTest: Record<Estimation, string> = {
  eta: DataTest.HC_VESSEL_CALL_ETA,
  etd: DataTest.HC_VESSEL_CALL_ETD,
  atd: DataTest.HC_VESSEL_CALL_ATD,
};

const EstimationPassed: Partial<Record<Estimation, string>> = {
  eta: 'Arrived',
  etd: 'Departed',
};

interface IProps {
  estimatedTime: VesselCall['eta'] | VesselCall['etd'] | VesselCall['atd'];
  estimation: Estimation;
}

export const EstimatedTimeVertical = ({ estimatedTime, estimation }: IProps) => {
  const running = isFuture(estimatedTime);
  const timeLeft = useCountdown(estimatedTime);

  const classes = useStylesVertical({ estimation });

  return (
    <Paper data-test={EstimationDataTest[estimation]}>
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.etHead}>
          <Typography variant="body1" className={classes.etTitle}>
            {estimation.toUpperCase()}
          </Typography>
        </Grid>

        <Divider className={classes.divider} />

        <Grid item xs={12}>
          <Typography className={classes.etTime} variant="body1">
            <Today />
            {estimatedTime ? formatDate(estimatedTime) : '-'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.etTime} variant="body1">
            <Schedule />
            {estimatedTime ? formatTime(estimatedTime) : '-'}
          </Typography>
        </Grid>

        {estimation !== 'atd' && (
          <>
            <Divider className={classes.divider} />

            <Grid item xs={12}>
              <Typography className={running ? classes.etTime : classes.etTimerEnd} variant="body1">
                {running && <Alarm />}
                {!running ? EstimationPassed[estimation] : timeLeft}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
};
