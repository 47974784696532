import React from 'react';
import { Dialog } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { api, capitalizeFirstLetter } from '~/utils';
import { BaplieUpdateDialog } from './BaplieUpdateDialog';

interface IProps {
  callNumber: string;
  baplieType: string;
  onSuccess: () => Promise<void>;
  onClose: () => void;
}

export const BaplieUpdate = ({ callNumber, baplieType, onSuccess, onClose }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('baplie', file);
      await api.post(`/vessel-calls/${callNumber}/baplie/${baplieType}`, formData);
      enqueueSnackbar(`${capitalizeFirstLetter(baplieType)} BAPLIE has been been updated`, {
        variant: 'success',
      });
      await onSuccess();
      onClose();
    } catch (e) {
      enqueueSnackbar(`Error updating ${baplieType} BAPLIE`, { variant: 'error' });
    }
  };

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="md">
      <BaplieUpdateDialog onCancel={onClose} onSubmit={handleSubmit} baplieType={baplieType} />
    </Dialog>
  );
};
