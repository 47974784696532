import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { Button, useToggle } from '@hpc/components';

interface IProps {
  onConfirm: () => Promise<void>;
  onCancel: () => void;
}

export const ArchiveVesselCallDialog = ({ onCancel, onConfirm }: IProps) => {
  const [isLoading, toggleLoading] = useToggle();

  const handleSubmit = async () => {
    toggleLoading(true);
    await onConfirm();
    toggleLoading(false);
  };

  return (
    <Dialog onClose={onCancel} open fullWidth maxWidth="sm">
      <DialogTitle>Archive vessel call</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to archive the vessel call?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" disabled={isLoading} onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" loading={isLoading} onClick={handleSubmit}>
          Archive
        </Button>
      </DialogActions>
    </Dialog>
  );
};
